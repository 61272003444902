import { Box, Combobox, Group, Input, InputBase, Tabs, Title, useCombobox } from '@mantine/core';
import {
  IconSettings,
  IconUserCircle,
  IconUsersGroup,
  TablerIconsProps,
} from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiClient } from '@/globals';

const TABS_DATA = [
  {
    label: 'PERSONAL',
    options: [
      {
        icon: IconUserCircle,
        value: 'my-settings',
        label: 'My Settings',
      },
    ],
  },
];

const ALL_TABS_OPTIONS: TabData[] = TABS_DATA.flatMap((group) => group.options);

interface TabData {
  icon: (props: TablerIconsProps) => JSX.Element;
  value: string;
  label: string;
}

export const MobileTabSelect = ({ tabValue }: { tabValue: string }) => {
  const navigate = useNavigate();

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
    },
  });

  const currentTab = useMemo(
    () => ALL_TABS_OPTIONS.find((option) => option.value === tabValue) ?? null,
    [tabValue]
  );

  const [value, setValue] = useState<TabData | null>(currentTab);

  return (
    <Combobox
      store={combobox}
      // @ts-expect-error because the val type is TabData
      onOptionSubmit={(val: TabData) => {
        setValue(val);
        navigate(`/settings/${val.value}`);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          type="button"
          variant="filled"
          pointer
          size="md"
          rightSection={<Combobox.Chevron />}
          rightSectionPointerEvents="none"
          onClick={() => {
            combobox.toggleDropdown();
          }}
          mb="xl"
        >
          {value?.label ?? <Input.Placeholder>Pick value</Input.Placeholder>}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        {TABS_DATA.map((group) => (
          <Combobox.Group key={group.label} label={group.label}>
            {group.options.map((option) => (
              <Combobox.Option
                key={option.value}
                // @ts-expect-error because the option type is TabData
                value={option as TabData}
              >
                <Group align="center">
                  <option.icon size="16px" />
                  {option.label}
                </Group>
              </Combobox.Option>
            ))}
          </Combobox.Group>
        ))}
      </Combobox.Dropdown>
    </Combobox>
  );
};

export const DesktopTabSelect = () => {
  const companyListQuery = apiClient.queries.useCompanyList();

  const companies = companyListQuery.data?.results ?? [];
  const companyTabs = companies.map((company) => ({
    label: company.name,
    options: [
      {
        icon: IconSettings,
        value: `company-settings/${company.id}`,
        label: 'Company Settings',
        companyId: company.id,
      },
      {
        icon: IconUsersGroup,
        value: `manage-users/${company.id}`,
        label: 'Manage Users',
        companyId: company.id,
      },
      // commenting this out until we are ready for billing
      // {
      //   icon: IconCreditCard,
      //   value: `billing/${company.id}`,
      //   label: 'Billing',
      //   companyId: company.id,
      // },
    ],
  }));
  return (
    <Tabs.List w={250}>
      {TABS_DATA.map((group) => (
        <Box key={group.label}>
          <Title order={6} textfor="subheading" ml="xs" mb="xs">
            {group.label}
          </Title>
          {group.options.map((option) => (
            <Tabs.Tab
              key={option.value}
              value={option.value}
              leftSection={<option.icon stroke={1.5} size="16px" />}
            >
              {option.label}
            </Tabs.Tab>
          ))}
        </Box>
      ))}
      {companyTabs.map((group) => (
        <Box key={group.label}>
          <Title order={6} textfor="subheading" ml="xs" mb="xs">
            {group.label}
          </Title>
          {group.options.map((option) => (
            <Tabs.Tab
              key={option.value}
              value={option.value}
              leftSection={<option.icon stroke={1.5} size="16px" />}
            >
              {option.label}
            </Tabs.Tab>
          ))}
        </Box>
      ))}
    </Tabs.List>
  );
};
