import { useRef } from 'react';

import { Button, FileInput, ThemeIcon } from '@mantine/core';
import { IconPaperclip } from '@tabler/icons-react';

interface AttachmentUploadButtonProps {
  onChange: (file: File[]) => void;
}

const AttachmentUploadButton = ({ onChange }: AttachmentUploadButtonProps) => {
  const inputRef = useRef<HTMLButtonElement>(null);

  const open = () => inputRef.current?.click();

  const handleChange = (files: File[]) => {
    // const filteredFiles = files
    //   .filter((file) => file.size <= 10_000_000) // 10 MB
    //   .filter((file, index, self) => self.findIndex((f) => f.name === file.name) === index)
    //   .filter((file) => ['image/jpeg', 'image/png', 'image/gif'].includes(file.type));

    onChange(files);
  };

  return (
    <>
      <FileInput onChange={handleChange} multiple ref={inputRef} display="none" />

      <div>
        <Button
          variant="light"
          onClick={open}
          leftSection={
            <ThemeIcon color="mantine-color-blue-6">
              <IconPaperclip size={16} />
            </ThemeIcon>
          }
        >
          Upload Attachment
        </Button>
      </div>
    </>
  );
};

export default AttachmentUploadButton;
