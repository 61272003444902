import { DatePickerInput } from '@mantine/dates';
import { Group } from '@mantine/core';

import type { DateRange } from '@/store/risk-store';

import '@mantine/dates/styles.css';

interface DateRangeFilterProps {
  selected: DateRange;
  onChange: (value: DateRange) => void;
}

export const DateRangeFilter = ({ selected, onChange }: DateRangeFilterProps) => (
  <Group>
    <DatePickerInput
      label="from"
      placeholder="Pick starting date"
      value={selected[0]}
      onChange={(value) => {
        if (selected[1] && value && selected[1] < value) {
          onChange([selected[1], value]);
          return;
        }

        onChange([value, selected[1]]);
      }}
      popoverProps={{
        withinPortal: false,
      }}
      clearable
    />

    <DatePickerInput
      label="to"
      placeholder="Pick the end date"
      value={selected[1]}
      onChange={(value) => {
        if (selected[0] && value && selected[0] > value) {
          onChange([value, selected[0]]);
          return;
        }

        onChange([selected[0], value]);
      }}
      popoverProps={{
        withinPortal: false,
      }}
      clearable
    />
  </Group>
);
