import { Box, Group, Space, Stack, Title } from '@mantine/core';
import UsersTable from '@/components/Users/UsersTable/UsersTable';
import { useCompany } from '@/hooks/use-company';
import { InviteUserButton } from '../SettingsForms/InviteUserButton';
import PendingInvitationsTable from '@/components/Users/PendingInvitations/PendingInvitationsTable';

export const ManageUsersTab = () => {
  const company = useCompany();

  const SubTitle = ({ title }: { title: string }) => (
    <Title order={2} textfor="h5" mb="xs">
      {title} [{company.name}]
    </Title>
  );

  return (
    <Stack>
      <Group justify="space-between" align="flex-start">
        <Box>
          <SubTitle title="Manage Users" />
        </Box>

        <InviteUserButton />
      </Group>

      <UsersTable />

      <Space h="xl" />

      <Stack gap="xs">
        <Title order={2} textfor="h4">
          Pending Invitations
        </Title>

        <PendingInvitationsTable />
      </Stack>
    </Stack>
  );
};
