import { Link } from 'react-router-dom';
import moment from 'moment';

import { Badge, Box, Button, Card, Group, Progress, Stack, Text, Title } from '@mantine/core';

import type { Contract } from '@/api-client';
import { currencyFormat } from '@/utils';

interface BadgeCardProps {
  contract: Contract;
}

export function ContractCard({ contract }: BadgeCardProps) {
  return (
    <Card>
      <Group justify="space-between">
        <Group>
          <Title textfor="h2">{contract.project.name}</Title>
          <Badge color={contract.project.status === 'active' ? 'green' : 'red'} variant="light">
            {contract.project.status}
          </Badge>
          <Text>
            {currencyFormat(contract.rate)}
            {contract.payment_option === 'fixed' && '/hr'}
          </Text>
        </Group>
        <Text textfor="info">{moment(contract.project.created).format('LL')}</Text>
      </Group>

      <Group>
        <Text>{contract.project.company.name}</Text>
      </Group>

      <Group my="sm">
        {contract.project.skills.length > 0 ? (
          contract.project.skills.map((skill) => (
            <Badge key={skill.id} color="blue" variant="light">
              {skill.name}
            </Badge>
          ))
        ) : (
          <Badge key="other" color="blue" variant="light">
            other
          </Badge>
        )}
      </Group>

      <Stack gap="xs">
        <Box>
          <Text lineClamp={2} textfor="description">
            {contract.project.description}
          </Text>
        </Box>
      </Stack>

      <Box my="md">
        <Group justify="space-between">
          <Text component="span" textfor="label">
            Risk Submitted
          </Text>
          <Text component="span" textfor="body">
            10/24
          </Text>
        </Group>
        <Progress size="sm" color="cyan" value={80} striped />
      </Box>

      <Group>
        <Button fullWidth component={Link} to={`/contract/${contract.id}`}>
          Show details
        </Button>
      </Group>
    </Card>
  );
}
