import React from 'react';
import { DropResult, OnDragEndResponder } from 'react-beautiful-dnd';
import { CardProps, ColumnItems, PatchedRiskWrapper } from '@/components/Risks/RiskList/props';

/**
 * Method to handle the drag end event for a draggable item.
 *
 * @param {ColumnItems} columns - The columns state.
 * @param {React.Dispatch<React.SetStateAction<ColumnItems>>} setColumns - The setColumns function to update the columns state.
 * @param {(p: PatchedRiskWrapper) => void} mutate - The mutate function to update a patched report wrapper.
 *
 * @returns {OnDragEndResponder} - The function to handle the drag end event.
 */
export function getOnDragEnd(
  columns: ColumnItems,
  setColumns: React.Dispatch<React.SetStateAction<ColumnItems>>,
  mutate: (p: PatchedRiskWrapper) => void
): OnDragEndResponder {
  return ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) {
      return null;
    }

    // Make sure we're actually moving the item
    if (source.droppableId === destination.droppableId && destination.index === source.index) {
      return null;
    }

    // Set start and end variables
    const start = columns[source.droppableId];
    const end = columns[destination.droppableId];

    mutate({
      riskId: start.list[source.index].id,
      payload: { index: destination.index, status: end.id },
    } as PatchedRiskWrapper);

    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter((_: CardProps, idx: number) => idx !== source.index);

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.list[source.index]);

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        list: newList,
      };

      // Update the state
      setColumns((state) => ({ ...state, [newCol.id]: newCol }));
      return null;
    }
    // If start is different from end, we need to update multiple columns
    // Filter the start list like before
    const newStartList = start.list.filter((_: CardProps, idx: number) => idx !== source.index);

    // Create a new start column
    const newStartCol = {
      id: start.id,
      list: newStartList,
    };

    // Make a new end list array
    const newEndList = end.list;

    // Insert the item into the end list
    newEndList.splice(destination.index, 0, start.list[source.index]);

    // Create a new end column
    const newEndCol = {
      id: end.id,
      list: newEndList,
    };

    // Update the state
    setColumns((state) => ({
      ...state,
      [newStartCol.id]: newStartCol,
      [newEndCol.id]: newEndCol,
    }));
    return null;
  };
}
