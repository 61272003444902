import { ActionIcon, useMantineColorScheme } from '@mantine/core';
import { IconMoon, IconSun } from '@tabler/icons-react';

export const ThemeSwitcher = () => {
  const { colorScheme, setColorScheme } = useMantineColorScheme();

  return colorScheme === 'dark' ? (
    <ActionIcon
      onClick={() => {
        setColorScheme('light');
      }}
    >
      <IconSun stroke={1.5} size="16px" />
    </ActionIcon>
  ) : (
    <ActionIcon
      onClick={() => {
        setColorScheme('dark');
      }}
    >
      <IconMoon stroke={1.5} size="16px" />
    </ActionIcon>
  );
};
