import { useEffect, useState } from 'react';
import { Button, CheckIcon, Combobox, Group, Text, ThemeIcon, useCombobox } from '@mantine/core';
import { IconAdjustmentsHorizontal, IconX } from '@tabler/icons-react';
import { RiskTagBadge } from '../RiskTags/RiskTagBadge';
import classes from './RiskFilters.module.css';
import styles from './ButtonMultiSelect.module.css';
import { FilterMeta, FilterOptions } from '@/hooks/use-filters';
import { CategoryEnum, SeverityEnum } from '@/api-client';
import { Status } from '@/store/risk-store';
import { removeUndercore } from '@/utils';
import { Gravatar } from '@/components/Common';

export function ButtonMultiSelect({
  filterMeta,
  activeTab,
  onChange,
  selected,
}: {
  filterMeta: FilterMeta;
  activeTab: 'list' | 'kanban';
  onChange?: (selected: string[] | string) => void;
  selected: SeverityEnum[] | CategoryEnum[] | string[] | Status | undefined;
}) {
  const [selectedItems, setSelectedItems] = useState<string[] | string | undefined>(selected);

  useEffect(() => {
    selectedItems && onChange?.(selectedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
    },
  });

  const isSelected = selected?.length;

  const options = filterMeta.options.map((item: FilterOptions, i: number) => (
    <Combobox.Option value={item.value} key={i}>
      <Group justify="space-between">
        <Group>
          {item.icon && <ThemeIcon className={styles[item.value]}>{item.icon}</ThemeIcon>}
          {filterMeta.avatar && (
            <Gravatar
              tooltipPosition="bottom"
              name={item.value}
              emailHash={item.emailHash!}
              size={18}
              withoutTooltip
            />
          )}
          {item.badge && (
            <ThemeIcon
              color={`var(--severity-color-${item.value})`}
              radius="xl"
              variant="filled"
              size={8}
            />
          )}
          {item.meta ? (
            <RiskTagBadge key={item.id} tag={item.meta} />
          ) : (
            <Text className={classes.label}>{removeUndercore(item.label)}</Text>
          )}
        </Group>
        {selected?.includes(item.value as never) && <CheckIcon size={12} />}
      </Group>
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      width={filterMeta.label === 'Category' ? 320 : 180}
      position="bottom-start"
      withArrow
      withinPortal={false}
      positionDependencies={[selectedItems]}
      onOptionSubmit={(val) => {
        setSelectedItems((current) => typeof current === 'object' && filterMeta.id !== 'status'
            ? val === 'all'
              ? ['all']
              : current.includes(val)
                ? current.filter((item) => item !== val)
                : [...current.filter((value) => value !== 'all'), val]
            : val);
      }}
      disabled={activeTab === 'kanban' && filterMeta.id === 'status'}
    >
      <Combobox.Target>
        <Button
          onClick={() => {
            combobox.toggleDropdown();
          }}
          variant={isSelected ? 'light' : 'subtle'}
          color={isSelected ? 'blue' : 'gray'}
          size="xs"
          mt={0}
          leftSection={filterMeta.icon ?? <IconAdjustmentsHorizontal stroke={1.5} size="1rem" />}
          disabled={activeTab === 'kanban' && filterMeta.id === 'status'}
          rightSection={
            isSelected ? (
              <IconX
                z={10}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedItems([]);
                }}
                className={classes.closeIcon}
                stroke={4}
                size={18}
              />
            ) : null
          }
        >
          {filterMeta.label}
        </Button>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
