import { Link } from 'react-router-dom';

import { Anchor, Breadcrumbs } from '@mantine/core';

import classes from './BreadcrubmsNavigation.module.css';

export interface Breadcrumb {
  label: string;
  href: string;
}

interface BreadcrumbNavigationProps {
  breadcrumbs: Breadcrumb[];
}

const BreadcrumbNavigation = ({ breadcrumbs }: BreadcrumbNavigationProps) => (
  <Breadcrumbs separator="\" classNames={classes}>
    {breadcrumbs.map((item) => (
      <Anchor component={Link} to={item.href} key={item.href}>
        {item.label}
      </Anchor>
    ))}
  </Breadcrumbs>
);

export default BreadcrumbNavigation;
