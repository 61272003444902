import { useCallback } from 'react';
import { UseMutationOptions } from '@tanstack/react-query';

import { apiClient, queryClient } from '@/globals';
import { queryKeys } from '@/api-client';

export const useSignOut = (
  options?: Omit<UseMutationOptions<unknown, unknown, unknown>, 'mutationFn'>
) => {
  const signOutMutation = apiClient.mutations.useAuthLogoutCreate({
    ...options,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.userMeRetrieve() });
      options?.onSuccess?.(...args);
    },
  });

  return useCallback(() => {
    signOutMutation.mutate({});
  }, [signOutMutation]);
};
