import { json, LoaderFunction } from 'react-router-dom';
import { LoaderFunctionArgs } from '@remix-run/router/utils';
import { queryClient } from '@/globals';
import { projectQuery, riskQuery } from '@/queries.loader';

export const RiskDetailLoader: LoaderFunction = async ({ params }: LoaderFunctionArgs) => {
  const { riskId } = params;
  if (riskId === undefined) {
    throw json({ message: 'riskId is required' }, { status: 401 });
  }

  const risk = await queryClient.fetchQuery({
    ...riskQuery(riskId),
    staleTime: 1000 * 60,
  });

  const project = await queryClient.fetchQuery({
    ...projectQuery(risk.project),
    staleTime: 1000 * 60,
  });

  return { risk, project };
};
