/**
 * Calculates the total number of pages based on the count of items and page size.
 *
 * @param {number | undefined} count - The total count of items.
 * @param {number}  pageSize - The number of items to be displayed on each page. Default is 20.
 * @returns {number} - The total number of pages.
 */
export const getTotalPages = (count: number | undefined, pageSize: number): number => {
  if (count === 0 || count === undefined) {
    return 1;
  }
  return Math.ceil(count / pageSize);
};

/**
 * Checks if an array of results has any values.
 *
 * @param {Array} results - The array of results to be checked.
 * @returns {boolean} - Returns true if the array has results, otherwise false.
 */
export const hasResults = (results: unknown[] | undefined): boolean => {
  if (results === undefined) return false;
  return results.length > 0;
};

/**
 * Formats a number as a currency string.
 *
 * @param {string} num - The number to format as currency.
 * @return {string} The number formatted as a currency string.
 */
export function currencyFormat(num: string | undefined): string {
  if (num === undefined) return '$0.00';
  const parsedFloat = parseFloat(num);
  const truncatedValue = Math.floor(parsedFloat);
  return `$${truncatedValue.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
}

/**
 * Truncates the given input string if it exceeds the maximum length of 250 characters.
 * If the input string length is greater than 100 characters, it truncates the string and adds '...' at the end.
 *
 * @param {string} input - The input string to be truncated.
 * @return {string} - The truncated string.
 */
export function truncate(input: string) {
  const maxLen = 250;
  if (input.length > 100) {
    return `${input.substring(0, maxLen)}...`;
  }
  return input;
}

/**
 * Checks if the given object is a File object.
 *
 * @param {unknown} obj
 * @return {boolean} - Returns true if the object is a File, otherwise false.
 */
export function isFile(obj: unknown): obj is File {
  return obj instanceof File;
}

/**
 * Replaces all underscores in the given string with spaces.
 *
 * @param {string} text - The input text
 * @returns {string} - The modified text
 */
export function removeUndercore(text: string): string {
  const newText = text.replaceAll('_', ' ');
  return newText;
}
