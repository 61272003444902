import { useState } from 'react';

import { ActionIcon, Tooltip } from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';

interface CopyButtonProps {
  value: string;
  timeout?: number;
}

export const CopyButton = ({ value, timeout = 2000 }: CopyButtonProps) => {
  const [icon, setIcon] = useState<React.ReactNode>(<IconCopy stroke={1.5} size="1rem" />);

  const onCopy = async () => {
    await navigator.clipboard.writeText(value);

    setIcon(<IconCheck stroke={1.5} size="1rem" />);

    setTimeout(() => {
      setIcon(<IconCopy stroke={1.5} size="1rem" />);
    }, timeout);
  };

  return (
      <Tooltip withArrow label="Copy">
        <ActionIcon variant="subtle" onClick={onCopy}>
          {icon}
        </ActionIcon>
      </Tooltip>
  );
};
