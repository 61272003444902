import { useMantineColorScheme } from '@mantine/core';
import MDEditor, { ContextStore } from '@uiw/react-md-editor';

import './Markdown.css';

interface MarkdownEditorProps {
  value: string;
  onChange: (
    value?: string | undefined,
    event?: React.ChangeEvent<HTMLTextAreaElement> | undefined,
    state?: ContextStore | undefined
  ) => void;
}

export const MarkdownEditor = ({ value, onChange }: MarkdownEditorProps) => {
  const theme = useMantineColorScheme();
  return (
    <div data-color-mode={theme.colorScheme === 'dark' ? 'dark' : 'light'}>
      <MDEditor value={value} preview="edit" onChange={onChange} />
    </div>
  );
};
