import { filesize } from 'filesize';

import { IconInfoCircle, IconX } from '@tabler/icons-react';
import { ActionIcon, Card, Group, Stack, Text, Tooltip, ThemeIcon, Loader } from '@mantine/core';
import { getIcon } from '@/components/Attachments/utils';

import classes from './AttachmentDisplay.module.css';

interface AttachmentDisplayProps {
  fileType?: string | null;
  filename?: string | null;
  fileSize?: number | null;
  onDelete: () => void;
  isLoading?: boolean;
  isError?: boolean;
}

const LoadingAttachment = () => (
  <Card p="sm" withBorder className={classes.attachmentWrapper}>
    <Group justify="center">
      <ThemeIcon variant="transparent" color="red">
        <Loader size="xs" />
      </ThemeIcon>
    </Group>
  </Card>
);

const FailedAttachment = ({ onDelete }: { onDelete: () => void }) => (
  <Card p="sm" withBorder className={classes.attachmentWrapper}>
    <Group justify="space-between">
      <Group>
        <Text textfor="attachment" c="red" className={classes.attachmentText}>
          Failed to load
        </Text>

        <ThemeIcon variant="transparent" color="red">
          <IconInfoCircle stroke={1.5} size={16} />
        </ThemeIcon>
      </Group>

      <ActionIcon onClick={onDelete} variant="subtle" color="red">
        <IconX stroke={1.5} size={16} />
      </ActionIcon>
    </Group>
  </Card>
);

export const AttachmentDisplay = ({
  fileType,
  filename,
  fileSize,
  onDelete,
  isError,
  isLoading,
}: AttachmentDisplayProps) => {
  if (isLoading) {
    return <LoadingAttachment />;
  }

  if (isError) {
    return <FailedAttachment onDelete={onDelete} />;
  }

  const size = filesize(fileSize ?? 0, { standard: 'jedec' });

  return (
    <Card p="sm" withBorder className={classes.attachmentWrapper}>
      <Group justify="space-between">
        <Group>
          {getIcon(fileType ?? '')}

          <Stack gap={0}>
            <Tooltip label={filename}>
              <Text textfor="attachment" className={classes.attachmentText}>
                {filename}
              </Text>
            </Tooltip>
            <Text textfor="info">{size}</Text>
          </Stack>
        </Group>
        <ActionIcon onClick={onDelete} variant="subtle" color="red">
          <IconX stroke={1.5} size={16} />
        </ActionIcon>
      </Group>
    </Card>
  );
};
