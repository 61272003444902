import { LoaderFunctionArgs } from '@remix-run/router/utils';
import { json, LoaderFunction } from 'react-router-dom';
import { queryClient } from '@/globals';
import { contractQuery, projectQuery, userMeQuery } from '@/queries.loader';

export const NewRiskLoader: LoaderFunction = async ({ params, request }: LoaderFunctionArgs) => {
  const { projectId } = params;
  if (projectId === undefined) {
    throw json({ message: 'projectId is required' }, { status: 401 });
  }

  const contractId = new URL(request.url).searchParams.get('contract');
  if (contractId === null) {
    throw json({ message: 'contractId is required' }, { status: 401 });
  }

  const project = await queryClient.fetchQuery({
    ...projectQuery(projectId),
    staleTime: 1000 * 60,
  });

  const contract = await queryClient.fetchQuery({
    ...contractQuery(contractId),
    staleTime: 1000 * 60,
  });

  const user = await queryClient.fetchQuery({
    ...userMeQuery(),
    staleTime: 1000 * 60,
  });

  if (user.user_type === 'researcher' && contract.researcher.id !== user.id) {
    throw json({ message: 'You are not the researcher on this contract' }, { status: 401 });
  }

  return { project, contract, user };
};
