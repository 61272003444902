import { Box, Modal, Stack, Text } from '@mantine/core';

interface SuccessModalProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  description: string;
  image?: string;
}

const SuccessModal = ({ title, description, image, opened, onClose }: SuccessModalProps) => (
  <Modal opened={opened} onClose={onClose} title={title} centered>
    <Stack gap="md">
      <Text textfor="paragraph">{description}</Text>
      <Box>
        <img src={image} alt="" />
      </Box>
    </Stack>
  </Modal>
);

export default SuccessModal;
