import { useState, useEffect } from 'react';
import { Badge, Group, Progress, Stack, HoverCard, Text, List, Box } from '@mantine/core';

const calculateDaysDifference = (startDate: Date, endDate: Date | null) => {
  const end = endDate ?? new Date();
  const timeDifference = end.getTime() - startDate.getTime();
  return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
};

const determineStatus = (daysDifference: number) => {
  if (daysDifference <= 30) {
    return 'Good';
  }
  if (daysDifference <= 60) {
    return 'Warning';
  }
  return 'Alarm';
};

const RiskSLA = ({
  createdDate,
  closedDate,
}: {
  createdDate: Date | string;
  closedDate?: Date | string | null;
}) => {
  const convertedCreatedDate = createdDate instanceof Date ? createdDate : new Date(createdDate);
  const convertedClosedDate = closedDate instanceof Date
      ? closedDate
      : closedDate
          ? new Date(closedDate)
          : null;
  const daysDifference = calculateDaysDifference(convertedCreatedDate, convertedClosedDate);
  const [status, setStatus] = useState<string>('Good');

  useEffect(() => {
    setStatus(determineStatus(daysDifference));
  }, [daysDifference]);

  const color = status === 'Good' ? 'green' : status === 'Warning' ? 'yellow' : 'red';

  return (
      <Stack>
        <HoverCard width={320} shadow="md">
          <HoverCard.Target>
            <Progress value={daysDifference} mt="md" size="xs" radius="xl" color={color} />
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Group justify="space-between">
              <Text size="sm" fw={700}>
                {daysDifference} days
              </Text>
              <Badge color={color}>{status}</Badge>
            </Group>
            <Text fz="sm" fw={500} mt="md" c="dimmed">
              Per NIST, SLA is tracked as follows:
            </Text>
            <Box fz="sm" c="dimmed" mt={5}>
              <List size="sm" c="dimmed">
                <List.Item>Days 0-30: Good</List.Item>
                <List.Item>Days 31-60: Warning</List.Item>
                <List.Item>Days 61+: Alarm</List.Item>
              </List>
            </Box>
          </HoverCard.Dropdown>
        </HoverCard>
      </Stack>
  );
};

export default RiskSLA;
