import { Code, Container, Grid, Skeleton, Title } from '@mantine/core';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { apiClient } from '@/globals';
import { useTrackPageView } from '@/hooks/use-track-page-view';

export function ResearcherDetailPage() {
  const { researcherId } = useParams();
  if (researcherId === undefined) {
    throw new Error('Researcher ID is required');
  }

  const result = apiClient.queries.useResearcherRetrieve(researcherId);

  useTrackPageView('researcher_details');

  return (
    <Container>
      <Grid>
        <Grid.Col span={{ base: 12, xs: 12 }}>
          <Title order={3}>Researcher details</Title>
        </Grid.Col>

        <Grid.Col span={{ base: 12, xs: 12 }}>
          {result.isError && <p>Error: {(result.error as AxiosError).message}</p>}
        </Grid.Col>

        {result.isLoading && (
          <Grid.Col span={{ base: 12, xs: 12 }}>
            <Skeleton height={200} />
          </Grid.Col>
        )}

        {result.isSuccess && (
          <Grid.Col span={{ base: 12, xs: 12 }}>
            <Code block>{JSON.stringify(result.data, null, 2)}</Code>
          </Grid.Col>
        )}
      </Grid>
    </Container>
  );
}
