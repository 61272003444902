import { LoaderFunction } from 'react-router-dom';
import { queryClient } from '@/globals';
import { contractListQuery } from '@/queries.loader';

export const ContractsLoader: LoaderFunction = async () => {
  const contractList = await queryClient.fetchQuery({
    ...contractListQuery(),
    staleTime: 1000 * 60,
  });

  return { contractList };
};
