import { Table } from '@mantine/core';
import { useCompany } from '@/hooks/use-company';
import { UserRow } from './UserRow';

import classes from './UsersTable.module.css';

export const UsersTable = () => {
  const company = useCompany();

  return (
    <Table classNames={classes}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>USERS</Table.Th>
          <Table.Th w="100px">STATUS</Table.Th>
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        {company.users.map((user) => (
          <UserRow key={`user-row-${user.id}`} user={user} />
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default UsersTable;
