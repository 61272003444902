import { IconBook, IconPencil } from '@tabler/icons-react';
import {
  Group,
  rem,
  Space,
  Text,
  Card,
  Tooltip,
  Anchor,
  ThemeIcon,
  ActionIcon,
} from '@mantine/core';
import React from 'react';
import ProjectStats from '@/components/Projects/ProjectStats/ProjectStats';
import BreadcrumbNavigation, {
  type Breadcrumb,
} from '@/components/Common/BreadcrumbNavigation/BreadcrumbNavigation';
import { ProjectScopeModal } from '@/components/Projects/ProjectScopeModal/ProjectScopeModal';
import { ProjectTitleEditModal } from '@/components/Projects/ProjectTitleEditModal/ProjectTitleEditModal';
import If from '@/components/Common/If/If';

import type { Contract, Project, UserTypeEnum } from '@/api-client';

import classes from './ProjectHeader.module.css';

interface ActiveProjectHeaderProps {
  project: Project;
  contract?: Contract;
  userType: UserTypeEnum;
  breadcrumbs: Breadcrumb[];
}

const Icon = ({ children }: React.PropsWithChildren) => (
  <ThemeIcon variant="transparent" color="gray" size="md">
    {children}
  </ThemeIcon>
);

export function ProjectHeader({
  project,
  contract,
  userType,
  breadcrumbs,
}: ActiveProjectHeaderProps) {
  return (
    <Group className={classes.HeaderContainer} justify="space-between" py={rem(8)}>
      <div>
        <BreadcrumbNavigation breadcrumbs={breadcrumbs} />
        <Space />
        <Group>
          <Text textfor="h3">{project.name}</Text>

          <If condition={userType !== 'researcher'}>
            <ProjectTitleEditModal projectId={project.id} currentTitle={project.name}>
              <Tooltip label="Change Project title">
                <ActionIcon variant="subtle">
                  <IconPencil stroke={1.5} size={16} />
                </ActionIcon>
              </Tooltip>
            </ProjectTitleEditModal>
          </If>

          {/* only show the "New Risk" button for researchers with a valid contract */}
          <If condition={userType === 'researcher' && contract?.active}>
            <ProjectScopeModal projectId={project.id}>
              <Anchor>
                <Icon>
                  <Tooltip label="Project scope">
                    <IconBook stroke={1.5} size={16} />
                  </Tooltip>
                </Icon>
              </Anchor>
            </ProjectScopeModal>
          </If>
        </Group>
        <Text textfor="info">{project.company.name}</Text>
      </div>
      <Group align="center">
        <Card p="xs">
          <ProjectStats project={project} />
        </Card>
      </Group>
    </Group>
  );
}

export default ProjectHeader;
