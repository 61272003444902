import React from 'react';
import * as z from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';

import { Box, Button, Group, Modal, Stack, Text, TextInput } from '@mantine/core';

import { apiClient } from '@/globals';

const projectTitleSchema = z.object({
  name: z
    .string()
    .min(3, 'Title must be at least 3 characters')
    .max(50, 'Title must be less than 50 characters'),
});

interface ProjectTitleEditModalProps {
  children: JSX.Element;
  projectId: string;
  currentTitle?: string;
  onSuccess?: () => void;
}

type ProjectTitleFormValues = z.infer<typeof projectTitleSchema>;

export const ProjectTitleEditModal = ({
  children,
  projectId,
  currentTitle,
  onSuccess,
}: ProjectTitleEditModalProps) => {
  const [opened, { open, close }] = useDisclosure(false);

  const { onSubmit, reset, isValid, getInputProps } = useForm({
    validateInputOnChange: true,
    initialValues: {
      name: currentTitle ?? '',
    },
    validate: zodResolver(projectTitleSchema),
  });

  const handleClose = () => {
    reset();
    close();
  };

  const projectUpdateMutation = apiClient.mutations.useProjectPartialUpdate(projectId, {
    onSuccess: () => {
      close();
      onSuccess?.();
    },
  });

  const handleSubmit = (formValues: ProjectTitleFormValues) => {
    projectUpdateMutation.mutate({
      name: formValues.name,
    });
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: open,
      })}

      <Modal opened={opened} onClose={handleClose} title="Edit Project Title" centered>
        <form onSubmit={onSubmit(handleSubmit)}>
          <Stack gap="md">
            <Box>
              <Text textfor="paragraph" mb="xs">
                Change the project title
              </Text>
              <TextInput label="Title" {...getInputProps('name')} />
            </Box>

            <Group justify="flex-end">
              <Button variant="subtle" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" loading={projectUpdateMutation.isPending} disabled={!isValid()}>
                Save
              </Button>
            </Group>
          </Stack>
        </form>
      </Modal>
    </>
  );
};
