import {
  Button,
  Card,
  createTheme,
  Group,
  Indicator,
  Menu,
  Space,
  Text,
  ActionIcon,
  Title,
  Flex,
  Select,
  ThemeIcon,
  Container,
  rem,
  Combobox,
  Accordion,
  Modal,
  Anchor,
  Avatar,
  TextInput,
  PasswordInput,
  virtualColor,
  colorsTuple,
} from '@mantine/core';

import { IconCaretUpFilled } from '@tabler/icons-react';

import '@/css/globals.css';
import classes from '@/css/Theme.module.css';

export const theme = createTheme({
  primaryShade: 6,
  fontFamily: 'Inter, sans-serif',
  fontSizes: {
    xs: rem(13),
    sm: rem(14),
    md: rem(16),
    lg: rem(18),
    xl: rem(20),
  },
  spacing: {
    xs: rem(6),
    sm: rem(10),
    md: rem(14),
    lg: rem(18),
    xl: rem(20),
  },
  headings: {
    sizes: {
      h1: { fontSize: rem(28) },
      h2: { fontSize: rem(24) },
      h3: { fontSize: rem(20) },
      h4: { fontSize: rem(18) },
      h5: { fontSize: rem(16) },
      h6: { fontSize: rem(14) },
    },
  },
  breakpoints: {
    ss: 'm',
    xs: '12em',
  },

  colors: {
    dark: [
      'rgb(189, 190, 194)',
      'rgb(156, 158, 163)',
      'rgb(123, 126, 133)',
      'rgb(89, 93, 102)',
      'rgb(48, 53, 64)',
      'rgb(38, 42, 51)',
      'rgb(29, 32, 38)',
      'rgb(19, 21, 26)',
      'rgb(10, 11, 13)',
      'rgb(0, 0, 0)',
    ],
    gray: [
      '#f8fafc',
      '#f8fafc',
      '#ecf0f4',
      '#d9dfe6',
      '#c0c8d4',
      '#8996a9',
      '#5b687d',
      '#3f4a5c',
      '#2c3748',
      '#192130',
      '#10172a',
    ],
    blue: [
      '#eef6ff',
      '#ddeeff',
      '#c3e1ff',
      '#96ceff',
      '#5ab1ff',
      '#1e90ff',
      '#0071e5',
      '#005dc8',
      '#0049a8',
      '#073d89',
    ],
    iconLight: colorsTuple('#5b687d'),
    iconDark: colorsTuple('rgb(248, 250, 252)'),
    icon: virtualColor({
      name: 'icon',
      dark: 'iconDark',
      light: 'iconLight',
    }),
  },
  defaultRadius: 'sm',
  components: {
    Accordion: Accordion.extend({
      defaultProps: {
        chevron: <IconCaretUpFilled />,
      },
    }),
    Anchor: Anchor.extend({
      defaultProps: {
        c: 'dark',
      },
    }),
    Button: Button.extend({
      defaultProps: {
        radius: 'sm',
        size: 'xs',
        fw: 500,
      },
    }),
    Card: Card.extend({
      defaultProps: {
        radius: 'md',
        padding: 'lg',
        withBorder: true,
      },
    }),
    Combobox: Combobox.extend({
      defaultProps: {
        transitionProps: { transition: 'pop-top-right' },
        position: 'bottom-end',
        shadow: 'md',
      },
    }),
    Container: Container.extend({
      defaultProps: {
        size: '1400px',
      },
    }),
    Flex: Flex.extend({
      defaultProps: {
        align: 'center',
        gap: 'xs',
      },
    }),
    Group: Group.extend({
      defaultProps: {
        gap: 'xs',
      },
    }),
    ActionIcon: ActionIcon.extend({
      defaultProps: {
        variant: 'default',
        style: {
          strokeWidth: 1.25,
        },
      },
    }),
    Indicator: Indicator.extend({
      defaultProps: {
        size: 20,
        color: 'blue',
        position: 'top-end',
        offset: 3,
        inline: true,
      },
    }),
    Menu: Menu.extend({
      defaultProps: {
        transitionProps: { transition: 'pop-top-right' },
        position: 'bottom-end',
        shadow: 'md',
      },
    }),
    MenuItem: Menu.Item.extend({
      defaultProps: {
        color: 'gray',
      },
    }),
    Modal: Modal.extend({
      defaultProps: {
        padding: 'lg',
        radius: 'md',
      },
      classNames: {
        header: classes.modal_header,
        title: classes.modal_title,
      },
    }),
    Space: Space.extend({
      defaultProps: {
        h: 'xs',
      },
    }),

    Select: Select.extend({
      defaultProps: {
        allowDeselect: false,
      },
    }),
    Text: Text.extend({
      defaultProps: {
        textfor: 'paragraph',
      },
    }),
    ThemeIcon: ThemeIcon.extend({
      classNames: {
        root: classes.themeIcon_root,
      },
      defaultProps: {
        variant: 'transparent',
        style: {
          strokeWidth: 1.25,
        },
        color: 'icon.6',
      },
    }),
    Title: Title.extend({
      defaultProps: {
        textfor: 'h5',
      },
    }),
    Avatar: Avatar.extend({
      classNames: {
        root: classes.avatar_root,
      },
    }),
    TextInput: TextInput.extend({
      defaultProps: {
        size: 'sm',
      },
    }),
    PasswordInput: PasswordInput.extend({
      defaultProps: {
        size: 'sm',
      },
    }),
  },
});
