import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';

import { QueryClientProvider } from '@tanstack/react-query';
import { Notifications } from '@mantine/notifications';

import { MantineProvider } from '@mantine/core';
import AnalyticsProvider from '@/Providers/AnalyticsProvider';
import { Router } from '@/Router';

import { theme } from '@/theme';
import { queryClient } from '@/globals';
import Confetti from './components/Common/Confetti';

export default function App() {
  return (
    <MantineProvider defaultColorScheme="light" theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Notifications />
        <Router />
        <Confetti />
        <AnalyticsProvider />
      </QueryClientProvider>
    </MantineProvider>
  );
}
