import { useForm, zodResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import * as z from 'zod';
import { IconUserPlus } from '@tabler/icons-react';
import { Box, Button, Group, Modal, Stack, Text, TextInput } from '@mantine/core';
import SuccessModal from '@/components/Modals/SuccessModal/SuccessModal';
import { useCompany } from '@/hooks/use-company';
import { apiClient, queryClient } from '@/globals';
import { queryKeys } from '@/api-client';

import classes from './Settings.module.css';

const inviteUserSchema = z.object({
  email: z.string().email('Invalid email'),
});

interface InviteUserFormValues {
  email: string;
}

export const InviteUserButton = () => {
  const company = useCompany();

  const [opened, { open: openInviteModal, close: closeInviteModal }] = useDisclosure(false);
  const [showSuccessModal, { open: openSuccessModal, close: closeSuccessModal }] =
    useDisclosure(false);

  const { onSubmit, reset, isValid, getInputProps, values } = useForm<InviteUserFormValues>({
    validateInputOnChange: true,
    initialValues: {
      email: '',
    },
    // validation will be enforced later
    validate: zodResolver(inviteUserSchema),
  });

  const handleClose = () => {
    closeInviteModal();
    closeSuccessModal();
    reset();
  };

  const inviteMutation = apiClient.mutations.useInvitationCreate({
    onSuccess: async () => {
      closeInviteModal();
      openSuccessModal();

      await queryClient.invalidateQueries({
        queryKey: queryKeys.companyPendingInvitationsList(company.id),
      });
    },
  });

  const handleSubmit = (formValues: InviteUserFormValues) => {
    // @ts-expect-error Unecessary properties are marked as required
    inviteMutation.mutate({
      email: formValues.email,
      company: company.id,
    });
  };

  return (
    <>
      <Button
        onClick={openInviteModal}
        mt={0}
        leftSection={<IconUserPlus stroke={1.5} size={16} />}
      >
        Invite User
      </Button>

      <Modal opened={opened} onClose={handleClose} title="Invite User" centered>
        <form onSubmit={onSubmit(handleSubmit)}>
          <Stack gap="md">
            <Box>
              <Text textfor="paragraph" mb="xs">
                Add a member to <b>{company.name}</b>
              </Text>
              <TextInput
                classNames={classes}
                label="Email"
                placeholder="Ex. test@gmail.com"
                {...getInputProps('email')}
              />
            </Box>

            <Group justify="flex-end">
              <Button variant="subtle" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" loading={inviteMutation.isPending} disabled={!isValid()}>
                Send
              </Button>
            </Group>
          </Stack>
        </form>
      </Modal>

      <SuccessModal
        opened={showSuccessModal}
        onClose={handleClose}
        title="Invitation Sent"
        description={`Invitation sent successfully to ${values.email}`}
      />
    </>
  );
};
