import { LoaderFunction } from 'react-router-dom';
import { queryClient } from '@/globals';
import { proposalListQuery } from '@/queries.loader';

export const ProposalsLoader: LoaderFunction = async () => {
  const proposalList = await queryClient.fetchQuery({
    ...proposalListQuery(),
    staleTime: 1000 * 60,
  });

  return { proposalList };
};
