import { notifications } from '@mantine/notifications';

import { Button } from '@mantine/core';
import { apiClient } from '@/globals';
import { useUserMe } from '@/hooks/use-user-me';

export const ResetPasswordButton = () => {
  const { mutate, isPending } = apiClient.mutations.usePasswordResetCreate({
    onSuccess: () => {
      notifications.show({
        color: 'green',
        title: 'Check your email',
        message:
          'We sent you an email with instructions to reset your password. Please check your inbox.',
      });
    },
    onError: () => {
      notifications.show({
        color: 'red',
        title: 'Email failed',
        message:
          'There was an error sending the password reset email. Please try again or contact support.',
      });
    },
  });

  const userMe = useUserMe();
  const userEmail = userMe.email;

  const handleResetPassword = () => {
    mutate({ email: userEmail });
  };

  return (
    <Button onClick={handleResetPassword} loading={isPending}>
      Reset Password
    </Button>
  );
};
