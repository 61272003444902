import { Box, Divider, Stack, Title } from '@mantine/core';

import { PersonalInfoForm } from '../SettingsForms/PersonalInfoForm';
import { ResetPasswordButton } from '../SettingsForms/ResetPasswordButton';

const SubTitle = ({ title }: { title: string }) => (
  <Title order={2} textfor="h5" mb="md">
    {title}
  </Title>
);

export function MySettingsTab() {
  return (
    <Stack>
      <Box>
        <SubTitle title="My Settings" />
        <PersonalInfoForm />
      </Box>

      <Divider my="xl" />

      <Box>
        <SubTitle title="Change My Password" />
        <ResetPasswordButton />
      </Box>
    </Stack>
  );
}
