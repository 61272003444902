import { useLoaderData } from 'react-router-dom';

import { Card, Container, Divider, Grid, Space, Title } from '@mantine/core';
import { MessageList } from '@/components/Common/MessageList/MessageList';
import ProposalHeader from '@/components/Proposals/ProposalHeader/ProposalHeader';

import { useTrackPageView } from '@/hooks/use-track-page-view';
import type { Conversation, Proposal, User } from '@/api-client';

interface LoaderProps {
  proposal: Proposal;
  conversation: Conversation;
  userMe: User;
}

export function ProposalDetailPage() {
  const { proposal, conversation, userMe } = useLoaderData() as LoaderProps;

  useTrackPageView('proposal_details');

  return (
    <Container>
      <Grid>
        <Grid.Col span={{ base: 12, xs: 12 }}>
          <Title order={3}>Proposal details</Title>
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 12 }}>
          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <ProposalHeader proposal={proposal} />
            <Space h="sm" />
            <Divider my="xs" label="Messages" labelPosition="center" />
            <Space h="sm" />
            <MessageList conversation={conversation} userMe={userMe} />
          </Card>
        </Grid.Col>
      </Grid>
    </Container>
  );
}
