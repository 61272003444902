import { apiClient } from '@/globals';
import { Attachment } from '@/api-client';

import { uploadToS3 } from './requests';

/**
 * Uploads a file to S3 and creates a new attachment object.
 */
export const createNewAttachment = (file: File): Promise<Attachment> =>
  uploadToS3(file).then((psr) => {
    if (psr === null) {
      // upload failed somehow, exit early
      throw new Error('Invalid psr');
    }

    // create new attachment with mutation
    return apiClient.requests.attachmentCreate({
      url: psr.url,
      key: psr.fields.key,
      filename: file.name,
      content_type: file.type,
      size: file.size,
    } as Attachment);
  });
