import { Group, Paper, Text } from '@mantine/core';
import moment from 'moment';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Message, PatchedMessage, User } from '@/api-client';
import Gravatar from '@/components/Common/Gravatar/Gravatar';
import { apiClient } from '@/globals';
import AttachmentContent from '@/components/Common/MessageList/AttachmentContent';

interface MessageBoxProps {
  message: Message;
  currentUser: User;
}

interface partialUpdateMessageProps {
  messageId: string;
  payload: PatchedMessage;
}

const MessageBox = ({ message, currentUser }: MessageBoxProps) => {
  const readMessageMutation = useMutation({
    mutationFn: ({ messageId, payload }: partialUpdateMessageProps) =>
      apiClient.requests.messagePartialUpdate(payload, messageId),
    retry: false,
  });

  const author = currentUser.id === message.author.id;
  const msgId = message.id;
  const unread = !message.read;

  useEffect(() => {
    if (!author && unread) {
      // set unread messages to "read"
      readMessageMutation.mutate({
        messageId: msgId,
        payload: { read: true },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [author, unread, msgId]);

  const MessageContent = () => {
    if (message.attachment) {
      return <AttachmentContent attachmentId={message.attachment} />;
    }

    return (
      <Text pt="sm" textfor="message">
        {message.content}
      </Text>
    );
  };

  return (
    <Paper withBorder radius="xs" p={10} key={msgId}>
      <Group>
        <Paper withBorder radius="xs" shadow="sm">
          <Gravatar emailHash={message.author.email_sha256} size={50} />
        </Paper>
        <div>
          <Text textfor="avatar">{message.author.name}</Text>
          <Text textfor="info">{moment(message.created).fromNow()}</Text>
        </div>
      </Group>
      <MessageContent />
    </Paper>
  );
};

export default MessageBox;
