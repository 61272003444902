import { Badge, Box, Group, rem, Text } from '@mantine/core';
import BreadcrumbNavigation from '@/components/Common/BreadcrumbNavigation/BreadcrumbNavigation';

import type { Contract } from '@/api-client';
import If from '@/components/Common/If/If';

const ContractHeader = ({ contract }: { contract: Contract }) => (
  <Box py={rem(8)}>
    <BreadcrumbNavigation
      breadcrumbs={[
        { label: 'Contracts', href: '/contracts/' },
        { label: contract.project.name, href: '' },
      ]}
    />

    <Group>
      <Text textfor="h1">{contract.project.name}</Text>

      <If condition={contract.active}>
        <Badge variant="light" color="green">
          Active
        </Badge>
      </If>
    </Group>
  </Box>
);
export default ContractHeader;
