import { json, LoaderFunction } from 'react-router-dom';
import { LoaderFunctionArgs } from '@remix-run/router/utils';
import { queryKeys } from '@/api-client';
import { apiClient, queryClient } from '@/globals';

const contractQuery = (id: string) => ({
  queryKey: queryKeys.contractRetrieve(id),
  queryFn: async () => apiClient.requests.contractRetrieve(id),
});

const conversationListQuery = (contractId: string) => ({
  queryKey: queryKeys.conversationList(contractId, 1),
  queryFn: async () => apiClient.requests.conversationList(contractId, 1),
});

const conversationQuery = (conversationId: string) => ({
  queryKey: queryKeys.conversationRetrieve(conversationId),
  queryFn: async () => apiClient.requests.conversationRetrieve(conversationId),
});

const userMeQuery = () => ({
  queryKey: queryKeys.userMeRetrieve(),
  queryFn: async () => apiClient.requests.userMeRetrieve(),
});

const messageListQuery = (conversationId: string) => ({
  queryKey: queryKeys.messageList(conversationId, 0),
  queryFn: async () => apiClient.requests.messageList(conversationId, 1),
});

export const ContractDetailLoader: LoaderFunction = async ({ params }: LoaderFunctionArgs) => {
  const { contractId } = params;
  if (contractId === undefined) {
    throw json({ message: 'contractId is required' }, { status: 401 });
  }

  const contract = await queryClient.fetchQuery({
    ...contractQuery(contractId),
    staleTime: 1000 * 60,
  });

  const conversationList = await queryClient.fetchQuery({
    ...conversationListQuery(contractId),
    staleTime: 1000 * 60,
  });

  const conversationId = conversationList.results?.[0].id;
  if (conversationId === undefined) {
    throw json({ message: 'failed to find conversationId' }, { status: 401 });
  }

  const conversation = await queryClient.fetchQuery({
    ...conversationQuery(conversationId),
    staleTime: 1000 * 60,
  });

  const userMe = await queryClient.fetchQuery({
    ...userMeQuery(),
    staleTime: 1000 * 60,
  });

  await queryClient.fetchQuery({
    ...messageListQuery(conversationId),
    staleTime: 1000 * 60,
  });

  return { contract, conversation, userMe };
};
