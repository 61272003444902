import { useState } from 'react';
import { AxiosError } from 'axios';

import { Paper, SimpleGrid, Skeleton, Stack } from '@mantine/core';
import { ContractCard } from '@/components/Contracts/ContractCard/ContractCard';

import { Contract, PaginatedContractList } from '@/api-client';
import { apiClient } from '@/globals';
import { hasResults } from '@/utils';

interface ContractCardProps {
  projectId?: string;
  contractList: PaginatedContractList;
}

const ContractList = ({ projectId, contractList }: ContractCardProps) => {
  const [activePage] = useState(1);

  const ret = apiClient.queries.useContractList(activePage, projectId, {
    initialData: contractList,
  });

  if (ret.isError) {
    const err = (ret.error as AxiosError).message;
    return <Paper>Error: {err}</Paper>;
  }

  if (!hasResults(ret.data?.results)) return null;

  return (
    <Stack>
      <Skeleton visible={ret.isLoading}>
        <SimpleGrid
          cols={{
            xs: 1,
            md: 2,
          }}
        >
          {ret.data?.results?.map((contract: Contract) => (
            <ContractCard key={contract.id} contract={contract} />
          ))}
        </SimpleGrid>
      </Skeleton>
    </Stack>
  );
};

export default ContractList;
