import { useState } from 'react';

import { IconLock, IconLockOpen } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

import { Button, Text, Alert, Group } from '@mantine/core';
import { apiClient, queryClient } from '@/globals';

import { Risk, queryKeys } from '@/api-client';

export const RiskUnlockRequestAlert = ({ risk }: { risk: Risk }) => {
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  const unlockRequestsQuery = apiClient.queries.useUnlockRequestList(false, undefined, risk.id);
  const unlockRequests = unlockRequestsQuery.data?.results;

  const handleReject = async () => {
    if (!unlockRequests) return;
    setIsRejecting(true);

    try {
      await Promise.all(unlockRequests.map((r) => apiClient.requests.unlockRequestDestroy(r.id)));
    } catch (error) {
      notifications.show({
        title: 'Error occured',
        message: "We couldn't reject unlock request, please try again!",
        color: 'red',
      });
    } finally {
      setIsRejecting(false);
    }
  };

  const handleAccept = async () => {
    if (!unlockRequests) return;
    setIsApproving(true);

    try {
      await Promise.all(
        unlockRequests.map((r) =>
          apiClient.requests.unlockRequestPartialUpdate({ approved: true }, r.id)
        )
      );

      await queryClient.invalidateQueries({
        queryKey: queryKeys.unlockRequestList(false, undefined, risk.id),
      });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.riskRetrieve(risk.id),
      });
    } catch (error) {
      notifications.show({
        title: 'Error occured',
        message: "We couldn't approve unlock request, please try again!",
        color: 'red',
      });
    } finally {
      setIsApproving(false);
    }
  };

  // Display only one unlock request since the user is the same
  const unlockRequest = unlockRequests?.[0];

  if (!unlockRequest) {
    return null;
  }

  return (
    <Alert key={unlockRequest.id} variant="light" closeButtonLabel="close">
      <Group justify="space-between">
        <Text textfor="h5">
          {unlockRequest.risk_details.researcher.name} requested to unlock this risk
        </Text>

        <Group gap="sm">
          <Button
            variant="outline"
            onClick={handleReject}
            loading={isRejecting}
            disabled={isApproving}
            leftSection={<IconLock stroke={1.5} size={16} />}
          >
            Reject
          </Button>
          <Button
            onClick={handleAccept}
            loading={isApproving}
            disabled={isRejecting}
            leftSection={<IconLockOpen stroke={1.5} size={16} />}
          >
            Unlock
          </Button>
        </Group>
      </Group>
    </Alert>
  );
};
