import { LoaderFunction } from 'react-router-dom';
import { queryKeys } from '@/api-client';
import { apiClient, queryClient } from '@/globals';

const projectsQuery = () => ({
  queryKey: queryKeys.projectList(),
  queryFn: async () => apiClient.requests.projectList(),
});

export const ProjectsLoader: LoaderFunction = async () => {
  const query = projectsQuery();
  const projectList = await queryClient.fetchQuery({
    ...query,
    staleTime: 1000 * 60,
  });

  return { projectList };
};
