import { useLoaderData } from 'react-router-dom';

import { Container, Space, Title } from '@mantine/core';
import ContractList from '@/components/Contracts/ContractList/ContractList';

import { useTrackPageView } from '@/hooks/use-track-page-view';
import type { PaginatedContractList } from '@/api-client';

interface LoaderProps {
  contractList: PaginatedContractList;
}

export function ContractsPage() {
  const { contractList } = useLoaderData() as LoaderProps;

  useTrackPageView('contracts');

  return (
    <Container>
      <Title order={3} textfor="h1" mt="md">
        Active Contracts
      </Title>
      <Space h="sm" />
      <ContractList contractList={contractList} />
    </Container>
  );
}
