import React from 'react';

import { IconBolt, IconCircleCheck, IconProgress } from '@tabler/icons-react';
import { Center, Divider, Group, rem, RingProgress, Stack, Text, ThemeIcon } from '@mantine/core';

import { useDebouncedValue } from '@mantine/hooks';
import moment from 'moment';
import clsx from 'clsx';
import { Project } from '@/api-client';
import { apiClient } from '@/globals';
import { getRiskCount } from '@/lib/getRiskCount';
import { useRiskFilters } from '@/store/risk-store';
import { debounceDuration } from '@/configuration';
import classes from './ProjectStats.module.css';

interface ProjectStatsProps {
  project: Project;
  nonFiltered?: boolean;
}

const Icon: React.FC<React.PropsWithChildren> = ({ children }) => (
  <ThemeIcon size="xs">{children}</ThemeIcon>
);

function ProjectStats({ project, nonFiltered = false }: ProjectStatsProps) {
  const { categories, severities, dateRange, tags, status, assignee } = useRiskFilters();

  const [debCategories] = useDebouncedValue(categories, debounceDuration);
  const [debSeverities] = useDebouncedValue(severities, debounceDuration);
  const [debDateRange] = useDebouncedValue(dateRange, debounceDuration);
  const [debTags] = useDebouncedValue(tags, debounceDuration);
  const date_range_from = debDateRange[0] ? moment(debDateRange[0]).format('L') : undefined;
  const date_range_to = debDateRange[1] ? moment(debDateRange[1]).format('L') : undefined;
  const [debAssignee] = useDebouncedValue(assignee, debounceDuration);

  const { data: risks } = apiClient.queries.useRiskList(
    debAssignee, // assignee
    debCategories, // category
    date_range_from, // created_after
    date_range_to, // created_before
    1, // page
    project.id, // project
    debSeverities, // severity
    status === 'all' ? undefined : status, // status
    debTags, // tag
    undefined, // title
    undefined, // updated_after
    undefined // updated_before
  );
  const risksDetails = getRiskCount(risks?.results ?? []);
  const totalRiskCount = risks?.count ?? 0;
  const ratio = totalRiskCount === 0 ? 0 : 100 / totalRiskCount;

  return (
    <Group py="xs">
      <Stack
        align="center"
        gap={rem(0)}
        // className={classes.section}
        // used the section class if nonfiltered is true
        className={clsx({
          [classes.section]: nonFiltered,
        })}
      >
        <RingProgress
          size={60}
          roundCaps
          thickness={8}
          sections={[
            {
              value: risksDetails.newRiskCount * ratio,
              color: 'var(--risk-bg-new)',
            },
          ]}
          label={<Center>{risksDetails.newRiskCount}</Center>}
        />
        <Group>
          <Icon>
            <IconBolt size="100%" />
          </Icon>
          <Text textfor="subheading">New</Text>
        </Group>
      </Stack>
      <Divider size="sm" orientation="vertical" />

      <Stack
        align="center"
        gap={rem(0)}
        className={clsx({
          [classes.section]: nonFiltered,
        })}
      >
        <RingProgress
          size={60}
          roundCaps
          thickness={8}
          sections={[
            {
              value: risksDetails.inProgressRiskCount * ratio,
              color: 'var(--risk-bg-in_progress)',
            },
          ]}
          label={<Center>{risksDetails.inProgressRiskCount}</Center>}
        />
        <Group>
          <Icon>
            <IconProgress size="100%" />
          </Icon>
          <Text textfor="subheading">In Progress</Text>
        </Group>
      </Stack>

      <Divider size="sm" orientation="vertical" />

      <Stack
        align="center"
        gap={rem(0)}
        className={clsx({
          [classes.section]: nonFiltered,
        })}
      >
        <RingProgress
          size={60}
          roundCaps
          thickness={8}
          sections={[
            {
              value: risksDetails.closedRiskCount * ratio,
              color: 'var(--risk-bg-closed)',
            },
          ]}
          label={<Center>{risksDetails.closedRiskCount}</Center>}
        />
        <Group>
          <Icon>
            <IconCircleCheck size="100%" />
          </Icon>
          <Text textfor="subheading">Closed</Text>
        </Group>
      </Stack>
    </Group>
  );
}

export default ProjectStats;
