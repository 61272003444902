import { Avatar, FloatingPosition, Tooltip } from '@mantine/core';
import classes from './gavatar.module.css';

interface GravatarProps {
  name?: string;
  tooltipPosition?: FloatingPosition;
  emailHash: string;
  size?: number;
  withoutTooltip?: boolean;
  style?: React.CSSProperties;
}

const BASE_GRAVATAR_URL = 'https://www.gravatar.com/avatar/';
const DEFAULT_GRAVATAR_SIZE = 50;

function getGravatarUrl(emailHash: string, size?: number) {
  return `${BASE_GRAVATAR_URL}${emailHash}?d=robohash&r=g&s=${size ?? DEFAULT_GRAVATAR_SIZE}`;
}

const Gravatar = ({
  tooltipPosition,
  emailHash,
  size,
  style,
  name,
  withoutTooltip,
}: GravatarProps) => {
  const gravatarUrl = getGravatarUrl(emailHash, size);

  return withoutTooltip ? (
    <Avatar
      src={gravatarUrl}
      style={style}
      size={size ?? DEFAULT_GRAVATAR_SIZE}
      radius={80}
      className={classes.gravatar}
    />
  ) : (
    <Tooltip label={name} withArrow position={tooltipPosition}>
      <Avatar
        alt={name}
        src={gravatarUrl}
        style={style}
        size={size ?? DEFAULT_GRAVATAR_SIZE}
        radius={80}
        className={classes.gravatar}
      />
    </Tooltip>
  );
};

export default Gravatar;
