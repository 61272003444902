import { useEffect, useMemo, useState } from 'react';
import { Button, CheckIcon, Combobox, Group, useCombobox } from '@mantine/core';
import { IconTag, IconX } from '@tabler/icons-react';
import { RiskTagBadge } from '../RiskTags/RiskTagBadge';
import classes from './RiskFilters.module.css';
import { FilterOptions } from '@/hooks/use-filters';
import { useRiskFilters } from '@/store/risk-store';
import { useCompany } from '@/hooks/use-company';
import { apiClient } from '@/globals';

export function TagsMultiSelect({
  onChange,
  selected,
}: {
  onChange: (selected: string[]) => void;
  selected: string[];
}) {
  const [selectedItems, setSelectedItems] = useState<string[]>(selected);

  useEffect(() => {
    onChange(selectedItems);
  }, [selectedItems, onChange]);

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
    },
  });

  const isSelected = selected.length;

  const company = useCompany();

  const { data } = apiClient.queries.useCompanyTagsList(company.id);

  const tags = useMemo(() => data?.results ?? [], [data?.results]);

  const { tags: selectedTags, setTags } = useRiskFilters();

  const tagsMeta = useMemo(
    () => ({
      label: 'Tags',
      id: 'tags',
      options: tags.map((tag) => ({
        value: tag.id,
        tag: tag.tag,
        label: tag.tag,
        id: tag.id,
        color: tag.color,
        type: 'tag',
        meta: tag,
      })),
      onChange: setTags,
      selected: selectedTags,
      icon: <IconTag stroke={1.5} size="1rem" />,
    }),
    [tags, selectedTags, setTags]
  );

  const options = tagsMeta.options.map((item: FilterOptions, i: number) => (
    <Combobox.Option value={item.value} key={i}>
      <Group justify="space-between">
        <RiskTagBadge key={item.id} tag={item.meta!} />
        {selected.includes(item.value as never) && <CheckIcon size={12} />}
      </Group>
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      width={150}
      position="bottom-start"
      withArrow
      withinPortal={false}
      positionDependencies={[selectedItems]}
      onOptionSubmit={(val) => {
        setSelectedItems((current) =>
          val === 'all'
            ? ['all']
            : current.includes(val)
              ? current.filter((item) => item !== val)
              : [...current.filter((value) => value !== 'all'), val]
        );
      }}
    >
      <Combobox.Target>
        <Button
          onClick={() => {
            combobox.toggleDropdown();
          }}
          variant={isSelected ? 'light' : 'subtle'}
          color={isSelected ? 'blue' : 'gray'}
          size="xs"
          mt={0}
          leftSection={<IconTag stroke={1.5} size="1rem" />}
          rightSection={
            isSelected ? (
              <IconX
                z={10}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedItems([]);
                }}
                className={classes.closeIcon}
                stroke={4}
                size={18}
              />
            ) : null
          }
        >
          Tags
        </Button>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
