import { AuthLayout } from '@/layouts/AuthLayout/AuthLayout';
import { RequestResetPasswordForm } from '@/components/Auth/RequestResetPasswordForm/RequestResetPasswordForm';

import { useTrackPageView } from '@/hooks/use-track-page-view';

export function RequestResetPasswordPage() {
  useTrackPageView('request_reset_password');

  return (
    <AuthLayout
      title="Reset Password"
      description="Enter your email address and we will email you a password reset link."
    >
      <RequestResetPasswordForm />
    </AuthLayout>
  );
}
