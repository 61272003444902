import { notifications } from '@mantine/notifications';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { RefObject } from 'react';
import { getTotalPages } from '@/utils';
import { apiClient, pagination, queryClient } from '@/globals';
import { Message } from '@/api-client';

export const UseInfiniteMessages = (conversationId: string) =>
  useInfiniteQuery({
    queryKey: ['messages'],
    queryFn: async ({ pageParam }: { pageParam: number }) =>
      apiClient.requests.messageList(conversationId, pageParam, undefined),
    initialPageParam: 1,
    getNextPageParam: (_lastPage, allPages, lastPageParam) => {
      const totalPages = getTotalPages(allPages[0].count, pagination.messagePageSize);
      const nextPage = lastPageParam + 1;
      return nextPage > totalPages ? undefined : nextPage;
    },
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => {
      if (firstPageParam <= 1) {
        return undefined;
      }
      return firstPageParam - 1;
    },
    select: (data) => ({
      pages: [...data.pages].reverse(),
      pageParams: [...data.pageParams].reverse(),
    }),
  });

export const UseSendMessageMutation = (viewport: RefObject<HTMLDivElement>) =>
  useMutation({
    mutationFn: ({ payload }: { payload: Message }) => apiClient.requests.messageCreate(payload),
    retry: false,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['messages'] });
      if (viewport.current) {
        viewport.current.scrollTo({ top: viewport.current.scrollHeight, behavior: 'auto' });
      }
    },
    // eslint-disable-next-line
    onError: (error: AxiosError, _, __) => {
      notifications.show({
        color: 'red',
        title: 'Failed to send message',
        message: error.message,
      });
    },
  });

export const UseSendMessage = (viewport: RefObject<HTMLDivElement>) => {
  const sendMessageMutation = UseSendMessageMutation(viewport);

  return (conversationId: string, content: string, attachmentId?: string) => {
    sendMessageMutation.mutate({
      payload: {
        conversation: conversationId,
        content,
        attachment: attachmentId ?? '',
        id: '',
        author: {
          id: '',
          name: undefined,
          user_type: undefined,
          email_sha256: '',
        },
        read: false,
        deleted: false,
        created: '',
        updated: '',
      },
    });
  };
};
