import { QueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import qs from 'qs';
import { initialize } from '@/api-client';

const csrf = {
  cookieName: 'csrftoken',
  headerName: 'X-CSRFTOKEN',
};

export const pagination = {
  messagePageSize: 20,
  defaultPageSize: 20,
};

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL as string,
  withCredentials: true,
  xsrfCookieName: csrf.cookieName,
  xsrfHeaderName: csrf.headerName,
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  },
});

// setup request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get(csrf.cookieName);
    if (token) {
      // eslint-disable-next-line
      config.headers[csrf.headerName] = token;
    }
    return config;
  },
  (error: Error) => Promise.reject(error)
);

// setup response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      // do not redirect if we are already in this location
      if (
        !window.location.pathname.includes('/auth') &&
        !window.location.pathname.includes('/invitation')
        ) {
        const intendedUrl = window.location.pathname;
        window.location.href = `/auth/sign-in?intended=${intendedUrl}`;
      }
    }
    return Promise.reject(error);
  }
);

export const apiClient = initialize(axiosInstance);

export const queryClient = new QueryClient();
