import { useEffect } from 'react';

import { initializeGA } from '@/services/GoogleAnalytics';
import { initPerformanceObserver } from '@/services/PerformanceObserver';

initPerformanceObserver();

const AnalyticsProvider = () => {
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initializeGA();
      window.GA_INITIALIZED = true;
    }
  }, []);

  return null;
};

export default AnalyticsProvider;
