import {
  Alert,
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Group,
  Paper,
  ScrollArea,
  Skeleton,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { ReactNode, useState } from 'react';
import { AxiosError } from 'axios';
import { IconInfoCircle, IconScoreboard, IconThumbDown, IconThumbUp } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import Gravatar from '@/components/Common/Gravatar/Gravatar';
import { currencyFormat, hasResults } from '@/utils';
import { PaginatedProposalList, PatchedProposal, Proposal, ProposalStatusEnum } from '@/api-client';
import { apiClient } from '@/globals';
import classes from '@/css/Card.module.css';

interface Props {
  projectId?: string;
  proposalList: PaginatedProposalList;
}

const TextBody = ({ content }: { content: ReactNode }) => (
  <Text component="span" fz="sm" lineClamp={3}>
    {content}
  </Text>
);

interface partialUpdateProposalProps {
  proposalId: string;
  payload: PatchedProposal;
}

const ProposalList = ({ projectId, proposalList }: Props) => {
  const navigate = useNavigate();

  const [activePage] = useState(1);

  const updateProposalMutation = useMutation({
    mutationFn: ({ proposalId, payload }: partialUpdateProposalProps) =>
      apiClient.requests.proposalPartialUpdate(payload, proposalId),
    retry: false,
  });

  const ret = apiClient.queries.useProposalList(activePage, projectId, {
    initialData: proposalList,
  });

  if (ret.isError) {
    const err = (ret.error as AxiosError).message;
    return <Paper>Error: {err}</Paper>;
  }

  const updateProposal = (proposalId: string, status: string) => {
    updateProposalMutation.mutate({
      proposalId,
      payload: {
        status: status as ProposalStatusEnum,
      },
    });
  };

  const noResults = () => (
    <Alert m={10} variant="light" color="blue" title="No results found" icon={<IconInfoCircle />}>
      Send an invitation to a researcher to start a proposal
    </Alert>
  );

  const results = () => (
    <>
      <ScrollArea>
        <Flex gap="md" justify="flex-start">
          {ret.isSuccess &&
            ret.data.results?.map((proposal: Proposal) => (
              <Box
                w={280}
                key={proposal.id}
                onClick={() => {
                  navigate(`/proposal/${proposal.id}`);
                }}
              >
                <Card h="100%" padding="lg" radius="md" withBorder>
                  <Group wrap="nowrap">
                    <Gravatar emailHash={proposal.researcher.email_sha256} size={50} />
                    <div>
                      <Text fz="lg" fw={500}>
                        {proposal.researcher.name}
                      </Text>
                      <Text fz="xs" fw={700} c="gray.7">
                        {currencyFormat(proposal.rate)}
                      </Text>
                    </div>
                  </Group>
                  <Space h="sm" />
                  <Group>
                    <Flex gap="xs" wrap="wrap">
                      <Badge color="blue" variant="light">
                        FIX
                      </Badge>
                      <Badge color="blue" variant="light">
                        ME
                      </Badge>
                      <Badge color="blue" variant="light">
                        I&apos;M
                      </Badge>
                      <Badge color="blue" variant="light">
                        STATIC
                      </Badge>
                    </Flex>
                  </Group>
                  <Text mt="md" className={classes.cardTitle} c="gray.7">
                    Proposal
                  </Text>
                  <TextBody content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ultrices dolor tristique odio dapibus, eu sagittis magna malesuada. Vestibulum magna nisi, mattis eu aliquet vitae, pellentesque at ex. Sed ullamcorper, justo eu faucibus molestie, nisi felis tincidunt odio, ut euismod lorem quam a sem. Etiam vel feugiat lorem. Morbi rhoncus, elit sit amet dictum scelerisque, dui ante pretium odio, at molestie urna nunc sed magna. Aenean hendrerit consequat ex et dictum. Cras a suscipit lorem. Vivamus tortor mi, pellentesque vel nibh et, rhoncus facilisis turpis. Nunc est purus, ultrices at dictum at, imperdiet a orci. Phasellus volutpat pharetra sollicitudin. Suspendisse potenti." />
                  <Group justify="center" p="md" gap="xs">
                    <Button
                      leftSection={<IconThumbUp style={{ width: '1rem', height: '1rem' }} />}
                      radius="md"
                      mt="sm"
                      size="xs"
                      onClick={() => {
                        updateProposal(proposal.id, 'accepted');
                      }}
                    >
                      Accept
                    </Button>
                    <Button
                      leftSection={<IconThumbDown style={{ width: '1rem', height: '1rem' }} />}
                      radius="md"
                      mt="sm"
                      size="xs"
                      variant="outline"
                      onClick={() => {
                        updateProposal(proposal.id, 'rejected');
                      }}
                    >
                      Reject
                    </Button>
                  </Group>
                </Card>
              </Box>
            ))}
        </Flex>
      </ScrollArea>
      <Space h="lg" />
    </>
  );

  return (
    <Stack>
      <Flex gap="xs" align="center">
        <IconScoreboard stroke={1.5} size="1rem" className={classes.cardIcon} />
        <Text>Proposals ({ret.data?.count})</Text>
      </Flex>
      <Skeleton visible={ret.isLoading}>
        {hasResults(ret.data?.results) ? results() : noResults()}
      </Skeleton>
    </Stack>
  );
};

export default ProposalList;
