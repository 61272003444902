import { Link } from 'react-router-dom';
import clsx from 'clsx';

import clientLogo from '@/logo/client/discloze_logo.svg';
import researcherLogo from '@/logo/researcher/discloze_logo.svg';
import adminLogo from '@/logo/admin/discloze_logo.svg';

import classes from './logo.module.css';
import { UserTypeEnum } from '@/api-client';

interface UserLogoProps {
  badgeColor: string;
  logo: string;
}

const userTypeMappings: Record<string, UserLogoProps> = {
  client: { badgeColor: '#1e64c7', logo: clientLogo },
  admin: { badgeColor: '#A121C4', logo: adminLogo },
  researcher: { badgeColor: '#C32044', logo: researcherLogo },
};

interface LogoProps {
  userType?: UserTypeEnum; // UserTypeEnum is missing 'admin'
  size?: 'small' | 'medium' | 'large';
}

const Logo = ({ userType = 'client', size = 'small' }: LogoProps) => {
  const logo = userTypeMappings[userType];

  return (
    <Link to="/">
      <img src={logo.logo} className={clsx(classes.logo, classes[size])} alt={logo.logo} />
    </Link>
  );
};

export default Logo;
