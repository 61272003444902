import { useNavigate } from 'react-router-dom';

import { IconDots, IconLock, IconPencil } from '@tabler/icons-react';
import {
  ActionIcon,
  Box,
  Card,
  Divider,
  Flex,
  Group,
  Menu,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { AxiosError } from 'axios';
import If from '@/components/Common/If/If';
import { RiskUnlockRequestAlert } from '@/components/Risks/RiskLock/RiskUnlockRequestAlert';
import { RiskPendingUnlockRequestAlert } from '@/components/Risks/RiskLock/RiskPendingUnlockRequestAlert';

import { useUserType } from '@/hooks/use-user-type';
import { paths } from '@/configuration';
import classes from './RiskStyle.module.css';
import { queryKeys, type Risk } from '@/api-client';
import { ConfirmModal } from '@/components/Modals/ConfirmModal/ConfirmModal';
import { apiClient, queryClient } from '@/globals';
import RiskStatusSelect from '../RiskStatusSelect/RiskStatusSelect';
import { SeverityStatus } from '@/components/Common';
import { RiskCategoryBadge } from '../RiskCategoryBadge/RiskCategoryBadge';
import { RiskEnvironmentsBadge } from '../RiskEnvironmentBadge/RiskEnvironmentBadge';
import { RiskTagsView } from '../RiskTags/RiskTagsView';
import { RiskTagsMenu } from '../RiskTags/RiskTagsMenu';
import { RiskStatusBadge } from '../RiskStatusBadge/RiskStatusBadge';

const RiskTitle = ({ title }: { title: string }) => (
  <Group gap="md">
    <Title order={1} textfor="h2" textWrap="wrap">
      {title}
    </Title>
  </Group>
);

export const RiskHeader = ({ risk, stickyNav }: { risk: Risk; stickyNav?: boolean }) => {
  const userType = useUserType();
  const navigate = useNavigate();
  const isResearcher = userType === 'researcher';
  const projectQuery = apiClient.queries.useProjectRetrieve(risk.project);

  const company = projectQuery.data?.company;

  const requestRiskUnlockMutation = apiClient.mutations.useUnlockRequestCreate({
    onSuccess: async () => {
      notifications.show({
        title: 'Unlock request sent!',
        message: 'Unlock Risk request has been submitted',
        color: 'green',
      });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.riskRetrieve(risk.id),
      });
    },
    onError: (error) => {
      notifications.show({
        title: 'Failed to send risk unlock request',
        message: (error as AxiosError).message,
        color: 'red',
      });
    },
  });

  const hanldeUnlockRiskRequest = () =>
    // @ts-expect-error should accept only risk ID
    requestRiskUnlockMutation.mutateAsync({
      risk: risk.id,
    });

  return (
    <Box>
      <Card
        component={Stack}
        gap="sm"
        bg="none"
        withBorder={false}
        px={{ xs: 0, md: 'sm' }}
        py="sm"
      >
        <Flex
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          align={{
            xs: 'end',
            sm: 'center',
          }}
          justify="space-between"
          wrap="nowrap"
        >
          <Stack gap="xs" mb="xs" w="100%">
            <Group gap={24}>
              {stickyNav && risk.status && <RiskStatusBadge status={risk.status} />}
              <RiskTitle title={risk.title} />
              {stickyNav && <SeverityStatus severity={risk.severity} />}
            </Group>
          </Stack>
          <If condition={isResearcher && risk.status && !stickyNav}>
            <Menu shadow="md" width={150}>
              <Menu.Target>
                <ActionIcon color="" variant="subtle">
                  <IconDots className={classes.icon} />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <If condition={!risk.locked}>
                  <Menu.Item
                    onClick={() => {
                      navigate(paths.editRisk(risk.id));
                    }}
                    leftSection={<IconPencil className={classes.icon} size={16} />}
                  >
                    <Text>Edit</Text>
                  </Menu.Item>
                </If>
                <If condition={risk.locked}>
                  <Menu.Item
                    leftSection={<IconLock className={classes.icon} size={16} />}
                    disabled={!!risk.pending_unlock}
                    onClick={() => {
                      document.getElementById('unlock')?.click();
                    }}
                  >
                    <Text>Edit</Text>
                  </Menu.Item>
                </If>
              </Menu.Dropdown>
            </Menu>
            <ConfirmModal
              title="Request to unlock risk"
              description={
                <Text>
                  This risk is locked by <b>{company?.name}</b>. Would you like to request edit
                  access from the team?
                </Text>
              }
              buttonText="Send request"
              onConfirm={hanldeUnlockRiskRequest}
            >
              <div id="unlock" />
            </ConfirmModal>
          </If>
        </Flex>
        <Stack gap="sm" className={classes.hideDesktop}>
          <Flex>
            <RiskStatusSelect status={risk.status!} riskId={risk.id} />
          </Flex>
          <Group>
            <Flex>
              <SeverityStatus severity={risk.severity} />
            </Flex>
            <RiskCategoryBadge category={risk.category} />
            <RiskEnvironmentsBadge environment={risk.environment ?? 'web'} />

            {!isResearcher && (
              <>
                <Divider orientation="vertical" />
                <RiskTagsView riskId={risk.id} />
                <RiskTagsMenu riskId={risk.id} />
              </>
            )}
          </Group>
        </Stack>

        <If condition={isResearcher && risk.pending_unlock}>
          <RiskPendingUnlockRequestAlert />
        </If>

        {!isResearcher && <RiskUnlockRequestAlert risk={risk} />}
      </Card>
    </Box>
  );
};
