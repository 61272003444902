import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { HeaderMenu } from '@/components/Header/HeaderMenu';
import { AuthenticatedProvider } from '@/Providers/AuthenticatedProvider';
import { AuthProvider } from '@/Providers/AuthProvider';

import { ErrorPage } from '@/pages/404/404.page';
import ContractDetailPath from '@/pages/ContractDetail/ContractDetail.path';
import ContractsPath from '@/pages/Contracts/Contracts.path';
import NewRiskPath from '@/pages/NewRisk/NewRisk.path';
import ProjectDetailPath from '@/pages/ProjectDetail/ProjectDetail.path';
import ProjectsPath from '@/pages/Projects/Projects.path';
import ProposalDetailPath from '@/pages/ProposalDetail/ProposalDetail.path';
import ProposalsPath from '@/pages/Proposals/Proposals.path';
import ResearcherDetailPath from '@/pages/ResearcherDetail/ResearcherDetail.path';
import ResearchersPath from '@/pages/Researchers/Researchers.path';
import ResetPasswordPage from '@/pages/ResetPassword/ResetPassword.page';
import RiskDetailPath from '@/pages/RiskDetail/RiskDetail.path';
import SettingsPath from '@/pages/Settings/Settings.path';
import EditRiskPath from '@/pages/EditRisk/EditRisk.path';

import { SignInPage } from '@/pages/SignIn.page';
import { RequestResetPasswordPage } from '@/pages/RequestResetPassword/RequestResetPassword.page';
import { InvitationPage } from '@/pages/invitations/invitation.page';
import { InvitationLoader } from '@/pages/invitations/invitation.loader';

import { paths } from './configuration';

export function AuthenticatedLayout() {
  return (
    <AuthenticatedProvider>
      <HeaderMenu />
      <Outlet />
    </AuthenticatedProvider>
  );
}

const router = createBrowserRouter([
  {
    path: '/auth',
    element: <AuthProvider />,
    children: [
      {
        path: paths.signIn,
        element: <SignInPage />,
      },
      {
        path: paths.requestResetPassword,
        element: <RequestResetPasswordPage />,
      },
    ],
  },

  {
    path: paths.resetPassword,
    element: <ResetPasswordPage />,
  },
  { path: paths.invitation, element: <InvitationPage />, loader: InvitationLoader },
  {
    path: '/',
    element: <AuthenticatedLayout />,
    errorElement: <ErrorPage />,
    children: [
      ContractDetailPath,
      ContractsPath,
      NewRiskPath,
      EditRiskPath,
      ProjectDetailPath,
      ProjectsPath,
      ProposalDetailPath,
      ProposalsPath,
      ResearchersPath,
      ResearcherDetailPath,
      RiskDetailPath,
      SettingsPath,
    ],
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
