import { useLoaderData, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { Container, SimpleGrid, Space, Title } from '@mantine/core';
import { ProjectCard } from '@/components/Projects/ProjectCard/ProjectCard';

import { useTrackPageView } from '@/hooks/use-track-page-view';
import type { PaginatedProjectList } from '@/api-client';

interface LoaderProps {
  projectList: PaginatedProjectList;
}

export function ProjectsPage() {
  const { projectList } = useLoaderData() as LoaderProps;
  const navigate = useNavigate();

  useTrackPageView('projects');

  useEffect(() => {
    // Check the number of projects
    if (projectList.results?.length === 1) {
      // If there is only one project, redirect to the project details page
      const projectId = projectList.results[0].id;
      navigate(`/project/${projectId}`);
    }
  }, [projectList, navigate]);

  return (
    <Container>
      <Title order={3} textfor="h1" mt="md">
        Projects
      </Title>
      <Space h="sm" />
      <SimpleGrid
        cols={{
          xs: 1,
          sm: 2,
          lg: 4,
        }}
        spacing="xl"
      >
        {projectList.results?.map((project) => <ProjectCard key={project.id} project={project} />)}
      </SimpleGrid>
    </Container>
  );
}
