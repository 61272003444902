import { Link, useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';

import { IconBellFilled, IconLogout, IconSettings, IconUsersGroup } from '@tabler/icons-react';

import { ActionIcon, Avatar, Burger, Container, Group, Menu } from '@mantine/core';
import Logo from '@/components/Common/Logo/Logo';
import { ThemeSwitcher } from '@/components/Common/ThemeSwitcher/ThemeSwitcher';

import { useUserMe } from '@/hooks/use-user-me';
import { useSignOut } from '@/hooks/use-sign-out';
import { useUserType } from '@/hooks/use-user-type';
import { apiClient } from '@/globals';
import { paths } from '@/configuration';

import headerStyles from '@/css/HeaderMenu.module.css';

// interface LinkProps {
//   link: string;
//   label?: string;
//   element?: ReactNode;
//   links?: LinkProps[];
// }

export function HeaderMenu() {
  const navigate = useNavigate();
  const [opened, { toggle }] = useDisclosure(false);

  const userMe = useUserMe();
  const userEmailSha256 = userMe.email_sha256 || 'ABS123';
  const userName = userMe.name ?? 'Username';

  const userType = useUserType();

  const companiesQuery = apiClient.queries.useCompanyList();
  const companies = companiesQuery.data?.results;

  const signOut = useSignOut({
    onSuccess: () => {
      navigate('/');
    },
  });

  const renderUserMenu = () => (
    <Menu withinPortal>
      <Menu.Target>
        <Avatar
          src={`https://www.gravatar.com/avatar/${userEmailSha256}?d=robohash&r=g&s=512`}
          size={24}
        />
      </Menu.Target>
      <Menu.Dropdown>
        {companiesQuery.isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <Menu.Label>{userName}</Menu.Label>
            <Link to="/settings/my-settings" className={headerStyles.menuLink}>
              <Menu.Item
                key="my-settings"
                leftSection={
                  <Avatar
                    src={`https://www.gravatar.com/avatar/${userEmailSha256}?d=robohash&r=g&s=512`}
                    size={20}
                  />
                }
              >
                My Settings
              </Menu.Item>
            </Link>
            <Menu.Divider />
            {companies?.map((company) => (
              <div key={company.id}>
                <Link to={paths.settings.mySettings} className={headerStyles.menuLink}>
                  <Menu.Label>{company.name}</Menu.Label>
                </Link>
                <Link
                  to={paths.settings.companySettings(company.id)}
                  className={headerStyles.menuLink}
                >
                  <Menu.Item leftSection={<IconSettings size="20" stroke="1.5" />}>
                    Settings
                  </Menu.Item>
                </Link>
                <Link to={paths.settings.manageUsers(company.id)} className={headerStyles.menuLink}>
                  <Menu.Item leftSection={<IconUsersGroup size="20" stroke="1.5" />}>
                    Users
                  </Menu.Item>
                </Link>
                <Menu.Divider />
              </div>
            ))}
            <Link to="#" key="sign-out" className={headerStyles.menuLink} onClick={signOut}>
              <Menu.Item key="log-out" leftSection={<IconLogout size="20" stroke="1.5" />}>
                Log Out
              </Menu.Item>
            </Link>
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );

  return (
    <header className={headerStyles.headerMain}>
      <Container className={headerStyles.headerInner}>
        <Group>
          <Logo userType={userType} />
        </Group>
        <Group gap="xs" visibleFrom="sm">
          {/*{items}*/}
          <ThemeSwitcher />
          <ActionIcon variant="subtle" size="sm" color="dimmed">
            <IconBellFilled />
          </ActionIcon>
          {renderUserMenu()}
        </Group>

        <Burger opened={opened} onClick={toggle} size="sm" hiddenFrom="sm" />
      </Container>
    </header>
  );
}
