import { useRef, useState } from 'react';
import clsx from 'clsx';
import { Combobox, Divider, Group, InputBase, ThemeIcon, useCombobox } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import {
  IconBolt,
  IconCircleCheck,
  IconProgress,
  IconCircleXFilled,
  IconArrowNarrowRight,
  IconChevronDown,
} from '@tabler/icons-react';
import { RiskStatusBadge } from '@/components/Risks/RiskStatusBadge/RiskStatusBadge';

import { PatchedRisk, RiskStatusEnum } from '@/api-client';
import { apiClient } from '@/globals';

import classes from './RiskStatusSelect.module.css';
import { useUserType } from '@/hooks/use-user-type';
import { confettiStore } from '@/store/confetti-store';

interface StatusOptionProps {
  label: string;
  description: string;
  value: RiskStatusEnum;
  className: string;
  icon: JSX.Element;
}
interface partialUpdateRiskProps {
  riskId: string;
  payload: PatchedRisk;
}
const statusOptions: Record<RiskStatusEnum, StatusOptionProps> = {
  new: {
    label: 'New',
    description: 'Mark as New',
    value: 'new',
    className: 'new',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconBolt size={16} />
      </ThemeIcon>
    ),
  },
  in_progress: {
    label: 'In Progress',
    description: 'Move to In Progress',
    value: 'in_progress',
    className: 'in_progress',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconProgress size={16} />
      </ThemeIcon>
    ),
  },
  closed: {
    label: 'Closed',
    description: 'Mark as Fixed',
    value: 'closed',
    className: 'closed',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconCircleCheck size={16} />
      </ThemeIcon>
    ),
  },
  rejected: {
    label: 'Rejected',
    description: 'Mark as Rejected',
    value: 'rejected',
    className: 'rejected',
    icon: (
      <ThemeIcon className={classes.statusIcon}>
        <IconCircleXFilled size={16} />
      </ThemeIcon>
    ),
  },
} as const;

const StatusOption = ({ status, description }: { status: RiskStatusEnum; description: string }) => (
  <Group justify="space-between">
    {description}
    <ThemeIcon>
      <IconArrowNarrowRight />
    </ThemeIcon>
    <RiskStatusBadge size="md" status={status} />
  </Group>
);

const RiskStatusSelect = ({
  riskId,
  status,
}: {
  riskId: partialUpdateRiskProps['riskId'];
  status: RiskStatusEnum;
}) => {
  // Researcher cant change status, Can be changed to a prop depends on future updates
  const readOnly = useUserType() === 'researcher';

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
    },
  });

  const dropdownTriggerRef = useRef<HTMLButtonElement>(null);

  const [statusOption, setStatusOption] = useState<StatusOptionProps>(statusOptions[status]);
  const statuses = Object.values(statusOptions).slice(0, 3);
  const { rejected } = statusOptions;
  const { toggleShoot } = confettiStore();

  const updateRiskMutation = useMutation({
    mutationFn: ({ riskId: id, payload }: partialUpdateRiskProps) =>
      apiClient.requests.riskPartialUpdate(payload, id),
    onSuccess: (data) => {
      if (data.status === statusOptions.closed.value) {
        // const position = getElementCenterPosPercent(dropdownTriggerRef.current);
        // showConfetti({ angle: 115, position });
        toggleShoot(true);
      }
    },
    retry: false,
  });

  const updateRisk = (id: string, newStatus: RiskStatusEnum) => {
    updateRiskMutation.mutate({
      riskId: id,
      payload: {
        status: newStatus,
      },
    });
  };

  const handleOptionSubmit = (val: string) => {
    const selectStatus = val as RiskStatusEnum;
    if (selectStatus === statusOption.value) {
      combobox.closeDropdown();
      return;
    }

    setStatusOption(statusOptions[selectStatus]);
    updateRisk(riskId, selectStatus);
    combobox.closeDropdown();
  };

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleOptionSubmit}
      classNames={{
        dropdown: classes.Dropdown,
      }}
      disabled={readOnly}
    >
      <Combobox.Target>
        <InputBase
          ref={dropdownTriggerRef}
          component="button"
          type="button"
          onClick={() => {
            combobox.toggleDropdown();
          }}
          pointer
          rightSection={!readOnly && <IconChevronDown stroke={1.5} size="16px" />}
          rightSectionPointerEvents="none"
          className={clsx(classes[statusOption.className])}
          classNames={{
            input: classes.input,
            section: classes.icon,
          }}
          leftSection={statusOption.icon}
        >
          {statusOption.label}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {statuses.map(({ value, description, className }) => (
            <Combobox.Option key={value} value={value} className={classes[className]}>
              <StatusOption status={value} description={description} />
            </Combobox.Option>
          ))}

          <Divider my="xs" />

          <Combobox.Option value="rejected" className={classes[rejected.className]}>
            <StatusOption status={rejected.value} description={rejected.description} />
          </Combobox.Option>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default RiskStatusSelect;
