import type { AxiosInstance, AxiosRequestConfig } from "axios";
import { useQuery, useMutation, useQueryClient, type QueryClient, type UseMutationOptions, type UseQueryOptions, type MutationFunction, type UseMutationResult, type UseQueryResult } from "@tanstack/react-query";
export type Asset = {
    id: string;
    name: string;
    type?: TypeEnum | BlankEnum | NullEnum;
    created: string;
    updated: string;
};
export type Attachment = {
    id: string;
    url: string;
    key: string;
    filename?: string | null;
    content_type?: string | null;
    size?: number | null;
    deleted: boolean;
    created: string;
    updated: string;
    author: string;
};
export type Auth = {
    email: string;
    password: string;
    token: string;
};
export type BlankEnum = any;
export type CategoryEnum = "broken_access_control" | "cryptographic_failure" | "injection" | "insecure_design" | "security_misconfiguration" | "vulnerable_and_outdated_components" | "identification_and_auth_failure" | "data_integrity_failure" | "secure_logging_failure" | "server_side_request_forgery";
export type Changes = {
    field: string;
    old_value: string;
    new_value: string;
};
export type Comment = {
    id: string;
    author: UserSummary;
    content: string;
    deleted: boolean;
    created: string;
    updated: string;
    risk: string;
    attachments?: string[];
};
export type Company = {
    id: string;
    name: string;
    created: string;
    updated: string;
    users: User;
    triagers: User;
    projects: Project;
};
export type CompanySummary = {
    id: string;
    name: string;
    created: string;
    updated: string;
};
export type CompanyUser = {
    user: string;
};
export type Contract = {
    id: string;
    project: ProjectSummary;
    researcher: Researcher;
    conversation: Conversation;
    active?: boolean;
    payment_option?: PaymentOptionEnum;
    rate?: string;
    created: string;
    updated: string;
};
export type Conversation = {
    id: string;
    unread_messages: number;
    conversation_type?: ConversationTypeEnum;
    created: string;
    updated: string;
    contract?: string | null;
    risk?: string | null;
    users: string[];
};
export type ConversationTypeEnum = "dm" | "contract" | "risk";
export type Email = {
    email: string;
};
export type EmailTemplate = {
    id: string;
    name: string;
    subject?: string;
    message: string;
    created: string;
    updated: string;
};
export type EnvironmentEnum = "web" | "mobile" | "api" | "network";
export type GetPreSignedUrl = {
    key: string;
    content_type: string;
};
export type Invitation = {
    id: string;
    email: string;
    code: string;
    created: string;
    updated: string;
    company: string;
};
export type InvitationAcceptExistingUser = {
    code: string;
};
export type InvitationAcceptNewUser = {
    code: string;
    email: string;
    password: string;
    password_confirmation: string;
    name: string;
};
export type InvitationValidate = {
    code: string;
};
export type InvitationValidateResponse = {
    id: string;
    company: Company;
    existing_user: boolean;
    email: string;
    code: string;
    created: string;
    updated: string;
};
export type KeyValuePair = {
    key: string;
    value: number;
};
export type LinkedRisk = {
    id: string;
    title: string;
};
export type Message = {
    id: string;
    author: UserSummary;
    content: string;
    read?: boolean;
    deleted: boolean;
    created: string;
    updated: string;
    conversation: string;
    attachment?: string | null;
};
export type NameEnum = "mobile" | "web" | "api" | "iot" | "network" | "other";
export type NullEnum = any;
export type PaginatedAttachmentList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Attachment[];
};
export type PaginatedCommentList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Comment[];
};
export type PaginatedCompanyList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Company[];
};
export type PaginatedContractList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Contract[];
};
export type PaginatedConversationList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Conversation[];
};
export type PaginatedEmailTemplateList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: EmailTemplate[];
};
export type PaginatedInvitationList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Invitation[];
};
export type PaginatedMessageList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Message[];
};
export type PaginatedProjectList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Project[];
};
export type PaginatedProposalList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Proposal[];
};
export type PaginatedRiskHistoryList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: RiskHistory[];
};
export type PaginatedRiskList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Risk[];
};
export type PaginatedRiskTagList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: RiskTag[];
};
export type PaginatedScanReadList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: ScanRead[];
};
export type PaginatedSkillList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Skill[];
};
export type PaginatedTagList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Tag[];
};
export type PaginatedUnlockRequestList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: UnlockRequest[];
};
export type PaginatedUserList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: User[];
};
export type PasswordToken = {
    password: string;
    token: string;
};
export type PatchedAttachment = {
    id?: string;
    url?: string;
    key?: string;
    filename?: string | null;
    content_type?: string | null;
    size?: number | null;
    deleted?: boolean;
    created?: string;
    updated?: string;
    author?: string;
};
export type PatchedComment = {
    id?: string;
    author?: UserSummary;
    content?: string;
    deleted?: boolean;
    created?: string;
    updated?: string;
    risk?: string;
    attachments?: string[];
};
export type PatchedCompany = {
    id?: string;
    name?: string;
    created?: string;
    updated?: string;
    users?: User;
    triagers?: User;
    projects?: Project;
};
export type PatchedContract = {
    id?: string;
    project?: ProjectSummary;
    researcher?: Researcher;
    conversation?: Conversation;
    active?: boolean;
    payment_option?: PaymentOptionEnum;
    rate?: string;
    created?: string;
    updated?: string;
};
export type PatchedConversation = {
    id?: string;
    unread_messages?: number;
    conversation_type?: ConversationTypeEnum;
    created?: string;
    updated?: string;
    contract?: string | null;
    risk?: string | null;
    users?: string[];
};
export type PatchedEmailTemplate = {
    id?: string;
    name?: string;
    subject?: string;
    message?: string;
    created?: string;
    updated?: string;
};
export type PatchedMessage = {
    id?: string;
    author?: UserSummary;
    content?: string;
    read?: boolean;
    deleted?: boolean;
    created?: string;
    updated?: string;
    conversation?: string;
    attachment?: string | null;
};
export type PatchedProject = {
    id?: string;
    assets?: Asset[];
    company?: CompanySummary;
    skills?: SkillSummary[];
    stats?: ProjectStats;
    name?: string;
    description?: string;
    status?: Status045Enum;
    payment_option?: PaymentOptionEnum;
    logo_url?: string | null;
    budget?: string;
    created?: string;
    updated?: string;
};
export type PatchedProposal = {
    id?: string;
    project?: ProjectSummary;
    researcher?: UserSummary;
    conversation?: Conversation;
    active?: boolean;
    payment_option?: PaymentOptionEnum;
    status?: ProposalStatusEnum;
    rate?: string;
    created?: string;
    updated?: string;
};
export type PatchedRisk = {
    id?: string;
    researcher?: Researcher;
    locked?: boolean;
    comment_count?: number;
    pending_unlock?: number;
    sla?: number;
    linked_risks?: string[];
    title?: string;
    description?: string;
    severity?: SeverityEnum;
    category?: CategoryEnum;
    status?: RiskStatusEnum;
    environment?: EnvironmentEnum;
    index?: number;
    triage_ready?: boolean;
    created?: string;
    updated?: string;
    closed?: string | null;
    project?: string;
    contract?: string;
    assignee?: string | null;
    attachments?: string[];
};
export type PatchedRiskTag = {
    id?: string;
    tag_details?: Tag;
    created?: string;
    updated?: string;
    risk?: string;
    tag?: string;
};
export type PatchedScan = {
    id?: string;
    name?: string;
    type?: TypeEnum | BlankEnum | NullEnum;
    project_id?: string;
    script?: string;
    risk_id?: string;
};
export type PatchedSkill = {
    id?: string;
    name?: NameEnum | BlankEnum | NullEnum;
    created?: string;
    updated?: string;
};
export type PatchedTag = {
    id?: string;
    company?: Company;
    tag?: string;
    color?: string;
    created?: string;
    updated?: string;
};
export type PatchedUnlockRequest = {
    id?: string;
    risk?: string;
    approved?: boolean;
    risk_details?: Risk;
};
export type PatchedUser = {
    id?: string;
    name?: string;
    email?: string;
    user_type?: UserTypeEnum | NullEnum;
    email_sha256?: string;
    is_active?: boolean;
};
export type PaymentOptionEnum = "hourly" | "fixed";
export type PreSignedFields = {
    key: string;
    AWSAccessKeyId: string;
    policy: string;
    signature: string;
};
export type PreSignedResponse = {
    url: string;
    fields: PreSignedFields;
};
export type Project = {
    id: string;
    name: string;
    description: string;
    status: Status045Enum;
    skills: string[];
    payment_option: PaymentOptionEnum;
    logo_url: string | null;
    budget: string;
    created: string;
    updated: string;
};
export type ProjectDetails = {
    risks: number;
    contracts: number;
    severities: KeyValuePair[];
    categories: KeyValuePair[];
    statuses: KeyValuePair[];
    environments: KeyValuePair[];
    team: UserSummary[];
    researchers: UserSummary[];
    assets: Asset[];
};
export type ProjectStats = {
    proposals: number;
    contracts: number;
    unread_messages: number;
};
export type ProjectSummary = {
    id: string;
    name: string;
    company: CompanySummary;
    description: string;
    status: Status045Enum;
    skills: SkillSummary[];
    created: string;
    updated: string;
    assets: string[];
    stats: ProjectStats;
    logo_url?: string | null;
};
export type Proposal = {
    id: string;
    project: ProjectSummary;
    researcher: UserSummary;
    conversation: Conversation;
    active?: boolean;
    payment_option?: PaymentOptionEnum;
    status?: ProposalStatusEnum;
    rate?: string;
    created: string;
    updated: string;
};
export type ProposalStatusEnum = "accepted" | "rejected" | "pending";
export type Researcher = {
    id: string;
    name?: string;
    email_sha256: string;
};
export type ResetToken = {
    token: string;
};
export type Risk = {
    id: string;
    researcher: Researcher;
    locked: boolean;
    comment_count: number;
    pending_unlock: number;
    sla: number;
    linked_risks?: string[];
    title: string;
    description: string;
    severity: SeverityEnum;
    category: CategoryEnum;
    status?: RiskStatusEnum;
    environment?: EnvironmentEnum;
    index?: number;
    triage_ready?: boolean;
    created: string;
    updated: string;
    closed?: string | null;
    project: string;
    contract: string;
    assignee?: string | null;
    attachments?: string[];
};
export type RiskHistory = {
    id: number;
    user: UserSummary;
    changes: Changes[];
    timestamp: string;
};
export type RiskLink = {
    linkedRiskId: string;
};
export type RiskStatusEnum = "new" | "in_progress" | "closed" | "rejected";
export type RiskTag = {
    id: string;
    tag_details: Tag;
    created: string;
    updated: string;
    risk: string;
    tag: string;
};
export type RiskUnlink = {
    linkedRiskId: string;
};
export type Scan = {
    id: string;
    name: string;
    type?: TypeEnum | BlankEnum | NullEnum;
    project_id: string;
    script?: string;
    risk_id?: string;
};
export type ScanRead = {
    id: string;
    name: string;
    type?: TypeEnum | BlankEnum | NullEnum;
    project_id: string;
    created: string;
    updated: string;
};
export type SeverityEnum = "informational" | "low" | "medium" | "high" | "critical";
export type Skill = {
    id: string;
    name?: NameEnum | BlankEnum | NullEnum;
    created: string;
    updated: string;
};
export type SkillSummary = {
    id: string;
    name: NameEnum | NullEnum;
};
export type Status045Enum = "draft" | "active" | "completed" | "closed";
export type Tag = {
    id: string;
    company: Company;
    tag: string;
    color?: string;
    created: string;
    updated: string;
};
export type TagDetail = {
    id: string;
    tag: string;
    color?: string;
    created: string;
    updated: string;
    company: string;
};
export type TypeEnum = "domain" | "subdomain";
export type UnlockRequest = {
    id: string;
    risk: string;
    approved?: boolean;
    risk_details: Risk;
};
export type User = {
    id: string;
    name?: string;
    email: string;
    user_type: UserTypeEnum | NullEnum;
    email_sha256: string;
    is_active: boolean;
};
export type UserSummary = {
    id: string;
    name?: string;
    is_active: boolean;
    email_sha256: string;
};
export type UserTypeEnum = "client" | "researcher" | "partner" | "triager";
export type AxiosConfig = {
    paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
};
export type Config = {
    mutations?: MutationConfigs;
    axios?: AxiosConfig;
};
export function initialize(axios: AxiosInstance, config?: Config) {
    const requests = makeRequests(axios, config?.axios);
    return {
        requests,
        queries: makeQueries(requests),
        mutations: makeMutations(requests, config?.mutations)
    };
}
function useRapiniMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(mutationFn: MutationFunction<TData, TVariables>, config?: (queryClient: QueryClient) => Pick<UseMutationOptions<TData, TError, TVariables, TContext>, "onSuccess" | "onSettled" | "onError">, options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, "mutationFn">): UseMutationResult<TData, TError, TVariables, TContext> {
    const { onSuccess, onError, onSettled, ...rest } = options ?? {};
    const queryClient = useQueryClient();
    const conf = config?.(queryClient);
    const mutationOptions: typeof options = {
        onSuccess: (data: TData, variables: TVariables, context?: TContext) => {
            conf?.onSuccess?.(data, variables, context);
            onSuccess?.(data, variables, context);
        },
        onError: (error: TError, variables: TVariables, context?: TContext) => {
            conf?.onError?.(error, variables, context);
            onError?.(error, variables, context);
        },
        onSettled: (data: TData | undefined, error: TError | null, variables: TVariables, context?: TContext) => {
            conf?.onSettled?.(data, error, variables, context);
            onSettled?.(data, error, variables, context);
        },
        ...rest
    };
    return useMutation({ mutationFn, ...mutationOptions });
}
function nullIfUndefined<T>(value: T): NonNullable<T> | null {
    return typeof value === "undefined" ? null : value as NonNullable<T> | null;
}
export const queryKeys = {
    apiSchemaRetrieve: (format?: "json" | "yaml", lang?: "af" | "ar" | "ar-dz" | "ast" | "az" | "be" | "bg" | "bn" | "br" | "bs" | "ca" | "ckb" | "cs" | "cy" | "da" | "de" | "dsb" | "el" | "en" | "en-au" | "en-gb" | "eo" | "es" | "es-ar" | "es-co" | "es-mx" | "es-ni" | "es-ve" | "et" | "eu" | "fa" | "fi" | "fr" | "fy" | "ga" | "gd" | "gl" | "he" | "hi" | "hr" | "hsb" | "hu" | "hy" | "ia" | "id" | "ig" | "io" | "is" | "it" | "ja" | "ka" | "kab" | "kk" | "km" | "kn" | "ko" | "ky" | "lb" | "lt" | "lv" | "mk" | "ml" | "mn" | "mr" | "ms" | "my" | "nb" | "ne" | "nl" | "nn" | "os" | "pa" | "pl" | "pt" | "pt-br" | "ro" | "ru" | "sk" | "sl" | "sq" | "sr" | "sr-latn" | "sv" | "sw" | "ta" | "te" | "tg" | "th" | "tk" | "tr" | "tt" | "udm" | "ug" | "uk" | "ur" | "uz" | "vi" | "zh-hans" | "zh-hant") => ["apiSchemaRetrieve", nullIfUndefined(format), nullIfUndefined(lang)] as const,
    attachmentList: (page?: number) => ["attachmentList", nullIfUndefined(page)] as const,
    attachmentRetrieve: (id: string) => ["attachmentRetrieve", id] as const,
    clientList: (page?: number) => ["clientList", nullIfUndefined(page)] as const,
    clientRetrieve: (id: string) => ["clientRetrieve", id] as const,
    commentList: (ordering?: string, page?: number, risk?: string) => ["commentList", nullIfUndefined(ordering), nullIfUndefined(page), nullIfUndefined(risk)] as const,
    commentRetrieve: (id: string, ordering?: string, risk?: string) => ["commentRetrieve", id, nullIfUndefined(ordering), nullIfUndefined(risk)] as const,
    companyList: (page?: number) => ["companyList", nullIfUndefined(page)] as const,
    companyRetrieve: (id: string) => ["companyRetrieve", id] as const,
    companyPendingInvitationsList: (id: string, page?: number) => ["companyPendingInvitationsList", id, nullIfUndefined(page)] as const,
    companyTagsList: (id: string, page?: number) => ["companyTagsList", id, nullIfUndefined(page)] as const,
    contractList: (page?: number, project?: string) => ["contractList", nullIfUndefined(page), nullIfUndefined(project)] as const,
    contractRetrieve: (id: string, project?: string) => ["contractRetrieve", id, nullIfUndefined(project)] as const,
    conversationList: (contract?: string, page?: number) => ["conversationList", nullIfUndefined(contract), nullIfUndefined(page)] as const,
    conversationRetrieve: (id: string, contract?: string) => ["conversationRetrieve", id, nullIfUndefined(contract)] as const,
    emailTemplateList: (page?: number) => ["emailTemplateList", nullIfUndefined(page)] as const,
    emailTemplateRetrieve: (id: string) => ["emailTemplateRetrieve", id] as const,
    invitationCancelRetrieve: (id: string) => ["invitationCancelRetrieve", id] as const,
    invitationResendRetrieve: (id: string) => ["invitationResendRetrieve", id] as const,
    messageList: (conversation?: string, page?: number, read?: boolean) => ["messageList", nullIfUndefined(conversation), nullIfUndefined(page), nullIfUndefined(read)] as const,
    messageRetrieve: (id: string) => ["messageRetrieve", id] as const,
    projectList: (page?: number) => ["projectList", nullIfUndefined(page)] as const,
    projectRetrieve: (id: string) => ["projectRetrieve", id] as const,
    projectDetailsRetrieve: (id: string) => ["projectDetailsRetrieve", id] as const,
    proposalList: (page?: number, project?: string) => ["proposalList", nullIfUndefined(page), nullIfUndefined(project)] as const,
    proposalRetrieve: (id: string, project?: string) => ["proposalRetrieve", id, nullIfUndefined(project)] as const,
    researcherList: (page?: number) => ["researcherList", nullIfUndefined(page)] as const,
    researcherRetrieve: (id: string) => ["researcherRetrieve", id] as const,
    riskList: (assignee?: string[], category?: ("broken_access_control" | "cryptographic_failure" | "data_integrity_failure" | "identification_and_auth_failure" | "injection" | "insecure_design" | "secure_logging_failure" | "security_misconfiguration" | "server_side_request_forgery" | "vulnerable_and_outdated_components")[], created_after?: string, created_before?: string, page?: number, project?: string, severity?: ("critical" | "high" | "informational" | "low" | "medium")[], status?: "closed" | "in_progress" | "new" | "rejected", tag?: string[], title?: string, updated_after?: string, updated_before?: string) => ["riskList", nullIfUndefined(assignee), nullIfUndefined(category), nullIfUndefined(created_after), nullIfUndefined(created_before), nullIfUndefined(page), nullIfUndefined(project), nullIfUndefined(severity), nullIfUndefined(status), nullIfUndefined(tag), nullIfUndefined(title), nullIfUndefined(updated_after), nullIfUndefined(updated_before)] as const,
    riskRetrieve: (id: string, project?: string, title?: string) => ["riskRetrieve", id, nullIfUndefined(project), nullIfUndefined(title)] as const,
    riskAskToUnlockRetrieve: (id: string, project?: string, title?: string) => ["riskAskToUnlockRetrieve", id, nullIfUndefined(project), nullIfUndefined(title)] as const,
    riskCommentsList: (id: string, assignee?: string[], category?: ("broken_access_control" | "cryptographic_failure" | "data_integrity_failure" | "identification_and_auth_failure" | "injection" | "insecure_design" | "secure_logging_failure" | "security_misconfiguration" | "server_side_request_forgery" | "vulnerable_and_outdated_components")[], created_after?: string, created_before?: string, page?: number, project?: string, severity?: ("critical" | "high" | "informational" | "low" | "medium")[], status?: "closed" | "in_progress" | "new" | "rejected", tag?: string[], title?: string, updated_after?: string, updated_before?: string) => ["riskCommentsList", id, nullIfUndefined(assignee), nullIfUndefined(category), nullIfUndefined(created_after), nullIfUndefined(created_before), nullIfUndefined(page), nullIfUndefined(project), nullIfUndefined(severity), nullIfUndefined(status), nullIfUndefined(tag), nullIfUndefined(title), nullIfUndefined(updated_after), nullIfUndefined(updated_before)] as const,
    riskHistoryList: (id: string, assignee?: string[], category?: ("broken_access_control" | "cryptographic_failure" | "data_integrity_failure" | "identification_and_auth_failure" | "injection" | "insecure_design" | "secure_logging_failure" | "security_misconfiguration" | "server_side_request_forgery" | "vulnerable_and_outdated_components")[], created_after?: string, created_before?: string, page?: number, project?: string, severity?: ("critical" | "high" | "informational" | "low" | "medium")[], status?: "closed" | "in_progress" | "new" | "rejected", tag?: string[], title?: string, updated_after?: string, updated_before?: string) => ["riskHistoryList", id, nullIfUndefined(assignee), nullIfUndefined(category), nullIfUndefined(created_after), nullIfUndefined(created_before), nullIfUndefined(page), nullIfUndefined(project), nullIfUndefined(severity), nullIfUndefined(status), nullIfUndefined(tag), nullIfUndefined(title), nullIfUndefined(updated_after), nullIfUndefined(updated_before)] as const,
    riskLinkedRisksList: (id: string) => ["riskLinkedRisksList", id] as const,
    riskSearchRetrieve: (project?: string, title?: string) => ["riskSearchRetrieve", nullIfUndefined(project), nullIfUndefined(title)] as const,
    riskTagList: (page?: number, risk?: string, tag?: string, tag_name?: string) => ["riskTagList", nullIfUndefined(page), nullIfUndefined(risk), nullIfUndefined(tag), nullIfUndefined(tag_name)] as const,
    riskTagRetrieve: (id: string) => ["riskTagRetrieve", id] as const,
    scanList: (page?: number) => ["scanList", nullIfUndefined(page)] as const,
    scanRetrieve: (id: string) => ["scanRetrieve", id] as const,
    skillList: (page?: number) => ["skillList", nullIfUndefined(page)] as const,
    skillRetrieve: (id: string) => ["skillRetrieve", id] as const,
    tagList: (company?: string, page?: number) => ["tagList", nullIfUndefined(company), nullIfUndefined(page)] as const,
    tagRetrieve: (id: string) => ["tagRetrieve", id] as const,
    unlockRequestList: (approved?: boolean, page?: number, risk?: string) => ["unlockRequestList", nullIfUndefined(approved), nullIfUndefined(page), nullIfUndefined(risk)] as const,
    unlockRequestRetrieve: (id: string) => ["unlockRequestRetrieve", id] as const,
    userList: (page?: number, user_type?: ("client" | "partner" | "researcher" | "triager") | null) => ["userList", nullIfUndefined(page), nullIfUndefined(user_type)] as const,
    userRetrieve: (id: string) => ["userRetrieve", id] as const,
    userMeRetrieve: () => ["userMeRetrieve"] as const
} as const;
export type QueryKeys = typeof queryKeys;
function makeRequests(axios: AxiosInstance, config?: AxiosConfig) {
    return {
        apiSchemaRetrieve: (format?: "json" | "yaml", lang?: "af" | "ar" | "ar-dz" | "ast" | "az" | "be" | "bg" | "bn" | "br" | "bs" | "ca" | "ckb" | "cs" | "cy" | "da" | "de" | "dsb" | "el" | "en" | "en-au" | "en-gb" | "eo" | "es" | "es-ar" | "es-co" | "es-mx" | "es-ni" | "es-ve" | "et" | "eu" | "fa" | "fi" | "fr" | "fy" | "ga" | "gd" | "gl" | "he" | "hi" | "hr" | "hsb" | "hu" | "hy" | "ia" | "id" | "ig" | "io" | "is" | "it" | "ja" | "ka" | "kab" | "kk" | "km" | "kn" | "ko" | "ky" | "lb" | "lt" | "lv" | "mk" | "ml" | "mn" | "mr" | "ms" | "my" | "nb" | "ne" | "nl" | "nn" | "os" | "pa" | "pl" | "pt" | "pt-br" | "ro" | "ru" | "sk" | "sl" | "sq" | "sr" | "sr-latn" | "sv" | "sw" | "ta" | "te" | "tg" | "th" | "tk" | "tr" | "tt" | "udm" | "ug" | "uk" | "ur" | "uz" | "vi" | "zh-hans" | "zh-hant") => axios.request<{
            [key: string]: any;
        }>({
            method: "get",
            url: `/api/schema/`,
            params: {
                ...(format !== undefined ? { format } : undefined),
                ...(lang !== undefined ? { lang } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        attachmentList: (page?: number) => axios.request<PaginatedAttachmentList>({
            method: "get",
            url: `/attachment/`,
            params: {
                ...(page !== undefined ? { page } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        attachmentCreate: (payload: Attachment) => axios.request<Attachment>({
            method: "post",
            url: `/attachment/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        attachmentRetrieve: (id: string) => axios.request<Attachment>({
            method: "get",
            url: `/attachment/${id}/`
        }).then(res => res.data),
        attachmentDestroy: (id: string) => axios.request<unknown>({
            method: "delete",
            url: `/attachment/${id}/`
        }).then(res => res.data),
        attachmentUpdate: (payload: Attachment, id: string) => axios.request<Attachment>({
            method: "put",
            url: `/attachment/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        attachmentPartialUpdate: (payload: PatchedAttachment, id: string) => axios.request<Attachment>({
            method: "patch",
            url: `/attachment/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        attachmentPresignedUrlCreate: (payload: GetPreSignedUrl) => axios.request<PreSignedResponse>({
            method: "post",
            url: `/attachment/presigned_url/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        authLoginCreate: (payload: Auth) => axios.request<Auth>({
            method: "post",
            url: `/auth/login/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        authLogoutCreate: () => axios.request<unknown>({
            method: "post",
            url: `/auth/logout/`
        }).then(res => res.data),
        authLogoutallCreate: () => axios.request<unknown>({
            method: "post",
            url: `/auth/logoutall/`
        }).then(res => res.data),
        clientList: (page?: number) => axios.request<PaginatedUserList>({
            method: "get",
            url: `/client/`,
            params: {
                ...(page !== undefined ? { page } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        clientCreate: (payload: User) => axios.request<User>({
            method: "post",
            url: `/client/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        clientRetrieve: (id: string) => axios.request<User>({
            method: "get",
            url: `/client/${id}/`
        }).then(res => res.data),
        clientDestroy: (id: string) => axios.request<unknown>({
            method: "delete",
            url: `/client/${id}/`
        }).then(res => res.data),
        clientUpdate: (payload: User, id: string) => axios.request<User>({
            method: "put",
            url: `/client/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        clientPartialUpdate: (payload: PatchedUser, id: string) => axios.request<User>({
            method: "patch",
            url: `/client/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        commentList: (ordering?: string, page?: number, risk?: string) => axios.request<PaginatedCommentList>({
            method: "get",
            url: `/comment/`,
            params: {
                ...(ordering !== undefined ? { ordering } : undefined),
                ...(page !== undefined ? { page } : undefined),
                ...(risk !== undefined ? { risk } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        commentCreate: (payload: Comment, ordering?: string, risk?: string) => axios.request<Comment>({
            method: "post",
            url: `/comment/`,
            params: {
                ...(ordering !== undefined ? { ordering } : undefined),
                ...(risk !== undefined ? { risk } : undefined)
            },
            paramsSerializer: config?.paramsSerializer,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        commentRetrieve: (id: string, ordering?: string, risk?: string) => axios.request<Comment>({
            method: "get",
            url: `/comment/${id}/`,
            params: {
                ...(ordering !== undefined ? { ordering } : undefined),
                ...(risk !== undefined ? { risk } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        commentDestroy: (id: string, ordering?: string, risk?: string) => axios.request<unknown>({
            method: "delete",
            url: `/comment/${id}/`,
            params: {
                ...(ordering !== undefined ? { ordering } : undefined),
                ...(risk !== undefined ? { risk } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        commentUpdate: (payload: Comment, id: string, ordering?: string, risk?: string) => axios.request<Comment>({
            method: "put",
            url: `/comment/${id}/`,
            params: {
                ...(ordering !== undefined ? { ordering } : undefined),
                ...(risk !== undefined ? { risk } : undefined)
            },
            paramsSerializer: config?.paramsSerializer,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        commentPartialUpdate: (payload: PatchedComment, id: string, ordering?: string, risk?: string) => axios.request<Comment>({
            method: "patch",
            url: `/comment/${id}/`,
            params: {
                ...(ordering !== undefined ? { ordering } : undefined),
                ...(risk !== undefined ? { risk } : undefined)
            },
            paramsSerializer: config?.paramsSerializer,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        companyList: (page?: number) => axios.request<PaginatedCompanyList>({
            method: "get",
            url: `/company/`,
            params: {
                ...(page !== undefined ? { page } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        companyCreate: (payload: Company) => axios.request<Company>({
            method: "post",
            url: `/company/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        companyRetrieve: (id: string) => axios.request<Company>({
            method: "get",
            url: `/company/${id}/`
        }).then(res => res.data),
        companyDestroy: (id: string) => axios.request<unknown>({
            method: "delete",
            url: `/company/${id}/`
        }).then(res => res.data),
        companyUpdate: (payload: Company, id: string) => axios.request<Company>({
            method: "put",
            url: `/company/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        companyPartialUpdate: (payload: PatchedCompany, id: string) => axios.request<Company>({
            method: "patch",
            url: `/company/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        companyPendingInvitationsList: (id: string, page?: number) => axios.request<PaginatedInvitationList>({
            method: "get",
            url: `/company/${id}/pending_invitations/`,
            params: {
                ...(page !== undefined ? { page } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        companyTagsList: (id: string, page?: number) => axios.request<PaginatedTagList>({
            method: "get",
            url: `/company/${id}/tags/`,
            params: {
                ...(page !== undefined ? { page } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        companyUserAddCreate: (payload: CompanyUser) => axios.request<Company>({
            method: "post",
            url: `/company/user/add/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        companyUserRemoveCreate: (payload: CompanyUser) => axios.request<Company>({
            method: "post",
            url: `/company/user/remove/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        contractList: (page?: number, project?: string) => axios.request<PaginatedContractList>({
            method: "get",
            url: `/contract/`,
            params: {
                ...(page !== undefined ? { page } : undefined),
                ...(project !== undefined ? { project } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        contractCreate: (payload: Contract, project?: string) => axios.request<Contract>({
            method: "post",
            url: `/contract/`,
            params: {
                ...(project !== undefined ? { project } : undefined)
            },
            paramsSerializer: config?.paramsSerializer,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        contractRetrieve: (id: string, project?: string) => axios.request<Contract>({
            method: "get",
            url: `/contract/${id}/`,
            params: {
                ...(project !== undefined ? { project } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        contractDestroy: (id: string, project?: string) => axios.request<unknown>({
            method: "delete",
            url: `/contract/${id}/`,
            params: {
                ...(project !== undefined ? { project } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        contractUpdate: (payload: Contract, id: string, project?: string) => axios.request<Contract>({
            method: "put",
            url: `/contract/${id}/`,
            params: {
                ...(project !== undefined ? { project } : undefined)
            },
            paramsSerializer: config?.paramsSerializer,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        contractPartialUpdate: (payload: PatchedContract, id: string, project?: string) => axios.request<Contract>({
            method: "patch",
            url: `/contract/${id}/`,
            params: {
                ...(project !== undefined ? { project } : undefined)
            },
            paramsSerializer: config?.paramsSerializer,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        conversationList: (contract?: string, page?: number) => axios.request<PaginatedConversationList>({
            method: "get",
            url: `/conversation/`,
            params: {
                ...(contract !== undefined ? { contract } : undefined),
                ...(page !== undefined ? { page } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        conversationCreate: (payload: Conversation, contract?: string) => axios.request<Conversation>({
            method: "post",
            url: `/conversation/`,
            params: {
                ...(contract !== undefined ? { contract } : undefined)
            },
            paramsSerializer: config?.paramsSerializer,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        conversationRetrieve: (id: string, contract?: string) => axios.request<Conversation>({
            method: "get",
            url: `/conversation/${id}/`,
            params: {
                ...(contract !== undefined ? { contract } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        conversationDestroy: (id: string, contract?: string) => axios.request<unknown>({
            method: "delete",
            url: `/conversation/${id}/`,
            params: {
                ...(contract !== undefined ? { contract } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        conversationUpdate: (payload: Conversation, id: string, contract?: string) => axios.request<Conversation>({
            method: "put",
            url: `/conversation/${id}/`,
            params: {
                ...(contract !== undefined ? { contract } : undefined)
            },
            paramsSerializer: config?.paramsSerializer,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        conversationPartialUpdate: (payload: PatchedConversation, id: string, contract?: string) => axios.request<Conversation>({
            method: "patch",
            url: `/conversation/${id}/`,
            params: {
                ...(contract !== undefined ? { contract } : undefined)
            },
            paramsSerializer: config?.paramsSerializer,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        emailTemplateList: (page?: number) => axios.request<PaginatedEmailTemplateList>({
            method: "get",
            url: `/email_template/`,
            params: {
                ...(page !== undefined ? { page } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        emailTemplateCreate: (payload: EmailTemplate) => axios.request<EmailTemplate>({
            method: "post",
            url: `/email_template/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        emailTemplateRetrieve: (id: string) => axios.request<EmailTemplate>({
            method: "get",
            url: `/email_template/${id}/`
        }).then(res => res.data),
        emailTemplateDestroy: (id: string) => axios.request<unknown>({
            method: "delete",
            url: `/email_template/${id}/`
        }).then(res => res.data),
        emailTemplateUpdate: (payload: EmailTemplate, id: string) => axios.request<EmailTemplate>({
            method: "put",
            url: `/email_template/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        emailTemplatePartialUpdate: (payload: PatchedEmailTemplate, id: string) => axios.request<EmailTemplate>({
            method: "patch",
            url: `/email_template/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        invitationCreate: (payload: Invitation) => axios.request<Invitation>({
            method: "post",
            url: `/invitation/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        invitationCancelRetrieve: (id: string) => axios.request<Invitation>({
            method: "get",
            url: `/invitation/${id}/cancel/`
        }).then(res => res.data),
        invitationResendRetrieve: (id: string) => axios.request<Invitation>({
            method: "get",
            url: `/invitation/${id}/resend/`
        }).then(res => res.data),
        invitationAcceptExistingUserCreate: (payload: InvitationAcceptExistingUser) => axios.request<Company>({
            method: "post",
            url: `/invitation/accept_existing_user/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        invitationAcceptNewUserCreate: (payload: InvitationAcceptNewUser) => axios.request<Company>({
            method: "post",
            url: `/invitation/accept_new_user/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        invitationValidateCreate: (payload: InvitationValidate) => axios.request<InvitationValidateResponse>({
            method: "post",
            url: `/invitation/validate/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        messageList: (conversation?: string, page?: number, read?: boolean) => axios.request<PaginatedMessageList>({
            method: "get",
            url: `/message/`,
            params: {
                ...(conversation !== undefined ? { conversation } : undefined),
                ...(page !== undefined ? { page } : undefined),
                ...(read !== undefined ? { read } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        messageCreate: (payload: Message) => axios.request<Message>({
            method: "post",
            url: `/message/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        messageRetrieve: (id: string) => axios.request<Message>({
            method: "get",
            url: `/message/${id}/`
        }).then(res => res.data),
        messageDestroy: (id: string) => axios.request<unknown>({
            method: "delete",
            url: `/message/${id}/`
        }).then(res => res.data),
        messageUpdate: (payload: Message, id: string) => axios.request<Message>({
            method: "put",
            url: `/message/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        messagePartialUpdate: (payload: PatchedMessage, id: string) => axios.request<Message>({
            method: "patch",
            url: `/message/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        passwordResetCreate: (payload: Email) => axios.request<Email>({
            method: "post",
            url: `/password_reset/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        passwordResetConfirmCreate: (payload: PasswordToken) => axios.request<PasswordToken>({
            method: "post",
            url: `/password_reset/confirm/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        passwordResetValidateTokenCreate: (payload: ResetToken) => axios.request<ResetToken>({
            method: "post",
            url: `/password_reset/validate_token/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        projectList: (page?: number) => axios.request<PaginatedProjectList>({
            method: "get",
            url: `/project/`,
            params: {
                ...(page !== undefined ? { page } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        projectCreate: (payload: Project) => axios.request<Project>({
            method: "post",
            url: `/project/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        projectRetrieve: (id: string) => axios.request<Project>({
            method: "get",
            url: `/project/${id}/`
        }).then(res => res.data),
        projectDestroy: (id: string) => axios.request<unknown>({
            method: "delete",
            url: `/project/${id}/`
        }).then(res => res.data),
        projectUpdate: (payload: Project, id: string) => axios.request<Project>({
            method: "put",
            url: `/project/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        projectPartialUpdate: (payload: PatchedProject, id: string) => axios.request<Project>({
            method: "patch",
            url: `/project/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        projectDetailsRetrieve: (id: string) => axios.request<ProjectDetails>({
            method: "get",
            url: `/project/${id}/details/`
        }).then(res => res.data),
        proposalList: (page?: number, project?: string) => axios.request<PaginatedProposalList>({
            method: "get",
            url: `/proposal/`,
            params: {
                ...(page !== undefined ? { page } : undefined),
                ...(project !== undefined ? { project } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        proposalCreate: (payload: Proposal, project?: string) => axios.request<Proposal>({
            method: "post",
            url: `/proposal/`,
            params: {
                ...(project !== undefined ? { project } : undefined)
            },
            paramsSerializer: config?.paramsSerializer,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        proposalRetrieve: (id: string, project?: string) => axios.request<Proposal>({
            method: "get",
            url: `/proposal/${id}/`,
            params: {
                ...(project !== undefined ? { project } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        proposalDestroy: (id: string, project?: string) => axios.request<unknown>({
            method: "delete",
            url: `/proposal/${id}/`,
            params: {
                ...(project !== undefined ? { project } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        proposalUpdate: (payload: Proposal, id: string, project?: string) => axios.request<Proposal>({
            method: "put",
            url: `/proposal/${id}/`,
            params: {
                ...(project !== undefined ? { project } : undefined)
            },
            paramsSerializer: config?.paramsSerializer,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        proposalPartialUpdate: (payload: PatchedProposal, id: string, project?: string) => axios.request<Proposal>({
            method: "patch",
            url: `/proposal/${id}/`,
            params: {
                ...(project !== undefined ? { project } : undefined)
            },
            paramsSerializer: config?.paramsSerializer,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        researcherList: (page?: number) => axios.request<PaginatedUserList>({
            method: "get",
            url: `/researcher/`,
            params: {
                ...(page !== undefined ? { page } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        researcherCreate: (payload: User) => axios.request<User>({
            method: "post",
            url: `/researcher/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        researcherRetrieve: (id: string) => axios.request<User>({
            method: "get",
            url: `/researcher/${id}/`
        }).then(res => res.data),
        researcherDestroy: (id: string) => axios.request<unknown>({
            method: "delete",
            url: `/researcher/${id}/`
        }).then(res => res.data),
        researcherUpdate: (payload: User, id: string) => axios.request<User>({
            method: "put",
            url: `/researcher/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        researcherPartialUpdate: (payload: PatchedUser, id: string) => axios.request<User>({
            method: "patch",
            url: `/researcher/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskList: (assignee?: string[], category?: ("broken_access_control" | "cryptographic_failure" | "data_integrity_failure" | "identification_and_auth_failure" | "injection" | "insecure_design" | "secure_logging_failure" | "security_misconfiguration" | "server_side_request_forgery" | "vulnerable_and_outdated_components")[], created_after?: string, created_before?: string, page?: number, project?: string, severity?: ("critical" | "high" | "informational" | "low" | "medium")[], status?: "closed" | "in_progress" | "new" | "rejected", tag?: string[], title?: string, updated_after?: string, updated_before?: string) => axios.request<PaginatedRiskList>({
            method: "get",
            url: `/risk/`,
            params: {
                ...(assignee !== undefined ? { assignee } : undefined),
                ...(category !== undefined ? { category } : undefined),
                ...(created_after !== undefined ? { created_after } : undefined),
                ...(created_before !== undefined ? { created_before } : undefined),
                ...(page !== undefined ? { page } : undefined),
                ...(project !== undefined ? { project } : undefined),
                ...(severity !== undefined ? { severity } : undefined),
                ...(status !== undefined ? { status } : undefined),
                ...(tag !== undefined ? { tag } : undefined),
                ...(title !== undefined ? { title } : undefined),
                ...(updated_after !== undefined ? { updated_after } : undefined),
                ...(updated_before !== undefined ? { updated_before } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        riskCreate: (payload: Risk, project?: string, title?: string) => axios.request<Risk>({
            method: "post",
            url: `/risk/`,
            params: {
                ...(project !== undefined ? { project } : undefined),
                ...(title !== undefined ? { title } : undefined)
            },
            paramsSerializer: config?.paramsSerializer,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskRetrieve: (id: string, project?: string, title?: string) => axios.request<Risk>({
            method: "get",
            url: `/risk/${id}/`,
            params: {
                ...(project !== undefined ? { project } : undefined),
                ...(title !== undefined ? { title } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        riskDestroy: (id: string, project?: string, title?: string) => axios.request<unknown>({
            method: "delete",
            url: `/risk/${id}/`,
            params: {
                ...(project !== undefined ? { project } : undefined),
                ...(title !== undefined ? { title } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        riskUpdate: (payload: Risk, id: string, project?: string, title?: string) => axios.request<Risk>({
            method: "put",
            url: `/risk/${id}/`,
            params: {
                ...(project !== undefined ? { project } : undefined),
                ...(title !== undefined ? { title } : undefined)
            },
            paramsSerializer: config?.paramsSerializer,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskPartialUpdate: (payload: PatchedRisk, id: string, project?: string, title?: string) => axios.request<Risk>({
            method: "patch",
            url: `/risk/${id}/`,
            params: {
                ...(project !== undefined ? { project } : undefined),
                ...(title !== undefined ? { title } : undefined)
            },
            paramsSerializer: config?.paramsSerializer,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskAskToUnlockRetrieve: (id: string, project?: string, title?: string) => axios.request<unknown>({
            method: "get",
            url: `/risk/${id}/ask_to_unlock/`,
            params: {
                ...(project !== undefined ? { project } : undefined),
                ...(title !== undefined ? { title } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        riskCommentsList: (id: string, assignee?: string[], category?: ("broken_access_control" | "cryptographic_failure" | "data_integrity_failure" | "identification_and_auth_failure" | "injection" | "insecure_design" | "secure_logging_failure" | "security_misconfiguration" | "server_side_request_forgery" | "vulnerable_and_outdated_components")[], created_after?: string, created_before?: string, page?: number, project?: string, severity?: ("critical" | "high" | "informational" | "low" | "medium")[], status?: "closed" | "in_progress" | "new" | "rejected", tag?: string[], title?: string, updated_after?: string, updated_before?: string) => axios.request<PaginatedCommentList>({
            method: "get",
            url: `/risk/${id}/comments/`,
            params: {
                ...(assignee !== undefined ? { assignee } : undefined),
                ...(category !== undefined ? { category } : undefined),
                ...(created_after !== undefined ? { created_after } : undefined),
                ...(created_before !== undefined ? { created_before } : undefined),
                ...(page !== undefined ? { page } : undefined),
                ...(project !== undefined ? { project } : undefined),
                ...(severity !== undefined ? { severity } : undefined),
                ...(status !== undefined ? { status } : undefined),
                ...(tag !== undefined ? { tag } : undefined),
                ...(title !== undefined ? { title } : undefined),
                ...(updated_after !== undefined ? { updated_after } : undefined),
                ...(updated_before !== undefined ? { updated_before } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        riskHistoryList: (id: string, assignee?: string[], category?: ("broken_access_control" | "cryptographic_failure" | "data_integrity_failure" | "identification_and_auth_failure" | "injection" | "insecure_design" | "secure_logging_failure" | "security_misconfiguration" | "server_side_request_forgery" | "vulnerable_and_outdated_components")[], created_after?: string, created_before?: string, page?: number, project?: string, severity?: ("critical" | "high" | "informational" | "low" | "medium")[], status?: "closed" | "in_progress" | "new" | "rejected", tag?: string[], title?: string, updated_after?: string, updated_before?: string) => axios.request<PaginatedRiskHistoryList>({
            method: "get",
            url: `/risk/${id}/history/`,
            params: {
                ...(assignee !== undefined ? { assignee } : undefined),
                ...(category !== undefined ? { category } : undefined),
                ...(created_after !== undefined ? { created_after } : undefined),
                ...(created_before !== undefined ? { created_before } : undefined),
                ...(page !== undefined ? { page } : undefined),
                ...(project !== undefined ? { project } : undefined),
                ...(severity !== undefined ? { severity } : undefined),
                ...(status !== undefined ? { status } : undefined),
                ...(tag !== undefined ? { tag } : undefined),
                ...(title !== undefined ? { title } : undefined),
                ...(updated_after !== undefined ? { updated_after } : undefined),
                ...(updated_before !== undefined ? { updated_before } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        riskLinkCreate: (payload: RiskLink, id: string) => axios.request<Risk[]>({
            method: "post",
            url: `/risk/${id}/link/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskLinkedRisksList: (id: string) => axios.request<LinkedRisk[]>({
            method: "get",
            url: `/risk/${id}/linked_risks/`
        }).then(res => res.data),
        riskUnlinkCreate: (payload: RiskUnlink, id: string) => axios.request<Risk>({
            method: "post",
            url: `/risk/${id}/unlink/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskSearchRetrieve: (project?: string, title?: string) => axios.request<Risk>({
            method: "get",
            url: `/risk/search/`,
            params: {
                ...(project !== undefined ? { project } : undefined),
                ...(title !== undefined ? { title } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        riskTagList: (page?: number, risk?: string, tag?: string, tag_name?: string) => axios.request<PaginatedRiskTagList>({
            method: "get",
            url: `/risk_tag/`,
            params: {
                ...(page !== undefined ? { page } : undefined),
                ...(risk !== undefined ? { risk } : undefined),
                ...(tag !== undefined ? { tag } : undefined),
                ...(tag_name !== undefined ? { tag_name } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        riskTagCreate: (payload: RiskTag) => axios.request<RiskTag>({
            method: "post",
            url: `/risk_tag/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskTagRetrieve: (id: string) => axios.request<RiskTag>({
            method: "get",
            url: `/risk_tag/${id}/`
        }).then(res => res.data),
        riskTagDestroy: (id: string) => axios.request<unknown>({
            method: "delete",
            url: `/risk_tag/${id}/`
        }).then(res => res.data),
        riskTagUpdate: (payload: RiskTag, id: string) => axios.request<RiskTag>({
            method: "put",
            url: `/risk_tag/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        riskTagPartialUpdate: (payload: PatchedRiskTag, id: string) => axios.request<RiskTag>({
            method: "patch",
            url: `/risk_tag/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        scanList: (page?: number) => axios.request<PaginatedScanReadList>({
            method: "get",
            url: `/scan/`,
            params: {
                ...(page !== undefined ? { page } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        scanCreate: (payload: Scan) => axios.request<Scan>({
            method: "post",
            url: `/scan/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        scanRetrieve: (id: string) => axios.request<ScanRead>({
            method: "get",
            url: `/scan/${id}/`
        }).then(res => res.data),
        scanDestroy: (id: string) => axios.request<unknown>({
            method: "delete",
            url: `/scan/${id}/`
        }).then(res => res.data),
        scanUpdate: (payload: Scan, id: string) => axios.request<Scan>({
            method: "put",
            url: `/scan/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        scanPartialUpdate: (payload: PatchedScan, id: string) => axios.request<Scan>({
            method: "patch",
            url: `/scan/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        skillList: (page?: number) => axios.request<PaginatedSkillList>({
            method: "get",
            url: `/skill/`,
            params: {
                ...(page !== undefined ? { page } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        skillCreate: (payload: Skill) => axios.request<Skill>({
            method: "post",
            url: `/skill/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        skillRetrieve: (id: string) => axios.request<Skill>({
            method: "get",
            url: `/skill/${id}/`
        }).then(res => res.data),
        skillDestroy: (id: string) => axios.request<unknown>({
            method: "delete",
            url: `/skill/${id}/`
        }).then(res => res.data),
        skillUpdate: (payload: Skill, id: string) => axios.request<Skill>({
            method: "put",
            url: `/skill/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        skillPartialUpdate: (payload: PatchedSkill, id: string) => axios.request<Skill>({
            method: "patch",
            url: `/skill/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        tagList: (company?: string, page?: number) => axios.request<PaginatedTagList>({
            method: "get",
            url: `/tag/`,
            params: {
                ...(company !== undefined ? { company } : undefined),
                ...(page !== undefined ? { page } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        tagCreate: (payload: TagDetail) => axios.request<TagDetail>({
            method: "post",
            url: `/tag/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        tagRetrieve: (id: string) => axios.request<Tag>({
            method: "get",
            url: `/tag/${id}/`
        }).then(res => res.data),
        tagDestroy: (id: string) => axios.request<unknown>({
            method: "delete",
            url: `/tag/${id}/`
        }).then(res => res.data),
        tagUpdate: (payload: Tag, id: string) => axios.request<Tag>({
            method: "put",
            url: `/tag/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        tagPartialUpdate: (payload: PatchedTag, id: string) => axios.request<Tag>({
            method: "patch",
            url: `/tag/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        unlockRequestList: (approved?: boolean, page?: number, risk?: string) => axios.request<PaginatedUnlockRequestList>({
            method: "get",
            url: `/unlock_request/`,
            params: {
                ...(approved !== undefined ? { approved } : undefined),
                ...(page !== undefined ? { page } : undefined),
                ...(risk !== undefined ? { risk } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        unlockRequestCreate: (payload: UnlockRequest) => axios.request<UnlockRequest>({
            method: "post",
            url: `/unlock_request/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        unlockRequestRetrieve: (id: string) => axios.request<UnlockRequest>({
            method: "get",
            url: `/unlock_request/${id}/`
        }).then(res => res.data),
        unlockRequestDestroy: (id: string) => axios.request<unknown>({
            method: "delete",
            url: `/unlock_request/${id}/`
        }).then(res => res.data),
        unlockRequestUpdate: (payload: UnlockRequest, id: string) => axios.request<UnlockRequest>({
            method: "put",
            url: `/unlock_request/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        unlockRequestPartialUpdate: (payload: PatchedUnlockRequest, id: string) => axios.request<UnlockRequest>({
            method: "patch",
            url: `/unlock_request/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        userList: (page?: number, user_type?: ("client" | "partner" | "researcher" | "triager") | null) => axios.request<PaginatedUserList>({
            method: "get",
            url: `/user/`,
            params: {
                ...(page !== undefined ? { page } : undefined),
                ...(user_type !== undefined ? { user_type } : undefined)
            },
            paramsSerializer: config?.paramsSerializer
        }).then(res => res.data),
        userCreate: (payload: User) => axios.request<User>({
            method: "post",
            url: `/user/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        userRetrieve: (id: string) => axios.request<User>({
            method: "get",
            url: `/user/${id}/`
        }).then(res => res.data),
        userDestroy: (id: string) => axios.request<unknown>({
            method: "delete",
            url: `/user/${id}/`
        }).then(res => res.data),
        userUpdate: (payload: User, id: string) => axios.request<User>({
            method: "put",
            url: `/user/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        userPartialUpdate: (payload: PatchedUser, id: string) => axios.request<User>({
            method: "patch",
            url: `/user/${id}/`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        userMeRetrieve: () => axios.request<User>({
            method: "get",
            url: `/user/me/`
        }).then(res => res.data)
    } as const;
}
export type Requests = ReturnType<typeof makeRequests>;
export type Response<T extends keyof Requests> = Awaited<ReturnType<Requests[T]>>;
function makeQueries(requests: Requests) {
    return {
        useApiSchemaRetrieve: (format?: "json" | "yaml", lang?: "af" | "ar" | "ar-dz" | "ast" | "az" | "be" | "bg" | "bn" | "br" | "bs" | "ca" | "ckb" | "cs" | "cy" | "da" | "de" | "dsb" | "el" | "en" | "en-au" | "en-gb" | "eo" | "es" | "es-ar" | "es-co" | "es-mx" | "es-ni" | "es-ve" | "et" | "eu" | "fa" | "fi" | "fr" | "fy" | "ga" | "gd" | "gl" | "he" | "hi" | "hr" | "hsb" | "hu" | "hy" | "ia" | "id" | "ig" | "io" | "is" | "it" | "ja" | "ka" | "kab" | "kk" | "km" | "kn" | "ko" | "ky" | "lb" | "lt" | "lv" | "mk" | "ml" | "mn" | "mr" | "ms" | "my" | "nb" | "ne" | "nl" | "nn" | "os" | "pa" | "pl" | "pt" | "pt-br" | "ro" | "ru" | "sk" | "sl" | "sq" | "sr" | "sr-latn" | "sv" | "sw" | "ta" | "te" | "tg" | "th" | "tk" | "tr" | "tt" | "udm" | "ug" | "uk" | "ur" | "uz" | "vi" | "zh-hans" | "zh-hant", options?: Omit<UseQueryOptions<Response<"apiSchemaRetrieve">, unknown, Response<"apiSchemaRetrieve">, ReturnType<QueryKeys["apiSchemaRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"apiSchemaRetrieve">, unknown> => useQuery({ queryKey: queryKeys.apiSchemaRetrieve(format, lang), queryFn: () => requests.apiSchemaRetrieve(format, lang), ...options }),
        useAttachmentList: (page?: number, options?: Omit<UseQueryOptions<Response<"attachmentList">, unknown, Response<"attachmentList">, ReturnType<QueryKeys["attachmentList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"attachmentList">, unknown> => useQuery({ queryKey: queryKeys.attachmentList(page), queryFn: () => requests.attachmentList(page), ...options }),
        useAttachmentRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"attachmentRetrieve">, unknown, Response<"attachmentRetrieve">, ReturnType<QueryKeys["attachmentRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"attachmentRetrieve">, unknown> => useQuery({ queryKey: queryKeys.attachmentRetrieve(id), queryFn: () => requests.attachmentRetrieve(id), ...options }),
        useClientList: (page?: number, options?: Omit<UseQueryOptions<Response<"clientList">, unknown, Response<"clientList">, ReturnType<QueryKeys["clientList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"clientList">, unknown> => useQuery({ queryKey: queryKeys.clientList(page), queryFn: () => requests.clientList(page), ...options }),
        useClientRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"clientRetrieve">, unknown, Response<"clientRetrieve">, ReturnType<QueryKeys["clientRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"clientRetrieve">, unknown> => useQuery({ queryKey: queryKeys.clientRetrieve(id), queryFn: () => requests.clientRetrieve(id), ...options }),
        useCommentList: (ordering?: string, page?: number, risk?: string, options?: Omit<UseQueryOptions<Response<"commentList">, unknown, Response<"commentList">, ReturnType<QueryKeys["commentList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"commentList">, unknown> => useQuery({ queryKey: queryKeys.commentList(ordering, page, risk), queryFn: () => requests.commentList(ordering, page, risk), ...options }),
        useCommentRetrieve: (id: string, ordering?: string, risk?: string, options?: Omit<UseQueryOptions<Response<"commentRetrieve">, unknown, Response<"commentRetrieve">, ReturnType<QueryKeys["commentRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"commentRetrieve">, unknown> => useQuery({ queryKey: queryKeys.commentRetrieve(id, ordering, risk), queryFn: () => requests.commentRetrieve(id, ordering, risk), ...options }),
        useCompanyList: (page?: number, options?: Omit<UseQueryOptions<Response<"companyList">, unknown, Response<"companyList">, ReturnType<QueryKeys["companyList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"companyList">, unknown> => useQuery({ queryKey: queryKeys.companyList(page), queryFn: () => requests.companyList(page), ...options }),
        useCompanyRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"companyRetrieve">, unknown, Response<"companyRetrieve">, ReturnType<QueryKeys["companyRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"companyRetrieve">, unknown> => useQuery({ queryKey: queryKeys.companyRetrieve(id), queryFn: () => requests.companyRetrieve(id), ...options }),
        useCompanyPendingInvitationsList: (id: string, page?: number, options?: Omit<UseQueryOptions<Response<"companyPendingInvitationsList">, unknown, Response<"companyPendingInvitationsList">, ReturnType<QueryKeys["companyPendingInvitationsList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"companyPendingInvitationsList">, unknown> => useQuery({ queryKey: queryKeys.companyPendingInvitationsList(id, page), queryFn: () => requests.companyPendingInvitationsList(id, page), ...options }),
        useCompanyTagsList: (id: string, page?: number, options?: Omit<UseQueryOptions<Response<"companyTagsList">, unknown, Response<"companyTagsList">, ReturnType<QueryKeys["companyTagsList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"companyTagsList">, unknown> => useQuery({ queryKey: queryKeys.companyTagsList(id, page), queryFn: () => requests.companyTagsList(id, page), ...options }),
        useContractList: (page?: number, project?: string, options?: Omit<UseQueryOptions<Response<"contractList">, unknown, Response<"contractList">, ReturnType<QueryKeys["contractList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"contractList">, unknown> => useQuery({ queryKey: queryKeys.contractList(page, project), queryFn: () => requests.contractList(page, project), ...options }),
        useContractRetrieve: (id: string, project?: string, options?: Omit<UseQueryOptions<Response<"contractRetrieve">, unknown, Response<"contractRetrieve">, ReturnType<QueryKeys["contractRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"contractRetrieve">, unknown> => useQuery({ queryKey: queryKeys.contractRetrieve(id, project), queryFn: () => requests.contractRetrieve(id, project), ...options }),
        useConversationList: (contract?: string, page?: number, options?: Omit<UseQueryOptions<Response<"conversationList">, unknown, Response<"conversationList">, ReturnType<QueryKeys["conversationList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"conversationList">, unknown> => useQuery({ queryKey: queryKeys.conversationList(contract, page), queryFn: () => requests.conversationList(contract, page), ...options }),
        useConversationRetrieve: (id: string, contract?: string, options?: Omit<UseQueryOptions<Response<"conversationRetrieve">, unknown, Response<"conversationRetrieve">, ReturnType<QueryKeys["conversationRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"conversationRetrieve">, unknown> => useQuery({ queryKey: queryKeys.conversationRetrieve(id, contract), queryFn: () => requests.conversationRetrieve(id, contract), ...options }),
        useEmailTemplateList: (page?: number, options?: Omit<UseQueryOptions<Response<"emailTemplateList">, unknown, Response<"emailTemplateList">, ReturnType<QueryKeys["emailTemplateList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"emailTemplateList">, unknown> => useQuery({ queryKey: queryKeys.emailTemplateList(page), queryFn: () => requests.emailTemplateList(page), ...options }),
        useEmailTemplateRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"emailTemplateRetrieve">, unknown, Response<"emailTemplateRetrieve">, ReturnType<QueryKeys["emailTemplateRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"emailTemplateRetrieve">, unknown> => useQuery({ queryKey: queryKeys.emailTemplateRetrieve(id), queryFn: () => requests.emailTemplateRetrieve(id), ...options }),
        useInvitationCancelRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"invitationCancelRetrieve">, unknown, Response<"invitationCancelRetrieve">, ReturnType<QueryKeys["invitationCancelRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"invitationCancelRetrieve">, unknown> => useQuery({ queryKey: queryKeys.invitationCancelRetrieve(id), queryFn: () => requests.invitationCancelRetrieve(id), ...options }),
        useInvitationResendRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"invitationResendRetrieve">, unknown, Response<"invitationResendRetrieve">, ReturnType<QueryKeys["invitationResendRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"invitationResendRetrieve">, unknown> => useQuery({ queryKey: queryKeys.invitationResendRetrieve(id), queryFn: () => requests.invitationResendRetrieve(id), ...options }),
        useMessageList: (conversation?: string, page?: number, read?: boolean, options?: Omit<UseQueryOptions<Response<"messageList">, unknown, Response<"messageList">, ReturnType<QueryKeys["messageList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"messageList">, unknown> => useQuery({ queryKey: queryKeys.messageList(conversation, page, read), queryFn: () => requests.messageList(conversation, page, read), ...options }),
        useMessageRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"messageRetrieve">, unknown, Response<"messageRetrieve">, ReturnType<QueryKeys["messageRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"messageRetrieve">, unknown> => useQuery({ queryKey: queryKeys.messageRetrieve(id), queryFn: () => requests.messageRetrieve(id), ...options }),
        useProjectList: (page?: number, options?: Omit<UseQueryOptions<Response<"projectList">, unknown, Response<"projectList">, ReturnType<QueryKeys["projectList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"projectList">, unknown> => useQuery({ queryKey: queryKeys.projectList(page), queryFn: () => requests.projectList(page), ...options }),
        useProjectRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"projectRetrieve">, unknown, Response<"projectRetrieve">, ReturnType<QueryKeys["projectRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"projectRetrieve">, unknown> => useQuery({ queryKey: queryKeys.projectRetrieve(id), queryFn: () => requests.projectRetrieve(id), ...options }),
        useProjectDetailsRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"projectDetailsRetrieve">, unknown, Response<"projectDetailsRetrieve">, ReturnType<QueryKeys["projectDetailsRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"projectDetailsRetrieve">, unknown> => useQuery({ queryKey: queryKeys.projectDetailsRetrieve(id), queryFn: () => requests.projectDetailsRetrieve(id), ...options }),
        useProposalList: (page?: number, project?: string, options?: Omit<UseQueryOptions<Response<"proposalList">, unknown, Response<"proposalList">, ReturnType<QueryKeys["proposalList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"proposalList">, unknown> => useQuery({ queryKey: queryKeys.proposalList(page, project), queryFn: () => requests.proposalList(page, project), ...options }),
        useProposalRetrieve: (id: string, project?: string, options?: Omit<UseQueryOptions<Response<"proposalRetrieve">, unknown, Response<"proposalRetrieve">, ReturnType<QueryKeys["proposalRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"proposalRetrieve">, unknown> => useQuery({ queryKey: queryKeys.proposalRetrieve(id, project), queryFn: () => requests.proposalRetrieve(id, project), ...options }),
        useResearcherList: (page?: number, options?: Omit<UseQueryOptions<Response<"researcherList">, unknown, Response<"researcherList">, ReturnType<QueryKeys["researcherList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"researcherList">, unknown> => useQuery({ queryKey: queryKeys.researcherList(page), queryFn: () => requests.researcherList(page), ...options }),
        useResearcherRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"researcherRetrieve">, unknown, Response<"researcherRetrieve">, ReturnType<QueryKeys["researcherRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"researcherRetrieve">, unknown> => useQuery({ queryKey: queryKeys.researcherRetrieve(id), queryFn: () => requests.researcherRetrieve(id), ...options }),
        useRiskList: (assignee?: string[], category?: ("broken_access_control" | "cryptographic_failure" | "data_integrity_failure" | "identification_and_auth_failure" | "injection" | "insecure_design" | "secure_logging_failure" | "security_misconfiguration" | "server_side_request_forgery" | "vulnerable_and_outdated_components")[], created_after?: string, created_before?: string, page?: number, project?: string, severity?: ("critical" | "high" | "informational" | "low" | "medium")[], status?: "closed" | "in_progress" | "new" | "rejected", tag?: string[], title?: string, updated_after?: string, updated_before?: string, options?: Omit<UseQueryOptions<Response<"riskList">, unknown, Response<"riskList">, ReturnType<QueryKeys["riskList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"riskList">, unknown> => useQuery({ queryKey: queryKeys.riskList(assignee, category, created_after, created_before, page, project, severity, status, tag, title, updated_after, updated_before), queryFn: () => requests.riskList(assignee, category, created_after, created_before, page, project, severity, status, tag, title, updated_after, updated_before), ...options }),
        useRiskRetrieve: (id: string, project?: string, title?: string, options?: Omit<UseQueryOptions<Response<"riskRetrieve">, unknown, Response<"riskRetrieve">, ReturnType<QueryKeys["riskRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"riskRetrieve">, unknown> => useQuery({ queryKey: queryKeys.riskRetrieve(id, project, title), queryFn: () => requests.riskRetrieve(id, project, title), ...options }),
        useRiskAskToUnlockRetrieve: (id: string, project?: string, title?: string, options?: Omit<UseQueryOptions<Response<"riskAskToUnlockRetrieve">, unknown, Response<"riskAskToUnlockRetrieve">, ReturnType<QueryKeys["riskAskToUnlockRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"riskAskToUnlockRetrieve">, unknown> => useQuery({ queryKey: queryKeys.riskAskToUnlockRetrieve(id, project, title), queryFn: () => requests.riskAskToUnlockRetrieve(id, project, title), ...options }),
        useRiskCommentsList: (id: string, assignee?: string[], category?: ("broken_access_control" | "cryptographic_failure" | "data_integrity_failure" | "identification_and_auth_failure" | "injection" | "insecure_design" | "secure_logging_failure" | "security_misconfiguration" | "server_side_request_forgery" | "vulnerable_and_outdated_components")[], created_after?: string, created_before?: string, page?: number, project?: string, severity?: ("critical" | "high" | "informational" | "low" | "medium")[], status?: "closed" | "in_progress" | "new" | "rejected", tag?: string[], title?: string, updated_after?: string, updated_before?: string, options?: Omit<UseQueryOptions<Response<"riskCommentsList">, unknown, Response<"riskCommentsList">, ReturnType<QueryKeys["riskCommentsList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"riskCommentsList">, unknown> => useQuery({ queryKey: queryKeys.riskCommentsList(id, assignee, category, created_after, created_before, page, project, severity, status, tag, title, updated_after, updated_before), queryFn: () => requests.riskCommentsList(id, assignee, category, created_after, created_before, page, project, severity, status, tag, title, updated_after, updated_before), ...options }),
        useRiskHistoryList: (id: string, assignee?: string[], category?: ("broken_access_control" | "cryptographic_failure" | "data_integrity_failure" | "identification_and_auth_failure" | "injection" | "insecure_design" | "secure_logging_failure" | "security_misconfiguration" | "server_side_request_forgery" | "vulnerable_and_outdated_components")[], created_after?: string, created_before?: string, page?: number, project?: string, severity?: ("critical" | "high" | "informational" | "low" | "medium")[], status?: "closed" | "in_progress" | "new" | "rejected", tag?: string[], title?: string, updated_after?: string, updated_before?: string, options?: Omit<UseQueryOptions<Response<"riskHistoryList">, unknown, Response<"riskHistoryList">, ReturnType<QueryKeys["riskHistoryList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"riskHistoryList">, unknown> => useQuery({ queryKey: queryKeys.riskHistoryList(id, assignee, category, created_after, created_before, page, project, severity, status, tag, title, updated_after, updated_before), queryFn: () => requests.riskHistoryList(id, assignee, category, created_after, created_before, page, project, severity, status, tag, title, updated_after, updated_before), ...options }),
        useRiskLinkedRisksList: (id: string, options?: Omit<UseQueryOptions<Response<"riskLinkedRisksList">, unknown, Response<"riskLinkedRisksList">, ReturnType<QueryKeys["riskLinkedRisksList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"riskLinkedRisksList">, unknown> => useQuery({ queryKey: queryKeys.riskLinkedRisksList(id), queryFn: () => requests.riskLinkedRisksList(id), ...options }),
        useRiskSearchRetrieve: (project?: string, title?: string, options?: Omit<UseQueryOptions<Response<"riskSearchRetrieve">, unknown, Response<"riskSearchRetrieve">, ReturnType<QueryKeys["riskSearchRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"riskSearchRetrieve">, unknown> => useQuery({ queryKey: queryKeys.riskSearchRetrieve(project, title), queryFn: () => requests.riskSearchRetrieve(project, title), ...options }),
        useRiskTagList: (page?: number, risk?: string, tag?: string, tag_name?: string, options?: Omit<UseQueryOptions<Response<"riskTagList">, unknown, Response<"riskTagList">, ReturnType<QueryKeys["riskTagList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"riskTagList">, unknown> => useQuery({ queryKey: queryKeys.riskTagList(page, risk, tag, tag_name), queryFn: () => requests.riskTagList(page, risk, tag, tag_name), ...options }),
        useRiskTagRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"riskTagRetrieve">, unknown, Response<"riskTagRetrieve">, ReturnType<QueryKeys["riskTagRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"riskTagRetrieve">, unknown> => useQuery({ queryKey: queryKeys.riskTagRetrieve(id), queryFn: () => requests.riskTagRetrieve(id), ...options }),
        useScanList: (page?: number, options?: Omit<UseQueryOptions<Response<"scanList">, unknown, Response<"scanList">, ReturnType<QueryKeys["scanList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"scanList">, unknown> => useQuery({ queryKey: queryKeys.scanList(page), queryFn: () => requests.scanList(page), ...options }),
        useScanRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"scanRetrieve">, unknown, Response<"scanRetrieve">, ReturnType<QueryKeys["scanRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"scanRetrieve">, unknown> => useQuery({ queryKey: queryKeys.scanRetrieve(id), queryFn: () => requests.scanRetrieve(id), ...options }),
        useSkillList: (page?: number, options?: Omit<UseQueryOptions<Response<"skillList">, unknown, Response<"skillList">, ReturnType<QueryKeys["skillList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"skillList">, unknown> => useQuery({ queryKey: queryKeys.skillList(page), queryFn: () => requests.skillList(page), ...options }),
        useSkillRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"skillRetrieve">, unknown, Response<"skillRetrieve">, ReturnType<QueryKeys["skillRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"skillRetrieve">, unknown> => useQuery({ queryKey: queryKeys.skillRetrieve(id), queryFn: () => requests.skillRetrieve(id), ...options }),
        useTagList: (company?: string, page?: number, options?: Omit<UseQueryOptions<Response<"tagList">, unknown, Response<"tagList">, ReturnType<QueryKeys["tagList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"tagList">, unknown> => useQuery({ queryKey: queryKeys.tagList(company, page), queryFn: () => requests.tagList(company, page), ...options }),
        useTagRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"tagRetrieve">, unknown, Response<"tagRetrieve">, ReturnType<QueryKeys["tagRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"tagRetrieve">, unknown> => useQuery({ queryKey: queryKeys.tagRetrieve(id), queryFn: () => requests.tagRetrieve(id), ...options }),
        useUnlockRequestList: (approved?: boolean, page?: number, risk?: string, options?: Omit<UseQueryOptions<Response<"unlockRequestList">, unknown, Response<"unlockRequestList">, ReturnType<QueryKeys["unlockRequestList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"unlockRequestList">, unknown> => useQuery({ queryKey: queryKeys.unlockRequestList(approved, page, risk), queryFn: () => requests.unlockRequestList(approved, page, risk), ...options }),
        useUnlockRequestRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"unlockRequestRetrieve">, unknown, Response<"unlockRequestRetrieve">, ReturnType<QueryKeys["unlockRequestRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"unlockRequestRetrieve">, unknown> => useQuery({ queryKey: queryKeys.unlockRequestRetrieve(id), queryFn: () => requests.unlockRequestRetrieve(id), ...options }),
        useUserList: (page?: number, user_type?: ("client" | "partner" | "researcher" | "triager") | null, options?: Omit<UseQueryOptions<Response<"userList">, unknown, Response<"userList">, ReturnType<QueryKeys["userList"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"userList">, unknown> => useQuery({ queryKey: queryKeys.userList(page, user_type), queryFn: () => requests.userList(page, user_type), ...options }),
        useUserRetrieve: (id: string, options?: Omit<UseQueryOptions<Response<"userRetrieve">, unknown, Response<"userRetrieve">, ReturnType<QueryKeys["userRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"userRetrieve">, unknown> => useQuery({ queryKey: queryKeys.userRetrieve(id), queryFn: () => requests.userRetrieve(id), ...options }),
        useUserMeRetrieve: (options?: Omit<UseQueryOptions<Response<"userMeRetrieve">, unknown, Response<"userMeRetrieve">, ReturnType<QueryKeys["userMeRetrieve"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"userMeRetrieve">, unknown> => useQuery({ queryKey: queryKeys.userMeRetrieve(), queryFn: () => requests.userMeRetrieve(), ...options })
    } as const;
}
type MutationConfigs = {
    useAttachmentCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"attachmentCreate">, unknown, Parameters<Requests["attachmentCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useAttachmentUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"attachmentUpdate">, unknown, Parameters<Requests["attachmentUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useAttachmentPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"attachmentPartialUpdate">, unknown, Parameters<Requests["attachmentPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useAttachmentDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"attachmentDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useAttachmentPresignedUrlCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"attachmentPresignedUrlCreate">, unknown, Parameters<Requests["attachmentPresignedUrlCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useAuthLoginCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"authLoginCreate">, unknown, Parameters<Requests["authLoginCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useAuthLogoutCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"authLogoutCreate">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useAuthLogoutallCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"authLogoutallCreate">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useClientCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"clientCreate">, unknown, Parameters<Requests["clientCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useClientUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"clientUpdate">, unknown, Parameters<Requests["clientUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useClientPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"clientPartialUpdate">, unknown, Parameters<Requests["clientPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useClientDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"clientDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useCommentCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"commentCreate">, unknown, Parameters<Requests["commentCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useCommentUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"commentUpdate">, unknown, Parameters<Requests["commentUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useCommentPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"commentPartialUpdate">, unknown, Parameters<Requests["commentPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useCommentDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"commentDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useCompanyCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"companyCreate">, unknown, Parameters<Requests["companyCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useCompanyUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"companyUpdate">, unknown, Parameters<Requests["companyUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useCompanyPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"companyPartialUpdate">, unknown, Parameters<Requests["companyPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useCompanyDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"companyDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useCompanyUserAddCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"companyUserAddCreate">, unknown, Parameters<Requests["companyUserAddCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useCompanyUserRemoveCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"companyUserRemoveCreate">, unknown, Parameters<Requests["companyUserRemoveCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useContractCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"contractCreate">, unknown, Parameters<Requests["contractCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useContractUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"contractUpdate">, unknown, Parameters<Requests["contractUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useContractPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"contractPartialUpdate">, unknown, Parameters<Requests["contractPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useContractDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"contractDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useConversationCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"conversationCreate">, unknown, Parameters<Requests["conversationCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useConversationUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"conversationUpdate">, unknown, Parameters<Requests["conversationUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useConversationPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"conversationPartialUpdate">, unknown, Parameters<Requests["conversationPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useConversationDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"conversationDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useEmailTemplateCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"emailTemplateCreate">, unknown, Parameters<Requests["emailTemplateCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useEmailTemplateUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"emailTemplateUpdate">, unknown, Parameters<Requests["emailTemplateUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useEmailTemplatePartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"emailTemplatePartialUpdate">, unknown, Parameters<Requests["emailTemplatePartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useEmailTemplateDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"emailTemplateDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useInvitationCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"invitationCreate">, unknown, Parameters<Requests["invitationCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useInvitationAcceptExistingUserCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"invitationAcceptExistingUserCreate">, unknown, Parameters<Requests["invitationAcceptExistingUserCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useInvitationAcceptNewUserCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"invitationAcceptNewUserCreate">, unknown, Parameters<Requests["invitationAcceptNewUserCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useInvitationValidateCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"invitationValidateCreate">, unknown, Parameters<Requests["invitationValidateCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useMessageCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"messageCreate">, unknown, Parameters<Requests["messageCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useMessageUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"messageUpdate">, unknown, Parameters<Requests["messageUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useMessagePartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"messagePartialUpdate">, unknown, Parameters<Requests["messagePartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useMessageDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"messageDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    usePasswordResetCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"passwordResetCreate">, unknown, Parameters<Requests["passwordResetCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    usePasswordResetConfirmCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"passwordResetConfirmCreate">, unknown, Parameters<Requests["passwordResetConfirmCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    usePasswordResetValidateTokenCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"passwordResetValidateTokenCreate">, unknown, Parameters<Requests["passwordResetValidateTokenCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useProjectCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"projectCreate">, unknown, Parameters<Requests["projectCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useProjectUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"projectUpdate">, unknown, Parameters<Requests["projectUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useProjectPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"projectPartialUpdate">, unknown, Parameters<Requests["projectPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useProjectDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"projectDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useProposalCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"proposalCreate">, unknown, Parameters<Requests["proposalCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useProposalUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"proposalUpdate">, unknown, Parameters<Requests["proposalUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useProposalPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"proposalPartialUpdate">, unknown, Parameters<Requests["proposalPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useProposalDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"proposalDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useResearcherCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"researcherCreate">, unknown, Parameters<Requests["researcherCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useResearcherUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"researcherUpdate">, unknown, Parameters<Requests["researcherUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useResearcherPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"researcherPartialUpdate">, unknown, Parameters<Requests["researcherPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useResearcherDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"researcherDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskCreate">, unknown, Parameters<Requests["riskCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskUpdate">, unknown, Parameters<Requests["riskUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskPartialUpdate">, unknown, Parameters<Requests["riskPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskLinkCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskLinkCreate">, unknown, Parameters<Requests["riskLinkCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskUnlinkCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskUnlinkCreate">, unknown, Parameters<Requests["riskUnlinkCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskTagCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskTagCreate">, unknown, Parameters<Requests["riskTagCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskTagUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskTagUpdate">, unknown, Parameters<Requests["riskTagUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskTagPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskTagPartialUpdate">, unknown, Parameters<Requests["riskTagPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useRiskTagDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"riskTagDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useScanCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"scanCreate">, unknown, Parameters<Requests["scanCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useScanUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"scanUpdate">, unknown, Parameters<Requests["scanUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useScanPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"scanPartialUpdate">, unknown, Parameters<Requests["scanPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useScanDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"scanDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useSkillCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"skillCreate">, unknown, Parameters<Requests["skillCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useSkillUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"skillUpdate">, unknown, Parameters<Requests["skillUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useSkillPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"skillPartialUpdate">, unknown, Parameters<Requests["skillPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useSkillDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"skillDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useTagCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"tagCreate">, unknown, Parameters<Requests["tagCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useTagUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"tagUpdate">, unknown, Parameters<Requests["tagUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useTagPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"tagPartialUpdate">, unknown, Parameters<Requests["tagPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useTagDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"tagDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useUnlockRequestCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"unlockRequestCreate">, unknown, Parameters<Requests["unlockRequestCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useUnlockRequestUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"unlockRequestUpdate">, unknown, Parameters<Requests["unlockRequestUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useUnlockRequestPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"unlockRequestPartialUpdate">, unknown, Parameters<Requests["unlockRequestPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useUnlockRequestDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"unlockRequestDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useUserCreate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"userCreate">, unknown, Parameters<Requests["userCreate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useUserUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"userUpdate">, unknown, Parameters<Requests["userUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useUserPartialUpdate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"userPartialUpdate">, unknown, Parameters<Requests["userPartialUpdate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useUserDestroy?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"userDestroy">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
};
function makeMutations(requests: Requests, config?: Config["mutations"]) {
    return {
        useAttachmentCreate: (options?: Omit<UseMutationOptions<Response<"attachmentCreate">, unknown, Parameters<Requests["attachmentCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"attachmentCreate">, unknown, Parameters<Requests["attachmentCreate"]>[0]>(payload => requests.attachmentCreate(payload), config?.useAttachmentCreate, options),
        useAttachmentUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"attachmentUpdate">, unknown, Parameters<Requests["attachmentUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"attachmentUpdate">, unknown, Parameters<Requests["attachmentUpdate"]>[0]>(payload => requests.attachmentUpdate(payload, id), config?.useAttachmentUpdate, options),
        useAttachmentPartialUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"attachmentPartialUpdate">, unknown, Parameters<Requests["attachmentPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"attachmentPartialUpdate">, unknown, Parameters<Requests["attachmentPartialUpdate"]>[0]>(payload => requests.attachmentPartialUpdate(payload, id), config?.useAttachmentPartialUpdate, options),
        useAttachmentDestroy: (id: string, options?: Omit<UseMutationOptions<Response<"attachmentDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"attachmentDestroy">, unknown, unknown>(() => requests.attachmentDestroy(id), config?.useAttachmentDestroy, options),
        useAttachmentPresignedUrlCreate: (options?: Omit<UseMutationOptions<Response<"attachmentPresignedUrlCreate">, unknown, Parameters<Requests["attachmentPresignedUrlCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"attachmentPresignedUrlCreate">, unknown, Parameters<Requests["attachmentPresignedUrlCreate"]>[0]>(payload => requests.attachmentPresignedUrlCreate(payload), config?.useAttachmentPresignedUrlCreate, options),
        useAuthLoginCreate: (options?: Omit<UseMutationOptions<Response<"authLoginCreate">, unknown, Parameters<Requests["authLoginCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"authLoginCreate">, unknown, Parameters<Requests["authLoginCreate"]>[0]>(payload => requests.authLoginCreate(payload), config?.useAuthLoginCreate, options),
        useAuthLogoutCreate: (options?: Omit<UseMutationOptions<Response<"authLogoutCreate">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"authLogoutCreate">, unknown, unknown>(() => requests.authLogoutCreate(), config?.useAuthLogoutCreate, options),
        useAuthLogoutallCreate: (options?: Omit<UseMutationOptions<Response<"authLogoutallCreate">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"authLogoutallCreate">, unknown, unknown>(() => requests.authLogoutallCreate(), config?.useAuthLogoutallCreate, options),
        useClientCreate: (options?: Omit<UseMutationOptions<Response<"clientCreate">, unknown, Parameters<Requests["clientCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"clientCreate">, unknown, Parameters<Requests["clientCreate"]>[0]>(payload => requests.clientCreate(payload), config?.useClientCreate, options),
        useClientUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"clientUpdate">, unknown, Parameters<Requests["clientUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"clientUpdate">, unknown, Parameters<Requests["clientUpdate"]>[0]>(payload => requests.clientUpdate(payload, id), config?.useClientUpdate, options),
        useClientPartialUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"clientPartialUpdate">, unknown, Parameters<Requests["clientPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"clientPartialUpdate">, unknown, Parameters<Requests["clientPartialUpdate"]>[0]>(payload => requests.clientPartialUpdate(payload, id), config?.useClientPartialUpdate, options),
        useClientDestroy: (id: string, options?: Omit<UseMutationOptions<Response<"clientDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"clientDestroy">, unknown, unknown>(() => requests.clientDestroy(id), config?.useClientDestroy, options),
        useCommentCreate: (ordering?: string, risk?: string, options?: Omit<UseMutationOptions<Response<"commentCreate">, unknown, Parameters<Requests["commentCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"commentCreate">, unknown, Parameters<Requests["commentCreate"]>[0]>(payload => requests.commentCreate(payload, ordering, risk), config?.useCommentCreate, options),
        useCommentUpdate: (id: string, ordering?: string, risk?: string, options?: Omit<UseMutationOptions<Response<"commentUpdate">, unknown, Parameters<Requests["commentUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"commentUpdate">, unknown, Parameters<Requests["commentUpdate"]>[0]>(payload => requests.commentUpdate(payload, id, ordering, risk), config?.useCommentUpdate, options),
        useCommentPartialUpdate: (id: string, ordering?: string, risk?: string, options?: Omit<UseMutationOptions<Response<"commentPartialUpdate">, unknown, Parameters<Requests["commentPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"commentPartialUpdate">, unknown, Parameters<Requests["commentPartialUpdate"]>[0]>(payload => requests.commentPartialUpdate(payload, id, ordering, risk), config?.useCommentPartialUpdate, options),
        useCommentDestroy: (id: string, ordering?: string, risk?: string, options?: Omit<UseMutationOptions<Response<"commentDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"commentDestroy">, unknown, unknown>(() => requests.commentDestroy(id, ordering, risk), config?.useCommentDestroy, options),
        useCompanyCreate: (options?: Omit<UseMutationOptions<Response<"companyCreate">, unknown, Parameters<Requests["companyCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"companyCreate">, unknown, Parameters<Requests["companyCreate"]>[0]>(payload => requests.companyCreate(payload), config?.useCompanyCreate, options),
        useCompanyUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"companyUpdate">, unknown, Parameters<Requests["companyUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"companyUpdate">, unknown, Parameters<Requests["companyUpdate"]>[0]>(payload => requests.companyUpdate(payload, id), config?.useCompanyUpdate, options),
        useCompanyPartialUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"companyPartialUpdate">, unknown, Parameters<Requests["companyPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"companyPartialUpdate">, unknown, Parameters<Requests["companyPartialUpdate"]>[0]>(payload => requests.companyPartialUpdate(payload, id), config?.useCompanyPartialUpdate, options),
        useCompanyDestroy: (id: string, options?: Omit<UseMutationOptions<Response<"companyDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"companyDestroy">, unknown, unknown>(() => requests.companyDestroy(id), config?.useCompanyDestroy, options),
        useCompanyUserAddCreate: (options?: Omit<UseMutationOptions<Response<"companyUserAddCreate">, unknown, Parameters<Requests["companyUserAddCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"companyUserAddCreate">, unknown, Parameters<Requests["companyUserAddCreate"]>[0]>(payload => requests.companyUserAddCreate(payload), config?.useCompanyUserAddCreate, options),
        useCompanyUserRemoveCreate: (options?: Omit<UseMutationOptions<Response<"companyUserRemoveCreate">, unknown, Parameters<Requests["companyUserRemoveCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"companyUserRemoveCreate">, unknown, Parameters<Requests["companyUserRemoveCreate"]>[0]>(payload => requests.companyUserRemoveCreate(payload), config?.useCompanyUserRemoveCreate, options),
        useContractCreate: (project?: string, options?: Omit<UseMutationOptions<Response<"contractCreate">, unknown, Parameters<Requests["contractCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"contractCreate">, unknown, Parameters<Requests["contractCreate"]>[0]>(payload => requests.contractCreate(payload, project), config?.useContractCreate, options),
        useContractUpdate: (id: string, project?: string, options?: Omit<UseMutationOptions<Response<"contractUpdate">, unknown, Parameters<Requests["contractUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"contractUpdate">, unknown, Parameters<Requests["contractUpdate"]>[0]>(payload => requests.contractUpdate(payload, id, project), config?.useContractUpdate, options),
        useContractPartialUpdate: (id: string, project?: string, options?: Omit<UseMutationOptions<Response<"contractPartialUpdate">, unknown, Parameters<Requests["contractPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"contractPartialUpdate">, unknown, Parameters<Requests["contractPartialUpdate"]>[0]>(payload => requests.contractPartialUpdate(payload, id, project), config?.useContractPartialUpdate, options),
        useContractDestroy: (id: string, project?: string, options?: Omit<UseMutationOptions<Response<"contractDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"contractDestroy">, unknown, unknown>(() => requests.contractDestroy(id, project), config?.useContractDestroy, options),
        useConversationCreate: (contract?: string, options?: Omit<UseMutationOptions<Response<"conversationCreate">, unknown, Parameters<Requests["conversationCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"conversationCreate">, unknown, Parameters<Requests["conversationCreate"]>[0]>(payload => requests.conversationCreate(payload, contract), config?.useConversationCreate, options),
        useConversationUpdate: (id: string, contract?: string, options?: Omit<UseMutationOptions<Response<"conversationUpdate">, unknown, Parameters<Requests["conversationUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"conversationUpdate">, unknown, Parameters<Requests["conversationUpdate"]>[0]>(payload => requests.conversationUpdate(payload, id, contract), config?.useConversationUpdate, options),
        useConversationPartialUpdate: (id: string, contract?: string, options?: Omit<UseMutationOptions<Response<"conversationPartialUpdate">, unknown, Parameters<Requests["conversationPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"conversationPartialUpdate">, unknown, Parameters<Requests["conversationPartialUpdate"]>[0]>(payload => requests.conversationPartialUpdate(payload, id, contract), config?.useConversationPartialUpdate, options),
        useConversationDestroy: (id: string, contract?: string, options?: Omit<UseMutationOptions<Response<"conversationDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"conversationDestroy">, unknown, unknown>(() => requests.conversationDestroy(id, contract), config?.useConversationDestroy, options),
        useEmailTemplateCreate: (options?: Omit<UseMutationOptions<Response<"emailTemplateCreate">, unknown, Parameters<Requests["emailTemplateCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"emailTemplateCreate">, unknown, Parameters<Requests["emailTemplateCreate"]>[0]>(payload => requests.emailTemplateCreate(payload), config?.useEmailTemplateCreate, options),
        useEmailTemplateUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"emailTemplateUpdate">, unknown, Parameters<Requests["emailTemplateUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"emailTemplateUpdate">, unknown, Parameters<Requests["emailTemplateUpdate"]>[0]>(payload => requests.emailTemplateUpdate(payload, id), config?.useEmailTemplateUpdate, options),
        useEmailTemplatePartialUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"emailTemplatePartialUpdate">, unknown, Parameters<Requests["emailTemplatePartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"emailTemplatePartialUpdate">, unknown, Parameters<Requests["emailTemplatePartialUpdate"]>[0]>(payload => requests.emailTemplatePartialUpdate(payload, id), config?.useEmailTemplatePartialUpdate, options),
        useEmailTemplateDestroy: (id: string, options?: Omit<UseMutationOptions<Response<"emailTemplateDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"emailTemplateDestroy">, unknown, unknown>(() => requests.emailTemplateDestroy(id), config?.useEmailTemplateDestroy, options),
        useInvitationCreate: (options?: Omit<UseMutationOptions<Response<"invitationCreate">, unknown, Parameters<Requests["invitationCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"invitationCreate">, unknown, Parameters<Requests["invitationCreate"]>[0]>(payload => requests.invitationCreate(payload), config?.useInvitationCreate, options),
        useInvitationAcceptExistingUserCreate: (options?: Omit<UseMutationOptions<Response<"invitationAcceptExistingUserCreate">, unknown, Parameters<Requests["invitationAcceptExistingUserCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"invitationAcceptExistingUserCreate">, unknown, Parameters<Requests["invitationAcceptExistingUserCreate"]>[0]>(payload => requests.invitationAcceptExistingUserCreate(payload), config?.useInvitationAcceptExistingUserCreate, options),
        useInvitationAcceptNewUserCreate: (options?: Omit<UseMutationOptions<Response<"invitationAcceptNewUserCreate">, unknown, Parameters<Requests["invitationAcceptNewUserCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"invitationAcceptNewUserCreate">, unknown, Parameters<Requests["invitationAcceptNewUserCreate"]>[0]>(payload => requests.invitationAcceptNewUserCreate(payload), config?.useInvitationAcceptNewUserCreate, options),
        useInvitationValidateCreate: (options?: Omit<UseMutationOptions<Response<"invitationValidateCreate">, unknown, Parameters<Requests["invitationValidateCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"invitationValidateCreate">, unknown, Parameters<Requests["invitationValidateCreate"]>[0]>(payload => requests.invitationValidateCreate(payload), config?.useInvitationValidateCreate, options),
        useMessageCreate: (options?: Omit<UseMutationOptions<Response<"messageCreate">, unknown, Parameters<Requests["messageCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"messageCreate">, unknown, Parameters<Requests["messageCreate"]>[0]>(payload => requests.messageCreate(payload), config?.useMessageCreate, options),
        useMessageUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"messageUpdate">, unknown, Parameters<Requests["messageUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"messageUpdate">, unknown, Parameters<Requests["messageUpdate"]>[0]>(payload => requests.messageUpdate(payload, id), config?.useMessageUpdate, options),
        useMessagePartialUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"messagePartialUpdate">, unknown, Parameters<Requests["messagePartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"messagePartialUpdate">, unknown, Parameters<Requests["messagePartialUpdate"]>[0]>(payload => requests.messagePartialUpdate(payload, id), config?.useMessagePartialUpdate, options),
        useMessageDestroy: (id: string, options?: Omit<UseMutationOptions<Response<"messageDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"messageDestroy">, unknown, unknown>(() => requests.messageDestroy(id), config?.useMessageDestroy, options),
        usePasswordResetCreate: (options?: Omit<UseMutationOptions<Response<"passwordResetCreate">, unknown, Parameters<Requests["passwordResetCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"passwordResetCreate">, unknown, Parameters<Requests["passwordResetCreate"]>[0]>(payload => requests.passwordResetCreate(payload), config?.usePasswordResetCreate, options),
        usePasswordResetConfirmCreate: (options?: Omit<UseMutationOptions<Response<"passwordResetConfirmCreate">, unknown, Parameters<Requests["passwordResetConfirmCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"passwordResetConfirmCreate">, unknown, Parameters<Requests["passwordResetConfirmCreate"]>[0]>(payload => requests.passwordResetConfirmCreate(payload), config?.usePasswordResetConfirmCreate, options),
        usePasswordResetValidateTokenCreate: (options?: Omit<UseMutationOptions<Response<"passwordResetValidateTokenCreate">, unknown, Parameters<Requests["passwordResetValidateTokenCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"passwordResetValidateTokenCreate">, unknown, Parameters<Requests["passwordResetValidateTokenCreate"]>[0]>(payload => requests.passwordResetValidateTokenCreate(payload), config?.usePasswordResetValidateTokenCreate, options),
        useProjectCreate: (options?: Omit<UseMutationOptions<Response<"projectCreate">, unknown, Parameters<Requests["projectCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"projectCreate">, unknown, Parameters<Requests["projectCreate"]>[0]>(payload => requests.projectCreate(payload), config?.useProjectCreate, options),
        useProjectUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"projectUpdate">, unknown, Parameters<Requests["projectUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"projectUpdate">, unknown, Parameters<Requests["projectUpdate"]>[0]>(payload => requests.projectUpdate(payload, id), config?.useProjectUpdate, options),
        useProjectPartialUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"projectPartialUpdate">, unknown, Parameters<Requests["projectPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"projectPartialUpdate">, unknown, Parameters<Requests["projectPartialUpdate"]>[0]>(payload => requests.projectPartialUpdate(payload, id), config?.useProjectPartialUpdate, options),
        useProjectDestroy: (id: string, options?: Omit<UseMutationOptions<Response<"projectDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"projectDestroy">, unknown, unknown>(() => requests.projectDestroy(id), config?.useProjectDestroy, options),
        useProposalCreate: (project?: string, options?: Omit<UseMutationOptions<Response<"proposalCreate">, unknown, Parameters<Requests["proposalCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"proposalCreate">, unknown, Parameters<Requests["proposalCreate"]>[0]>(payload => requests.proposalCreate(payload, project), config?.useProposalCreate, options),
        useProposalUpdate: (id: string, project?: string, options?: Omit<UseMutationOptions<Response<"proposalUpdate">, unknown, Parameters<Requests["proposalUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"proposalUpdate">, unknown, Parameters<Requests["proposalUpdate"]>[0]>(payload => requests.proposalUpdate(payload, id, project), config?.useProposalUpdate, options),
        useProposalPartialUpdate: (id: string, project?: string, options?: Omit<UseMutationOptions<Response<"proposalPartialUpdate">, unknown, Parameters<Requests["proposalPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"proposalPartialUpdate">, unknown, Parameters<Requests["proposalPartialUpdate"]>[0]>(payload => requests.proposalPartialUpdate(payload, id, project), config?.useProposalPartialUpdate, options),
        useProposalDestroy: (id: string, project?: string, options?: Omit<UseMutationOptions<Response<"proposalDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"proposalDestroy">, unknown, unknown>(() => requests.proposalDestroy(id, project), config?.useProposalDestroy, options),
        useResearcherCreate: (options?: Omit<UseMutationOptions<Response<"researcherCreate">, unknown, Parameters<Requests["researcherCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"researcherCreate">, unknown, Parameters<Requests["researcherCreate"]>[0]>(payload => requests.researcherCreate(payload), config?.useResearcherCreate, options),
        useResearcherUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"researcherUpdate">, unknown, Parameters<Requests["researcherUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"researcherUpdate">, unknown, Parameters<Requests["researcherUpdate"]>[0]>(payload => requests.researcherUpdate(payload, id), config?.useResearcherUpdate, options),
        useResearcherPartialUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"researcherPartialUpdate">, unknown, Parameters<Requests["researcherPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"researcherPartialUpdate">, unknown, Parameters<Requests["researcherPartialUpdate"]>[0]>(payload => requests.researcherPartialUpdate(payload, id), config?.useResearcherPartialUpdate, options),
        useResearcherDestroy: (id: string, options?: Omit<UseMutationOptions<Response<"researcherDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"researcherDestroy">, unknown, unknown>(() => requests.researcherDestroy(id), config?.useResearcherDestroy, options),
        useRiskCreate: (project?: string, title?: string, options?: Omit<UseMutationOptions<Response<"riskCreate">, unknown, Parameters<Requests["riskCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskCreate">, unknown, Parameters<Requests["riskCreate"]>[0]>(payload => requests.riskCreate(payload, project, title), config?.useRiskCreate, options),
        useRiskUpdate: (id: string, project?: string, title?: string, options?: Omit<UseMutationOptions<Response<"riskUpdate">, unknown, Parameters<Requests["riskUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskUpdate">, unknown, Parameters<Requests["riskUpdate"]>[0]>(payload => requests.riskUpdate(payload, id, project, title), config?.useRiskUpdate, options),
        useRiskPartialUpdate: (id: string, project?: string, title?: string, options?: Omit<UseMutationOptions<Response<"riskPartialUpdate">, unknown, Parameters<Requests["riskPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskPartialUpdate">, unknown, Parameters<Requests["riskPartialUpdate"]>[0]>(payload => requests.riskPartialUpdate(payload, id, project, title), config?.useRiskPartialUpdate, options),
        useRiskDestroy: (id: string, project?: string, title?: string, options?: Omit<UseMutationOptions<Response<"riskDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"riskDestroy">, unknown, unknown>(() => requests.riskDestroy(id, project, title), config?.useRiskDestroy, options),
        useRiskLinkCreate: (id: string, options?: Omit<UseMutationOptions<Response<"riskLinkCreate">, unknown, Parameters<Requests["riskLinkCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskLinkCreate">, unknown, Parameters<Requests["riskLinkCreate"]>[0]>(payload => requests.riskLinkCreate(payload, id), config?.useRiskLinkCreate, options),
        useRiskUnlinkCreate: (id: string, options?: Omit<UseMutationOptions<Response<"riskUnlinkCreate">, unknown, Parameters<Requests["riskUnlinkCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskUnlinkCreate">, unknown, Parameters<Requests["riskUnlinkCreate"]>[0]>(payload => requests.riskUnlinkCreate(payload, id), config?.useRiskUnlinkCreate, options),
        useRiskTagCreate: (options?: Omit<UseMutationOptions<Response<"riskTagCreate">, unknown, Parameters<Requests["riskTagCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskTagCreate">, unknown, Parameters<Requests["riskTagCreate"]>[0]>(payload => requests.riskTagCreate(payload), config?.useRiskTagCreate, options),
        useRiskTagUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"riskTagUpdate">, unknown, Parameters<Requests["riskTagUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskTagUpdate">, unknown, Parameters<Requests["riskTagUpdate"]>[0]>(payload => requests.riskTagUpdate(payload, id), config?.useRiskTagUpdate, options),
        useRiskTagPartialUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"riskTagPartialUpdate">, unknown, Parameters<Requests["riskTagPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"riskTagPartialUpdate">, unknown, Parameters<Requests["riskTagPartialUpdate"]>[0]>(payload => requests.riskTagPartialUpdate(payload, id), config?.useRiskTagPartialUpdate, options),
        useRiskTagDestroy: (id: string, options?: Omit<UseMutationOptions<Response<"riskTagDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"riskTagDestroy">, unknown, unknown>(() => requests.riskTagDestroy(id), config?.useRiskTagDestroy, options),
        useScanCreate: (options?: Omit<UseMutationOptions<Response<"scanCreate">, unknown, Parameters<Requests["scanCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"scanCreate">, unknown, Parameters<Requests["scanCreate"]>[0]>(payload => requests.scanCreate(payload), config?.useScanCreate, options),
        useScanUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"scanUpdate">, unknown, Parameters<Requests["scanUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"scanUpdate">, unknown, Parameters<Requests["scanUpdate"]>[0]>(payload => requests.scanUpdate(payload, id), config?.useScanUpdate, options),
        useScanPartialUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"scanPartialUpdate">, unknown, Parameters<Requests["scanPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"scanPartialUpdate">, unknown, Parameters<Requests["scanPartialUpdate"]>[0]>(payload => requests.scanPartialUpdate(payload, id), config?.useScanPartialUpdate, options),
        useScanDestroy: (id: string, options?: Omit<UseMutationOptions<Response<"scanDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"scanDestroy">, unknown, unknown>(() => requests.scanDestroy(id), config?.useScanDestroy, options),
        useSkillCreate: (options?: Omit<UseMutationOptions<Response<"skillCreate">, unknown, Parameters<Requests["skillCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"skillCreate">, unknown, Parameters<Requests["skillCreate"]>[0]>(payload => requests.skillCreate(payload), config?.useSkillCreate, options),
        useSkillUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"skillUpdate">, unknown, Parameters<Requests["skillUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"skillUpdate">, unknown, Parameters<Requests["skillUpdate"]>[0]>(payload => requests.skillUpdate(payload, id), config?.useSkillUpdate, options),
        useSkillPartialUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"skillPartialUpdate">, unknown, Parameters<Requests["skillPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"skillPartialUpdate">, unknown, Parameters<Requests["skillPartialUpdate"]>[0]>(payload => requests.skillPartialUpdate(payload, id), config?.useSkillPartialUpdate, options),
        useSkillDestroy: (id: string, options?: Omit<UseMutationOptions<Response<"skillDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"skillDestroy">, unknown, unknown>(() => requests.skillDestroy(id), config?.useSkillDestroy, options),
        useTagCreate: (options?: Omit<UseMutationOptions<Response<"tagCreate">, unknown, Parameters<Requests["tagCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"tagCreate">, unknown, Parameters<Requests["tagCreate"]>[0]>(payload => requests.tagCreate(payload), config?.useTagCreate, options),
        useTagUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"tagUpdate">, unknown, Parameters<Requests["tagUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"tagUpdate">, unknown, Parameters<Requests["tagUpdate"]>[0]>(payload => requests.tagUpdate(payload, id), config?.useTagUpdate, options),
        useTagPartialUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"tagPartialUpdate">, unknown, Parameters<Requests["tagPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"tagPartialUpdate">, unknown, Parameters<Requests["tagPartialUpdate"]>[0]>(payload => requests.tagPartialUpdate(payload, id), config?.useTagPartialUpdate, options),
        useTagDestroy: (id: string, options?: Omit<UseMutationOptions<Response<"tagDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"tagDestroy">, unknown, unknown>(() => requests.tagDestroy(id), config?.useTagDestroy, options),
        useUnlockRequestCreate: (options?: Omit<UseMutationOptions<Response<"unlockRequestCreate">, unknown, Parameters<Requests["unlockRequestCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"unlockRequestCreate">, unknown, Parameters<Requests["unlockRequestCreate"]>[0]>(payload => requests.unlockRequestCreate(payload), config?.useUnlockRequestCreate, options),
        useUnlockRequestUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"unlockRequestUpdate">, unknown, Parameters<Requests["unlockRequestUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"unlockRequestUpdate">, unknown, Parameters<Requests["unlockRequestUpdate"]>[0]>(payload => requests.unlockRequestUpdate(payload, id), config?.useUnlockRequestUpdate, options),
        useUnlockRequestPartialUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"unlockRequestPartialUpdate">, unknown, Parameters<Requests["unlockRequestPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"unlockRequestPartialUpdate">, unknown, Parameters<Requests["unlockRequestPartialUpdate"]>[0]>(payload => requests.unlockRequestPartialUpdate(payload, id), config?.useUnlockRequestPartialUpdate, options),
        useUnlockRequestDestroy: (id: string, options?: Omit<UseMutationOptions<Response<"unlockRequestDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"unlockRequestDestroy">, unknown, unknown>(() => requests.unlockRequestDestroy(id), config?.useUnlockRequestDestroy, options),
        useUserCreate: (options?: Omit<UseMutationOptions<Response<"userCreate">, unknown, Parameters<Requests["userCreate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"userCreate">, unknown, Parameters<Requests["userCreate"]>[0]>(payload => requests.userCreate(payload), config?.useUserCreate, options),
        useUserUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"userUpdate">, unknown, Parameters<Requests["userUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"userUpdate">, unknown, Parameters<Requests["userUpdate"]>[0]>(payload => requests.userUpdate(payload, id), config?.useUserUpdate, options),
        useUserPartialUpdate: (id: string, options?: Omit<UseMutationOptions<Response<"userPartialUpdate">, unknown, Parameters<Requests["userPartialUpdate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"userPartialUpdate">, unknown, Parameters<Requests["userPartialUpdate"]>[0]>(payload => requests.userPartialUpdate(payload, id), config?.useUserPartialUpdate, options),
        useUserDestroy: (id: string, options?: Omit<UseMutationOptions<Response<"userDestroy">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"userDestroy">, unknown, unknown>(() => requests.userDestroy(id), config?.useUserDestroy, options)
    } as const;
}
