import { useEffect, useState } from 'react';

import { IconSwitchVertical } from '@tabler/icons-react';
import { Box, Button, Group, Pagination, Stack, Title } from '@mantine/core';
import { CreateComment } from '@/components/Risks/RiskDetails/CreateComment';
import { CommentCard } from './RiskCommentCard';

import { apiClient, pagination, queryClient } from '@/globals';
import { getTotalPages } from '@/utils';
import { queryKeys } from '@/api-client';
import { useUserMe } from '@/hooks/use-user-me';

export const RiskCommentList = ({
  riskId,
  setCommentCount,
}: {
  riskId: string;
  setCommentCount: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [ordering, setOrdering] = useState('-created');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // get risk from API
  const riskResult = apiClient.queries.useRiskRetrieve(riskId, undefined);

  // get comment list from API
  const commentsResult = apiClient.queries.useCommentList(ordering, page, riskId);

  // get user data from API
  const userMe = useUserMe();

  const refreshCommentList = async () => {
    await queryClient.invalidateQueries({
      queryKey: queryKeys.commentList(ordering, page, riskId),
    });
  };

  useEffect(() => {
    const _totalPages = getTotalPages(commentsResult.data?.count, pagination.defaultPageSize);
    setTotalPages(_totalPages);
    setCommentCount(commentsResult.data?.count ?? 0);
  }, [commentsResult.data?.count, setCommentCount]);

  // wait for risks to load
  if (riskResult.isError) {
    return <div>Failed to load risk</div>;
  }
  if (riskResult.isLoading) {
    return <div>Loading risk...</div>;
  }

  // wait for comments to load
  if (commentsResult.isError) {
    return <div>Failed to load comments</div>;
  }
  if (commentsResult.isLoading) {
    return <div>Loading comments...</div>;
  }

  const userEmailSha256 = userMe.email_sha256 || 'ABS123';

  return (
    <Box py="md">
      <Stack gap="md">
        <Stack gap="md">
          <Group justify="space-between" align="center">
            <Group gap="xs">
              <Title order={2} size="h6">
                Comments
              </Title>
            </Group>
            <Button
              variant="subtle"
              leftSection={<IconSwitchVertical stroke={1.5} size="12px" />}
              color="dark"
              mt={0}
              onClick={() => {
                setOrdering(ordering === 'created' ? '-created' : 'created');
                setPage(1);
              }}
            >
              {ordering === 'created' ? 'Newest First' : 'Oldest First'}
            </Button>
          </Group>

          <CreateComment
            userEmailSha256={userEmailSha256}
            riskId={riskId}
            refreshCommentList={refreshCommentList}
          />
          <Stack>
            {/* display list of comments */}
            {commentsResult.data?.results?.map((c) => (
              <CommentCard
                key={c.id}
                comment={c}
                isAuthor={userMe.id === c.author.id}
                refreshCommentList={refreshCommentList}
              />
            ))}
            {/* show pagination */}
            <Pagination value={page} onChange={setPage} total={totalPages} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
