import { useContext } from 'react';

import { UserContext } from '@/contexts/UserContext';

export const useUserMe = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserMe must be used within a UserProvider');
  }
  return context.user;
};
