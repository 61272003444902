import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import NotificationsProvider from '@/Providers/NotificationsProvider';
import { UserContext } from '@/contexts/UserContext';
import { paths } from '@/configuration';
import { apiClient } from '@/globals';

import type { UserTypeEnum } from '@/api-client';

interface UserContextProviderProps {
  children: React.ReactNode;
}

export const AuthenticatedProvider = ({ children }: UserContextProviderProps) => {
  const navigate = useNavigate();

  const {
    data: user,
    isLoading,
    isSuccess,
    isError,
  } = apiClient.queries.useUserMeRetrieve({
    retry: false,
  });

  useEffect(() => {
    if (isSuccess && window.location.pathname === '/') {
      navigate(paths.appHome[user.user_type as UserTypeEnum]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isSuccess, navigate, window.location.pathname]);

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (isError || !user) {
    return null;
  }

  return (
    <UserContext.Provider value={{ user }}>
      {children}
      <NotificationsProvider />
    </UserContext.Provider>
  );
};
