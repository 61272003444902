import { Badge, Grid, Group, NumberFormatter, Space, Text, Title } from '@mantine/core';
import moment from 'moment';
import { ReactNode } from 'react';
import Gravatar from '@/components/Common/Gravatar/Gravatar';
import { Proposal } from '@/api-client';

const TextHeader = ({ content }: { content: ReactNode }) => (
  <Text fz="sm" tt="uppercase" fw={700} c="gray.7">
    {content}
  </Text>
);

const TextBody = ({ content }: { content: ReactNode }) => (
  <Text component="span" fz="sm">
    {content}
  </Text>
);

const ProposalHeader = ({ proposal }: { proposal: Proposal }) => (
  <Grid>
    <Grid.Col span={{ base: 6, xs: 6 }}>
      <Group />
      <TextHeader content="proposal name" />
      <Title>{proposal.project.name}</Title>
    </Grid.Col>
    <Grid.Col span={{ base: 6, xs: 6 }}>
      <TextHeader content="researcher" />
      <Group>
        <Gravatar emailHash={proposal.researcher.email_sha256} size={50} />
        <Title>{proposal.researcher.name}</Title>
      </Group>
    </Grid.Col>
    <Space h="sm" />
    <Grid.Col span={{ base: 2, xs: 2 }}>
      <TextHeader content="status" />
      <TextBody
        content={
          <Badge color={proposal.active ? 'green' : 'red'} variant="light">
            {proposal.active ? 'active' : 'inactive'}
          </Badge>
        }
      />
    </Grid.Col>
    <Grid.Col span={{ base: 2, xs: 2 }}>
      <TextHeader content="payment option" />
      <TextBody
        content={
          <Badge color="blue" variant="light">
            {proposal.payment_option}
          </Badge>
        }
      />
    </Grid.Col>
    <Grid.Col span={{ base: 2, xs: 2 }}>
      <TextHeader content="rate" />
      <TextBody content={<NumberFormatter prefix="$" value={proposal.rate} thousandSeparator />} />
    </Grid.Col>
    <Grid.Col span={{ base: 2, xs: 2 }}>
      <TextHeader content="created" />
      <TextBody content={moment(proposal.created).format('L')} />
    </Grid.Col>
    <Grid.Col span={{ base: 2, xs: 2 }}>
      <TextHeader content="updated" />
      <TextBody content={moment(proposal.updated).format('L')} />
    </Grid.Col>
  </Grid>
);

export default ProposalHeader;
