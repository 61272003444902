import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FC } from 'react';

interface DateDisplayProps {
  date: string;
  showDate?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
dayjs.extend(relativeTime);

const DateDisplay: FC<DateDisplayProps> = ({ date, showDate }: DateDisplayProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  const formattedDate: string = showDate
    ? dayjs(date).format('MMM D, YYYY')
    : dayjs(date).fromNow();

  return <>{formattedDate}</>;
};

// DateDisplay.defaultProps = {
//     date: dayjs().toISOString(),
// };

export default DateDisplay;
