import React from 'react';
import { useDisclosure } from '@mantine/hooks';

import { Group, Badge, Box, Modal, Title, Text } from '@mantine/core';
import { MarkdownViewer } from '@/components/Common/Markdown/MarkdownViewer';

import { apiClient } from '@/globals';

import classes from './ProjectScopeModal.module.css';

interface ProjectScopeModalProps {
  projectId: string;
  children: JSX.Element;
}

export const ProjectScopeModal = ({ projectId, children }: ProjectScopeModalProps) => {
  const [opened, { open, close }] = useDisclosure(false);

  const { data, isSuccess } = apiClient.queries.useProjectRetrieve(projectId);

  if (!isSuccess) {
    return null;
  }

  return (
    <>
      {React.cloneElement(children, { onClick: open })}

      <Modal
        title={
          <Text span textfor="h1">
            {data.name}
          </Text>
        }
        opened={opened}
        onClose={close}
        classNames={{
          content: classes.modal_content,
        }}
      >
        <Group my="sm">
          {data.skills.length > 0 ? (
            data.skills.map((skill) => (
              <Badge key={skill.id} color="blue" variant="light">
                {skill.name}
              </Badge>
            ))
          ) : (
            <Badge key="other" color="blue" variant="light">
              other
            </Badge>
          )}
        </Group>

        <Box>
          <Title order={2} textfor="h4" mb="xs">
            Description
          </Title>
          <MarkdownViewer content={data.description} />
        </Box>
      </Modal>
    </>
  );
};
