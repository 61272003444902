import { useLoaderData } from 'react-router-dom';

import { Badge, Box, Container, Divider, Grid, Group, Stack, Tabs } from '@mantine/core';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { RiskDetails } from '@/components/Risks/RiskDetails/RiskDetails';
import { RiskHeader } from '@/components/Risks/RiskHeader/RiskHeader';
import BreadcrumbNavigation from '@/components/Common/BreadcrumbNavigation/BreadcrumbNavigation';

import { apiClient } from '@/globals';
import { Project, Risk } from '@/api-client';
import RiskHistoryList from '@/components/Risks/RiskHistory/RiskHistoryList';
import { useTrackPageView } from '@/hooks/use-track-page-view';
import { CompanyContextProvider } from '@/contexts/CompanyContext';
import { RiskCommentList } from '@/components/Risks/RiskDetails/RiskCommentList';
import RiskSidebar from '@/components/Risks/RiskDetails/RiskSidebar';
import classes from './RiskDetail.module.css';

interface LoaderProps {
  risk: Risk;
  project: Project;
}

export function RiskDetailPage() {
  const loaderData = useLoaderData() as LoaderProps;
  const { project } = loaderData;
  const [commentCount, setCommentCount] = useState(0);
  const riskQuery = apiClient.queries.useRiskRetrieve(loaderData.risk.id, undefined, undefined, {
    initialData: loaderData.risk,
  });

  useTrackPageView('risk_detail');

  const [isStickyHeaderVisible, setIsStickyHeaderVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerPoint = 200;

      if (scrollPosition > triggerPoint) {
        setIsStickyHeaderVisible(true);
      } else {
        setIsStickyHeaderVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (riskQuery.isLoading) {
    return (
      <Container>
        <Box>Loading...</Box>
      </Container>
    );
  }

  if (riskQuery.isError || !riskQuery.isSuccess) {
    return (
      <Container>
        <Box>Failed to load risk</Box>
      </Container>
    );
  }

  const risk = riskQuery.data;

  return (
    <CompanyContextProvider companyId={project.company.id}>
      <Box
        className={clsx(
          classes.stickyHeader,
          isStickyHeaderVisible ? classes.visible : classes.hidden
        )}
      >
        <Container>
          <RiskHeader risk={risk} stickyNav />
        </Container>
        <Divider />
      </Box>
      <Container>
        <Stack gap="xs" mt="xl">
          <Box>
            <BreadcrumbNavigation
              breadcrumbs={[
                { label: 'Project', href: '/projects/' },
                { label: project.name, href: `/project/${project.id}` },
                { label: risk.title, href: `/risk/${risk.id}` },
              ]}
            />
          </Box>

          <Grid align="start" px={{ md: 'sm' }} pt="sm" gutter={32}>
            <Grid.Col
              span={{
                xs: 12,
                md: 8,
              }}
            >
              <RiskHeader risk={risk} />
              <Box px={{ xs: 0, md: 'sm' }} py="sm">
                <RiskDetails risk={risk} />
              </Box>
              <Tabs defaultValue="comments">
                <Tabs.List>
                  <Tabs.Tab value="comments">
                    <Group>
                      Comments{' '}
                      {commentCount && (
                        <Badge size="sm" color="gray" variant="outline">
                          {commentCount}
                        </Badge>
                      )}
                    </Group>
                  </Tabs.Tab>
                  <Tabs.Tab value="history">History</Tabs.Tab>
                  <Tabs.Tab value="details" className={classes.hideDesktop}>
                    Details
                  </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="comments">
                  <RiskCommentList setCommentCount={setCommentCount} riskId={risk.id} />
                </Tabs.Panel>
                <Tabs.Panel value="history">
                  <RiskHistoryList riskId={risk.id} />
                </Tabs.Panel>
                <Tabs.Panel value="details" className={classes.hideDesktop}>
                  <Box py="md">
                    <RiskSidebar project={project} risk={risk} />
                  </Box>
                </Tabs.Panel>
              </Tabs>
            </Grid.Col>
            <Grid.Col
              span={{
                xs: 12,
                md: 4,
              }}
              className={classes.hideMobile}
            >
              <RiskSidebar project={project} risk={risk} />
            </Grid.Col>
          </Grid>
        </Stack>
      </Container>
    </CompanyContextProvider>
  );
}
