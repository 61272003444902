import { FC, useEffect, useRef, useState } from 'react';
import Realistic from 'react-canvas-confetti/dist/presets/realistic';
import { TConductorInstance } from 'react-canvas-confetti/dist/types';
import { confettiStore } from '@/store/confetti-store';
import classes from './confetti.module.css';

const Confetti: FC = () => {
  const { shoot, toggleShoot } = confettiStore();
  const [zIndex, setZIndex] = useState<number>(-90);
  const firstController = useRef<TConductorInstance>();
  const firstInitHandler = ({ conductor }: { conductor: TConductorInstance }) => {
    firstController.current = conductor;
  };
  const secondController = useRef<TConductorInstance>();
  const secondInitHandler = ({ conductor }: { conductor: TConductorInstance }) => {
    secondController.current = conductor;
  };
  useEffect(() => {
    if (shoot) {
      setZIndex(90);
      firstController.current?.shoot();
      secondController.current?.shoot();
      toggleShoot(false);
      setTimeout(() => {
        setZIndex(-90);
      }, 2500);
    }
  }, [shoot, toggleShoot]);

  return (
    <>
      <Realistic style={{ zIndex }} className={classes.first} onInit={firstInitHandler} />
      <Realistic style={{ zIndex }} className={classes.second} onInit={secondInitHandler} />
    </>
  );
};

export default Confetti;
