import { Container, Space } from '@mantine/core';
import { useLoaderData } from 'react-router-dom';

import ContractHeader from '@/components/Contracts/ContractHeader/ContractHeader';
import { ContractDetail } from '@/components/Contracts/ContractDetail/ContractDetail';

import { useTrackPageView } from '@/hooks/use-track-page-view';
import type { Contract, Conversation, User } from '@/api-client';

interface LoaderProps {
  contract: Contract;
  conversation: Conversation;
  userMe: User;
}

export function ContractDetailPage() {
  const { contract } = useLoaderData() as LoaderProps;

  useTrackPageView('contract_detail');

  return (
    <Container>
      <ContractHeader contract={contract} />
      <Space />
      <ContractDetail contract={contract} />
    </Container>
  );
}
