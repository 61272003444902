import { apiClient } from '@/globals';
import { RiskTagBadge } from './RiskTagBadge';

interface RiskTagsViewProps {
  riskId: string;
}

export const RiskTagsView = ({ riskId }: RiskTagsViewProps) => {
  const selectedTagsQuery = apiClient.queries.useRiskTagList(undefined, riskId);

  return selectedTagsQuery.data?.results?.map((tag) => (
    <RiskTagBadge key={tag.id} tag={tag.tag_details} />
  ));
};
