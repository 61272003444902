import { filesize } from 'filesize';

import { Alert, Card, Group, Loader, Stack, Text } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { getIcon } from '@/components/Attachments/utils';

import { apiClient } from '@/globals';

interface AttachmentContentProps {
  attachmentId: string;
}

const AttachmentContent = ({ attachmentId }: AttachmentContentProps) => {
  const ret = apiClient.queries.useAttachmentRetrieve(attachmentId);

  if (ret.isLoading) {
    return <Loader color="blue" />;
  }

  if (ret.isError) {
    const icon = <IconInfoCircle />;
    return (
      <Alert variant="light" color="red" title="Alert title" icon={icon}>
        An error occurred while retrieving this attachment.
      </Alert>
    );
  }

  const size = filesize(ret.data?.size ?? 0, { standard: 'jedec' });

  return (
    <Card bg="yellow" m={10} shadow="sm" component="a" target="_blank" href={ret.data?.url}>
      <Stack>
        <Group>
          {getIcon(ret.data?.content_type ?? '')}
          <Text textfor="attachment">
            {ret.data?.filename} ({size})
          </Text>
        </Group>
      </Stack>
    </Card>
  );
};

export default AttachmentContent;
