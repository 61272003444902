import * as z from 'zod';
import { useForm, zodResolver } from '@mantine/form';

import { Button, Group, Stack, TextInput, ThemeIcon } from '@mantine/core';
import { IconLock } from '@tabler/icons-react';

export type ResetPasswordValues = z.infer<typeof resetPasswordSchema>;

interface ResetPasswordFormProps {
  handleSubmit: (values: ResetPasswordValues) => void;
  isPending: boolean;
}

const resetPasswordSchema = z
  .object({
    newPassword: z.string().min(8, 'Password must be at least 8 characters'),
    confirmPassword: z.string().min(8, 'Password must be at least 8 characters'),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  });

export const ResetPasswordForm = ({ handleSubmit, isPending }: ResetPasswordFormProps) => {
  const { onSubmit, isValid, getInputProps } = useForm<ResetPasswordValues>({
    validateInputOnChange: true,
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validate: zodResolver(resetPasswordSchema),
  });

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <Stack gap="sm">
        <TextInput
          type="password"
          label="New Password"
          placeholder="New Password"
          leftSection={
            <ThemeIcon>
              <IconLock strokeWidth="inherit" />
            </ThemeIcon>
          }
          {...getInputProps('newPassword')}
        />
        <TextInput
          type="password"
          label="Confirm Password"
          placeholder="Confirm Password"
          leftSection={
            <ThemeIcon>
              <IconLock strokeWidth="inherit" />
            </ThemeIcon>
          }
          {...getInputProps('confirmPassword')}
        />
      </Stack>

      <Group justify="flex-end" mt="md">
        <Button type="submit" disabled={!isValid()} loading={isPending}>
          Change my password
        </Button>
      </Group>
    </form>
  );
};
