import { useEffect } from 'react';
import { trackPageView } from '@/services/GoogleAnalytics';
import { paths } from '@/configuration';

type PageTitle =
  | 'projects'
  | 'project_details'
  | 'contracts'
  | 'contract_detail'
  | 'sign_in'
  | 'request_reset_password'
  | 'reset_password'
  | 'invitation'
  | 'proposals'
  | 'proposal_details'
  | 'researchers'
  | 'researcher_details'
  | 'risk_detail'
  | 'new_risk'
  | 'edit_risk'
  | 'settings'
  | 'error_page';

const PAGES_PAHTS: Record<PageTitle, string> = {
  projects: paths.projects,
  project_details: paths.project(':project_id'),
  contracts: paths.contracts,
  contract_detail: paths.contract(':contract_id'),
  sign_in: paths.signIn,
  request_reset_password: paths.requestResetPassword,
  reset_password: paths.resetPassword,
  invitation: paths.invitation,
  proposals: paths.proposals,
  proposal_details: paths.proposal(':proposal_id'),
  risk_detail: paths.risk(':risk_id'),
  new_risk: paths.newRisk(':project_id'),
  edit_risk: paths.editRisk(':risk_id'),
  researchers: paths.researchers,
  researcher_details: paths.researcher(':researcher_id'),
  settings: paths.settings.base,
  error_page: '*',
};

// formatPageName removes "_" and Capitalize the first letter
const formatPageName = (p: string) => p.replace('_', ' ').replace(/^\w/, (c) => c.toUpperCase());

export const useTrackPageView = (page: PageTitle) => {
  useEffect(() => {
    trackPageView(PAGES_PAHTS[page], formatPageName(page));
  }, [page]);
};
