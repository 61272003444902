import { useMantineColorScheme } from '@mantine/core';
import MDEditor from '@uiw/react-md-editor';

import './Markdown.css';

export const MarkdownViewer = ({ content }: { content: string }) => {
  const theme = useMantineColorScheme();
  return (
    <div data-color-mode={theme.colorScheme === 'dark' ? 'dark' : 'light'}>
      <MDEditor.Markdown source={content} />
    </div>
  );
};
