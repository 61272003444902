import {
  IconInfoCircle,
  IconFileTypePdf,
  IconPhoto,
  IconVideo,
  IconFileTypeTxt,
} from '@tabler/icons-react';

export const getIcon = (mimeType: string) => {
  const size = 30;

  if (mimeType === 'application/pdf') {
    return <IconFileTypePdf size={size} />;
  }

  if (mimeType.startsWith('image/')) {
    return <IconPhoto size={size} />;
  }

  if (mimeType.startsWith('video/')) {
    return <IconVideo size={size} />;
  }

  if (mimeType.startsWith('text/')) {
    return <IconFileTypeTxt size={size} />;
  }

  return <IconInfoCircle size={size} />;
};
