import { AttachmentDisplay } from './AttachmentDisplay';

export const AttachmentFile = ({
  file,
  onDelete,
}: {
  file: File;
  onDelete: (file: File) => void;
}) => {
  const fileSize = file.size;
  const filename = file.name;
  const fileType = file.type;

  return (
    <AttachmentDisplay
      onDelete={() => {
        onDelete(file);
      }}
      fileType={fileType}
      filename={filename}
      fileSize={fileSize}
    />
  );
};
