import { Badge, Group } from '@mantine/core';

import { EnvironmentEnum } from '@/api-client';

interface RiskEnvironmentProps {
  environment: EnvironmentEnum;
}

export const RiskEnvironmentsBadge = ({ environment }: RiskEnvironmentProps) => (
  <Group gap="xs">
    <Badge key={environment} variant="light" radius="sm" tt="capitalize">
      {environment}
    </Badge>
  </Group>
);
