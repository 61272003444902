import ReactGA from 'react-ga4';

const GOOGLE_ANALYTICS_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_ID as Maybe<string>;

export const initializeGA = () => {
  if (!GOOGLE_ANALYTICS_ID) {
    return;
  }

  ReactGA.initialize(GOOGLE_ANALYTICS_ID, { testMode: import.meta.env.DEV });
};

export const trackPageView = (path: string, title: string) => {
  ReactGA.send({ hitType: 'pageview', path, title });
};

export const trackGAEvent = (category: string, action: string, label: string, value: number) => {
  ReactGA.event({ category, label, action, value });
};
