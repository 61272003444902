import { AuthenticationTitle } from '@/components/Auth/AuthenticationTitle/AuthenticationTitle';
import { AuthLayout } from '@/layouts/AuthLayout/AuthLayout';

import { useTrackPageView } from '@/hooks/use-track-page-view';

export function SignInPage() {
  useTrackPageView('sign_in');

  return (
    <AuthLayout title="Welcome Back!">
      <AuthenticationTitle />
    </AuthLayout>
  );
}
