import { Image, Container, Title, Text, Button, SimpleGrid } from '@mantine/core';
import { useTrackPageView } from '@/hooks/use-track-page-view';

import image from './404.svg';
import classes from './404.module.css';

export function ErrorPage() {
  useTrackPageView('error_page');

  return (
    <Container className={classes.root}>
      <SimpleGrid spacing={{ base: 40, sm: 80 }} cols={{ base: 1, sm: 2 }}>
        <Image src={image} className={classes.mobileImage} />
        <div>
          <Title className={classes.title}>Something is not right...</Title>
          <Text c="dimmed" size="lg">
            The page you are trying to open does not exist. You may have mistyped the URL, or you
            could just be haxoring. We get it. Keep hunting and don&apos;t forget to discloze your
            findings.
          </Text>
          <Button
            variant="outline"
            size="md"
            mt="xl"
            className={classes.control}
            onClick={() => {
              window.history.back();
            }}
          >
            Get back to home page
          </Button>
        </div>
        <Image src={image} className={classes.desktopImage} />
      </SimpleGrid>
    </Container>
  );
}

export default ErrorPage;
