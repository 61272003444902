import { useLoaderData } from 'react-router-dom';

import { Container, Grid, Title } from '@mantine/core';
import ProposalList from '@/components/Proposals/ProposalList/ProposalList';

import { useTrackPageView } from '@/hooks/use-track-page-view';
import type { PaginatedProposalList } from '@/api-client';

interface LoaderProps {
  proposalList: PaginatedProposalList;
}

export function ProposalsPage() {
  const { proposalList } = useLoaderData() as LoaderProps;

  useTrackPageView('proposals');

  return (
    <Container>
      <Grid>
        <Grid.Col span={{ base: 12, xs: 12 }}>
          <Title order={3}>Proposals</Title>
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 12 }}>
          <ProposalList proposalList={proposalList} />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
