import { LoaderFunction } from 'react-router-dom';
import { queryClient } from '@/globals';
import { companyListQuery } from '@/queries.loader';

export const SettingsPageLoader: LoaderFunction = async () => {
  const companyList = await queryClient.fetchQuery({
    ...companyListQuery(1),
    staleTime: 1000 * 60,
  });

  return { companyList };
};
