import { LoaderFunctionArgs } from '@remix-run/router/utils';
import { json, LoaderFunction } from 'react-router-dom';
import { queryClient } from '@/globals';
import { contractListQuery, projectQuery, proposalListQuery, userMeQuery } from '@/queries.loader';

export const ProjectDetailLoader: LoaderFunction = async ({ params }: LoaderFunctionArgs) => {
  const { projectId } = params;
  if (projectId === undefined) {
    throw json({ message: 'projectId is required' }, { status: 401 });
  }

  const project = await queryClient.fetchQuery({
    ...projectQuery(projectId),
    staleTime: 1000 * 60,
  });

  const contractList = await queryClient.fetchQuery({
    ...contractListQuery(projectId),
    staleTime: 1000 * 60,
  });

  const proposalList = await queryClient.fetchQuery({
    ...proposalListQuery(projectId),
    staleTime: 1000 * 60,
  });

  const user = await queryClient.fetchQuery({
    ...userMeQuery(),
    staleTime: 1000 * 60,
  });

  return { project, contractList, proposalList, user };
};
