import { CategoryEnum, SeverityEnum } from '@/api-client';

export enum RISK_CATEGORY_LABEL_MAP {
  broken_access_control = 'Broken Access Control',
  cryptographic_failure = 'Cryptographic Failure',
  injection = 'Injection',
  insecure_design = 'Insecure Design',
  security_misconfiguration = 'Security Misconfiguration',
  vulnerable_and_outdated_components = 'Vulnerable & Outdated Components',
  identification_and_auth_failure = 'Identification and Auth Failures',
  data_integrity_failure = 'Data Integrity Failures',
  secure_logging_failure = 'Secure Logging Failures',
  server_side_request_forgery = 'Server-Side Request Forgery',
}

export type RiskCategoryType = keyof typeof RISK_CATEGORY_LABEL_MAP;

export const RISK_CATEGORIES: CategoryEnum[] = [
  'broken_access_control',
  'cryptographic_failure',
  'injection',
  'insecure_design',
  'security_misconfiguration',
  'vulnerable_and_outdated_components',
  'identification_and_auth_failure',
  'data_integrity_failure',
  'secure_logging_failure',
  'server_side_request_forgery',
];

export enum RISK_ENVIRONMENT_LABEL_MAP {
  web = 'Web',
  mobile = 'Mobile',
  api = 'API',
  network = 'Network',
}

export enum SEVERITY_LABEL_MAP {
  critical = 'Critical',
  high = 'High',
  medium = 'Medium',
  low = 'Low',
  informational = 'Informational',
}

export const SEVERITIES: SeverityEnum[] = ['critical', 'high', 'medium', 'low', 'informational'];
