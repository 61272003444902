import { useLoaderData } from 'react-router-dom';

import { Container, Divider, rem } from '@mantine/core';
import { ProjectHeader } from '@/components/Projects/ProjectHeader/ProjectHeader';
import RiskList from '@/components/Risks/RiskList/RiskList';
import { CompanyContextProvider } from '@/contexts/CompanyContext';

import { useTrackPageView } from '@/hooks/use-track-page-view';
import { paths } from '@/configuration';
import type { PaginatedContractList, Project, User, UserTypeEnum } from '@/api-client';

interface LoaderProps {
  project: Project;
  contractList: PaginatedContractList;
  user: User;
}

export function ProjectDetailPage() {
  const { project, contractList, user } = useLoaderData() as LoaderProps;

  useTrackPageView('project_details');

  if (contractList.results === undefined) {
    return <div>Failed to load contract results</div>;
  }

  // we are assuming that a researcher can only have 1 contract with a project
  const contract = contractList.results[0];

  const breadcrumbs = [
    { label: 'Project', href: paths.projects },
    { label: project.name, href: paths.project(project.id) },
  ];

  const Header = () => {
    switch (user.user_type as string) {
      case 'client':
        return (
          <ProjectHeader
            project={project}
            // contracts={contractList.results}
            userType={user.user_type as UserTypeEnum}
            breadcrumbs={breadcrumbs}
          />
        );
      case 'researcher':
        return (
          <ProjectHeader
            project={project}
            contract={contract}
            userType={user.user_type as UserTypeEnum}
            breadcrumbs={breadcrumbs}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <CompanyContextProvider companyId={project.company.id}>
      <Container>
        <Header />
        <Divider mb={rem(4)} />
        <RiskList project={project} />
      </Container>
    </CompanyContextProvider>
  );
}
