import { create } from 'zustand';

interface IConfetti {
  shoot: boolean;
  toggleShoot: (shoot: boolean) => void;
}

export const confettiStore = create<IConfetti>()((set) => ({
  shoot: false,
  toggleShoot: (shoot) => {
    set({ shoot });
  },
}));
