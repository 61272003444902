import { Badge, BadgeProps } from '@mantine/core';

import classes from './RiskStatusBadge.module.css';

import type { RiskStatusEnum } from '@/api-client';

const riskStatusOptions: Record<RiskStatusEnum, string> = {
  new: 'New',
  in_progress: 'In Progress',
  closed: 'Closed',
  rejected: 'Rejected',
};

interface RiskStatusBadgeProps {
  status: RiskStatusEnum;
  size?: BadgeProps['size'];
}

export const RiskStatusBadge = ({ status, size }: RiskStatusBadgeProps) => (
  <Badge radius="sm" className={classes[status]} size={size}>
    {riskStatusOptions[status]}
  </Badge>
);
