import { Card, Grid, Text, Title, Space } from '@mantine/core';

import { Contract } from '@/api-client';
import ProjectStats from '@/components/Projects/ProjectStats/ProjectStats';
import { ContractSummary } from './ContractSummary';

interface ContractDetailProps {
  contract: Contract;
}

export const ContractDetail = ({ contract }: ContractDetailProps) => (
  <Grid>
    <Grid.Col
      order={{
        md: 1,
        xs: 2,
      }}
      span={{
        xs: 12,
        md: 8,
      }}
    >
      <Card>
        <Title order={2} textfor="h2" mb="lg">
          Risk Stats
        </Title>

        {/* TODO: Change with contract stats */}
        <ProjectStats project={contract.project} />
      </Card>

      <Space h="md" />

      <Card>
        <Title order={2} textfor="h2" mb="lg">
          Description
        </Title>

        <Text lineClamp={3}>{contract.project.description}</Text>
      </Card>
    </Grid.Col>

    <Grid.Col
      order={{
        md: 2,
        xs: 1,
      }}
      span={{
        xs: 12,
        md: 4,
      }}
    >
      <Card>
        <Title order={2} textfor="h2" mb="lg">
          Summary
        </Title>

        <ContractSummary contract={contract} />
      </Card>
    </Grid.Col>
  </Grid>
);
