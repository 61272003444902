import { trackGAEvent } from './GoogleAnalytics';

const metricsToTrack = ['navigation'];

const callback: PerformanceObserverCallback = (list) => {
  // Process the list of performance entries
  list.getEntries().forEach((entry) => {
    if (entry.entryType === 'navigation') {
      const { requestStart, responseStart, responseEnd } = entry as PerformanceNavigationTiming;

      // Track the performance metric using trackGAEvent
      trackGAEvent(
        'Load Performance',
        entry.name,
        'Server latency',
        Math.round(responseStart - requestStart)
      );
      trackGAEvent(
        'Load Performance',
        entry.name,
        'Download time',
        Math.round(responseEnd - responseStart)
      );
      trackGAEvent(
        'Load Performance',
        entry.name,
        'Total app load time',
        Math.round(responseEnd - requestStart)
      );
    }
  });
};

export const initPerformanceObserver = () => {
  const observer = new PerformanceObserver(callback);
  observer.observe({ entryTypes: metricsToTrack });
};
