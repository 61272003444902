import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useInputState } from '@mantine/hooks';
import { notifications, showNotification } from '@mantine/notifications';

import { Avatar, Box, Button, Group, Stack, Textarea } from '@mantine/core';
import AttachmentUploadButton from '@/components/Attachments/AttachmentUploadButton/AttachmentUploadButton';
import { AttachmentFile } from '@/components/Attachments/AttachmentDisplay/AttachmentFile';
import { createNewAttachment } from '@/components/Attachments/create-new-attachment';
import If from '@/components/Common/If/If';

import { apiClient } from '@/globals';

import type { Comment } from '@/api-client';
import classes from './CreateComment.module.css';

interface CreateCommentProps {
  riskId: string;
  refreshCommentList: () => Promise<void>;
  userEmailSha256: string;
}

interface CreateCommentMutationProps {
  riskId: string;
  content: string;
  attachments: string[];
}

export const CreateComment = ({
  riskId,
  refreshCommentList,
  userEmailSha256,
}: CreateCommentProps) => {
  const [content, setContent] = useInputState('');
  const [attachments, setAttachments] = useState<File[]>([]);
  const [isUploadingAttachments, setIsUploadingAttatchments] = useState(false);

  const createComment = useMutation({
    mutationFn: (props: CreateCommentMutationProps) =>
      apiClient.requests.commentCreate({
        content: props.content,
        risk: riskId,
        attachments: props.attachments,
      } as Comment),
    onError: (error: Error) => {
      notifications.show({
        color: 'red',
        title: 'Failed to post comment',
        message: error.message,
      });
    },
    onSuccess: async () => {
      await refreshCommentList();
      notifications.show({
        color: 'green',
        title: 'Success',
        message: 'Comment successfully posted',
      });
      setContent('');
      setAttachments([]);
    },
    retry: false,
  });

  /** Uploads array of attachments in parallel and returns successful IDs. */
  const uploadAttachments = async () => {
    const promises = attachments.map((attachment) =>
      createNewAttachment(attachment)
        .then((res) => res.id)
        .catch((err: Error) => {
          showNotification({
            title: 'Error uploading attachment',
            message: err.message,
            color: 'red',
          });
          throw err;
        })
    );
    const results = await Promise.allSettled(promises);
    return results
      .filter((r) => r.status === 'fulfilled')
      .map((r) => (r.status === 'fulfilled' && r.value) as string);
  };

  const handleSubmit = async () => {
    setIsUploadingAttatchments(true);
    const attachmentIds = await uploadAttachments();
    setIsUploadingAttatchments(false);

    createComment.mutate({
      riskId,
      content,
      attachments: attachmentIds,
    });
  };

  const isLoading = isUploadingAttachments || createComment.isPending;

  return (
    <Group align="flex-start" gap="md">
      <Box w={32}>
        <Avatar
          src={`https://www.gravatar.com/avatar/${userEmailSha256}?d=robohash&r=g&s=512`}
          size={36}
        />
      </Box>
      <Stack gap="xs" flex={1}>
        <Textarea
          placeholder="Comments will be seen by your team and the disclosing researcher"
          minRows={4}
          onChange={setContent}
          value={content}
          autosize
          classNames={classes}
        />

        <If condition={attachments.length > 0}>
          <Group gap="sm">
            {attachments.map((attachment, index, self) => (
              <AttachmentFile
                key={index}
                file={attachment}
                onDelete={(file) => {
                  const selectedFile = self.find((f) => f.name === file.name);
                  setAttachments((prev) => prev.filter((f) => f !== selectedFile));
                }}
              />
            ))}
          </Group>
        </If>

        <Group justify="space-between">
          <AttachmentUploadButton
            onChange={(files) => {
              setAttachments((prev) => [...prev, ...files]);
            }}
          />
          <Button
            variant="outline"
            mt={0}
            onClick={handleSubmit}
            loading={isLoading}
            disabled={content.length === 0}
          >
            Add comment
          </Button>
        </Group>
      </Stack>
    </Group>
  );
};
