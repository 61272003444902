import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { apiClient } from '@/globals';

export const AuthProvider = () => {
  const navigate = useNavigate();
  const userMe = apiClient.queries.useUserMeRetrieve();

  useEffect(() => {
    if (userMe.isSuccess) {
      navigate('/');
    }
  }, [userMe.isSuccess, navigate]);

  return <Outlet />;
};
