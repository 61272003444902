import { useLoaderData } from 'react-router';
import { notifications } from '@mantine/notifications';

import { Box, Container, Space } from '@mantine/core';
import { RiskForm } from '@/components/Risks/RiskForm/RiskForm';
import BreadcrumbNavigation from '@/components/Common/BreadcrumbNavigation/BreadcrumbNavigation';

import { useTrackPageView } from '@/hooks/use-track-page-view';
import { apiClient } from '@/globals';
import type { Risk } from '@/api-client';

interface LoaderProps {
  risk: Risk;
}

const EditRiskPage = () => {
  const { risk } = useLoaderData() as LoaderProps;

  const updateRisk = apiClient.mutations.useRiskUpdate(risk.id, undefined, {
    onError: (error) => {
      notifications.show({
        color: 'red',
        title: 'Failed to update risk',
        message: (error as Error).message,
      });
    },
    onSuccess: (data) => {
      const riskId = data.id;
      notifications.show({
        color: 'green',
        title: 'Success',
        message: 'Risk updated successfully',
      });
      window.location.href = `/risk/${riskId}`;
    },
  });

  const handleUpdateRisk = async (values: Risk) => updateRisk.mutateAsync(values);

  useTrackPageView('edit_risk');

  return (
    <Container>
      <Box mt="lg">
        <BreadcrumbNavigation
          breadcrumbs={[
            { label: 'Project', href: '/projects/' },
            { label: risk.project, href: '' },
          ]}
        />
      </Box>

      <Space h="sm" />

      <RiskForm edit onSubmit={handleUpdateRisk} risk={risk} />
    </Container>
  );
};

export default EditRiskPage;
