import React, { useState } from 'react';
import { useDisclosure } from '@mantine/hooks';

import { Button, ButtonProps, Group, Modal } from '@mantine/core';
import { UnlockRequest } from '@/api-client';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
type OnConfirm = () => Promise<UnlockRequest | void> | void;

interface ConfirmModalProps {
  title: string;
  description: React.ReactNode;
  buttonText: string;
  color?: ButtonProps['color'];
  onConfirm: OnConfirm;
  children: JSX.Element;
  cancelText?: string;
}

export const ConfirmModal = ({
  title,
  description,
  buttonText,
  color,
  onConfirm,
  cancelText,
  children,
}: ConfirmModalProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      await onConfirm();
      close();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {React.cloneElement(children, { onClick: open })}
      <Modal opened={opened} onClose={close} title={title} color={color} centered>
        <div>{description}</div>

        <Group mt="md" justify="center">
          <Button onClick={close} disabled={isLoading} variant="outline" color={color}>
            {cancelText ?? 'Cancel'}
          </Button>
          <Button type="submit" onClick={handleConfirm} loading={isLoading} color={color}>
            {buttonText}
          </Button>
        </Group>
      </Modal>
    </>
  );
};
