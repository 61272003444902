import { notifications } from '@mantine/notifications';

import { IconLock } from '@tabler/icons-react';
import { ActionIcon, Stack, Text, Tooltip } from '@mantine/core';
import { ConfirmModal } from '@/components/Modals/ConfirmModal/ConfirmModal';

import { apiClient, queryClient } from '@/globals';

import { queryKeys, type Risk } from '@/api-client';

export const RiskUnlockButton = ({ risk }: { risk: Risk }) => {
  const unlockRequestsQuery = apiClient.queries.useUnlockRequestList(false, undefined, risk.id);

  const handleRiskUnlock = async () => {
    if (!unlockRequestsQuery.data?.results?.length) {
      return;
    }

    try {
      const { unlockRequestPartialUpdate } = apiClient.requests;

      await Promise.all(
        unlockRequestsQuery.data.results.map((r) =>
          unlockRequestPartialUpdate({ approved: true }, r.id)
        )
      );

      await queryClient.invalidateQueries({
        queryKey: queryKeys.riskRetrieve(risk.id),
      });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.unlockRequestList(false, undefined, risk.id),
      });
    } catch (error) {
      notifications.show({
        title: 'Error occured',
        message: "The risk couldn't be unlocked, please try again!",
        color: 'red',
      });
    }
  };

  return (
    <ConfirmModal
      title="Unlock Risk"
      description={
        <Stack>
          <Text>
            This will make this risk editable by <b>{risk.researcher.name}</b>. The risk will
            lock again once the status changes. Are you sure you want to do that?
          </Text>
          <Text textfor="caption">{risk.researcher.name} will be notified by email.</Text>
        </Stack>
      }
      buttonText="Confirm"
      onConfirm={handleRiskUnlock}
    >
      <Tooltip
        label={risk.pending_unlock ? 'Click to unlock' : `The risk is currently locked and cannot be edited by ${risk.researcher.name}.`}
      >
        <ActionIcon variant="subtle" color="dark" disabled={!risk.pending_unlock}>
          <IconLock stroke={1.5} size={16} />
        </ActionIcon>
      </Tooltip>
    </ConfirmModal>
  );
};
