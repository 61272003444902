import { Group, Title, Text, Box } from '@mantine/core';
import { Logo } from '@/components/Common';

import classes from './AuthLayout.module.css';
import If from '@/components/Common/If/If';

interface AuthLayoutProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
}

export function AuthLayout({ children, title, description }: AuthLayoutProps) {
  return (
    <div className={classes.wrapper}>
      <Box className={classes.nav}>
        <Group justify="center">
          <Logo size="large" />
        </Group>
      </Box>
      {/* <Paper className={classes.formWrapper} shadow="sm" p="xl"> */}
      <Box className={classes.formWrapper}>
        <If condition={!!title}>
          <Title textfor="h2" ta="center" mb="md">
            {title}
          </Title>
        </If>
        <If condition={!!description}>
          <Text textfor="description" mb="lg">
            {description}
          </Text>
        </If>

        {children}
        {/* </Paper> */}
      </Box>
    </div>
  );
}
