import { IconCalendar, IconToolsOff, IconTrashX, IconX } from '@tabler/icons-react';
import { Button, Group, Menu } from '@mantine/core';

import { ButtonMultiSelect } from './ButtonMultiSelect';
import { DateRangeFilter } from '../RiskFilters/DateRangeFilter';
import { useRiskFilters } from '@/store/risk-store';
import { useFilters } from '@/hooks/use-filters';
import classes from './RiskFilters.module.css';
import { TagsMultiSelect } from './TagsMultiSelect';
import { useUserType } from '@/hooks/use-user-type';
import If from '@/components/Common/If/If';
import { AssigneeMultiSelect } from './AssigneeMultiSelect';

interface TabActionsProps {
  activeTab: 'list' | 'kanban';
}

export const TabActions = ({ activeTab }: TabActionsProps) => {
  const {
    dateRange,
    removeDateRange,
    hideRejected,
    setDateRange,
    categories,
    status,
    severities,
    tags,
    setTags,
    removeAll,
    toggleHideRejected,
    assignee,
    setAssignee,
  } = useRiskFilters();
  const { filters } = useFilters();
  const isDateSelected = dateRange.filter((date) => date !== null).length > 0;
  const userType = useUserType();

  return (
    <>
      <Group>
        {filters.map((filter, i) => (
          <ButtonMultiSelect
            key={i}
            activeTab={activeTab}
            filterMeta={filter}
            onChange={filter.onChange}
            selected={filter.selected}
          />
        ))}
        <If condition={userType !== 'researcher'}>
          <AssigneeMultiSelect selected={assignee} onChange={setAssignee} />
          <TagsMultiSelect selected={tags} onChange={setTags} />
        </If>
        <Button
          variant={hideRejected ? 'light' : 'subtle'}
          color={hideRejected ? 'blue' : 'gray'}
          size="xs"
          mt={0}
          leftSection={<IconToolsOff stroke={1.5} size="1rem" />}
          onClick={(e) => {
            e.stopPropagation();
            toggleHideRejected();
          }}
          rightSection={
            hideRejected ? (
              <IconX
                z={99999}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleHideRejected();
                }}
                className={classes.closeIcon}
                stroke={4}
                size={18}
              />
            ) : null
          }
        >
          Hide rejected
        </Button>
        <Menu position="bottom-start">
          <Menu.Target>
            <Button
              variant={isDateSelected ? 'light' : 'subtle'}
              color={isDateSelected ? 'blue' : 'gray'}
              size="xs"
              mt={0}
              leftSection={<IconCalendar stroke={1.5} size="1rem" />}
              rightSection={
                isDateSelected ? (
                  <IconX
                    z={99999}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeDateRange('from');
                      removeDateRange('to');
                    }}
                    className={classes.closeIcon}
                    stroke={4}
                    size={18}
                  />
                ) : null
              }
            >
              Date Range
            </Button>
          </Menu.Target>
          <Menu.Dropdown p="md">
            <DateRangeFilter selected={dateRange} onChange={setDateRange} />
          </Menu.Dropdown>
        </Menu>
        {/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */}
        {tags.length ||
        categories.length ||
        status?.length ||
        severities.length ||
        isDateSelected ||
        assignee.length ||
        hideRejected ? (
          <Button
            color="red.5"
            size="xs"
            mt={0}
            leftSection={<IconTrashX stroke={1.5} size="1rem" />}
            onClick={() => {
              removeAll();
            }}
            variant="subtle"
          >
            Clear All
          </Button>
        ) : null}
        {/* eslint-enable @typescript-eslint/prefer-nullish-coalescing */}
      </Group>
    </>
  );
};
