import { Box, Stack, Title } from '@mantine/core';

import { CompanySettingsForm } from '../SettingsForms/CompanySettingsForm';

const SubTitle = ({ title }: { title: string }) => (
  <Title order={2} textfor="h5" mb="md">
    {title}
  </Title>
);

export function CompanySettingsTab() {
  return (
    <Stack>
      <Box>
        <SubTitle title="Company Settings" />
        <CompanySettingsForm />
      </Box>
    </Stack>
  );
}
