import { Badge, Box, Group, rem, Stack, Text } from '@mantine/core';
import clsx from 'clsx';
import React from 'react';
import { Draggable, DroppableProvided } from 'react-beautiful-dnd';
import { Project, Risk } from '@/api-client';
import RiskCard from '@/components/Risks/RiskCard/RiskCard';
import { CardProps } from '@/components/Risks/RiskList/props';

import classes from '@/components/Risks/RiskList/RiskList.module.css';

import { StrictModeDroppable } from './strict-mode-droppable';

interface KanbanColumnProps {
  risks: CardProps[];
  label: string;
  status: string;
  project: Project;
}

export const KanbanColumn: React.FC<KanbanColumnProps> = ({
  risks,
  status,
  label,
  project,
}: KanbanColumnProps) => (
    <div>
      <Box className={clsx(classes.kanbanColumn, classes[status])}>
        <div>
          <Group className={classes.kanbanColumnHeader} align="center" gap={rem(4)} mx={rem(8)}>
            <Text>{label}</Text>
            <Badge variant="default" color="gray" size="sm">
              {risks.length}
            </Badge>
          </Group>

          <StrictModeDroppable key={status} droppableId={status}>
            {(provided: DroppableProvided) => (
              <Box {...provided.droppableProps} key={status} ref={provided.innerRef} mih={rem(400)}>
                <Stack className={classes.kanbanColumnBody} gap="xs">
                  {risks.map((risk, index) => (
                    <Draggable key={risk.id} draggableId={risk.id} index={index}>
                      {(providedDraggable, snapshot) => (
                        <Box
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                          ref={providedDraggable.innerRef}
                          key={risk.id}
                          mx={clsx(snapshot.isDragging && rem(8))}
                          my={clsx(snapshot.isDragging && rem(12))}
                        >
                          <RiskCard projectId={project.id} layout="Column" risk={risk.risk ?? ({} as Risk)} />
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Stack>
              </Box>
            )}
          </StrictModeDroppable>
        </div>
      </Box>
    </div>
  );
