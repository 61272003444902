import { Container, Grid, Title } from '@mantine/core';
import ResearchersTable from '@/components/Researchers/ResearchersTable/ResearchersTable';

import { useTrackPageView } from '@/hooks/use-track-page-view';

export function ResearchersPage() {
  useTrackPageView('researchers');

  return (
    <Container>
      <Grid>
        <Grid.Col span={{ base: 12, xs: 12 }}>
          <Title order={3}>Researchers</Title>
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 12 }}>
          <ResearchersTable />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
