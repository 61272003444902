import React from 'react';

interface IfProps<Value = unknown> {
  condition: Value | Falsy;
  children: React.ReactNode | ((value: Value) => React.ReactNode);
  fallback?: React.ReactNode;
}

const If = ({ condition, children, fallback }: IfProps) => {
  if (condition) {
    if (typeof children === 'function') {
      return children(condition);
    }

    return children;
  }

  if (fallback) {
    return fallback;
  }

  return null;
};

export default If;
