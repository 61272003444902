import { useContext } from 'react';
import { CompanyContext } from '@/contexts/CompanyContext';

export const useCompany = () => {
  const context = useContext(CompanyContext);
  if (!context) {
    throw new Error('useCompany must be used within a CompanyProvider and for allowed user types');
  }
  return context.company;
};
